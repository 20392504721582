import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
import { SUBMIT_BUTTON_STYLES_VARIABLES } from "../../root/schema/variables/submit-button";
export const submitButton = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SUBMIT_BUTTON]: {
    display: "grid"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SUBMIT_BUTTON_BUTTON]: {
    cursor: "pointer",
    "background-color": `var(${SUBMIT_BUTTON_STYLES_VARIABLES.BUTTON_COLOR})`,
    "border-style": `var(${SUBMIT_BUTTON_STYLES_VARIABLES.BORDER_STYLE})`,
    "border-radius": `var(${SUBMIT_BUTTON_STYLES_VARIABLES.BORDER_RADIUS})`,
    "border-color": `var(${SUBMIT_BUTTON_STYLES_VARIABLES.BORDER_COLOR})`,
    "border-width": `var(${SUBMIT_BUTTON_STYLES_VARIABLES.BORDER_WIDTH})`,
    width: `var(${SUBMIT_BUTTON_STYLES_VARIABLES.WIDTH})`,
    "justify-self": `var(${SUBMIT_BUTTON_STYLES_VARIABLES.ALIGNMENT})`,
    "font-size": `var(${SUBMIT_BUTTON_STYLES_VARIABLES.FONT_SIZE})`,
    "padding-top": `var(${SUBMIT_BUTTON_STYLES_VARIABLES.VERTICAL_SPACING})`,
    "padding-right": `var(${SUBMIT_BUTTON_STYLES_VARIABLES.HORIZONTAL_SPACING})`,
    "padding-bottom": `var(${SUBMIT_BUTTON_STYLES_VARIABLES.VERTICAL_SPACING})`,
    "padding-left": `var(${SUBMIT_BUTTON_STYLES_VARIABLES.HORIZONTAL_SPACING})`,
    color: `var(${SUBMIT_BUTTON_STYLES_VARIABLES.COLOR})`
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SUBMIT_BUTTON_BUTTON_DISABLED]: {
    filter: "grayscale(100%)",
    cursor: "no-drop"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SUBMIT_BUTTON_BUTTON_RTL]: {
    "text-align": "center"
  }
};
