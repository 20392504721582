import React, { useRef } from 'react';
import './dropdown-sort-list.sass';
import useDropdownListPosition from '../../../../hooks/use-dropdown-list-position/use-dropdown-list-position';
import PropTypes from 'prop-types';
import { DropdownHeader } from '../../button/header/dropdown-header';
import { Button } from '../../../button/button';
import TintIcon from '../../../icon/icon';

const DropdownSortList = ({
  onChange,
  parentRef,
  onChangeTextComponent,
  iconLeft,
  iconRight,
  iconCenter,
  currentItems,
  expandToTop,
  styles,
  dropdownHeaderStyle,
  blankOption,
}) => {
  const elementRef = useRef();
  const { positionHorizontal, positionVertical } = useDropdownListPosition(elementRef, parentRef);

  const DropdownTextElement = title => {
    return (
      <DropdownHeader
        iconLeft={iconLeft}
        iconRight={iconRight}
        iconCenter={iconCenter}
        title={title}
        dropdownHeaderStyle={dropdownHeaderStyle}
      />
    );
  };

  let _currentOptions = blankOption
    ? [
        {
          value: null,
          name: ' ',
          blankOption: true,
        },
        ...currentItems,
      ]
    : currentItems;

  return (
    <>
      <ul
        className={`dropdown-list dropdown-list--${positionHorizontal} dropdown-list--${positionVertical} dropdown-list--${
          expandToTop ? 'expand-top' : ''
        }`}
        style={styles}
        ref={elementRef}
        data-testid='dropdown-simple-list'>
        {_currentOptions.map((item, i) => (
          <li
            className={`dropdown-list__item${item?.type === 'button' ? ' dropdown-list__item--button' : ''}${
              item?.isDisabled ? ' dropdown-list__item--disabled' : ''
            }`}
            key={i}
            onClick={() => {
              if (!item.isDisabled) {
                onChange(item);
                onChangeTextComponent(DropdownTextElement(item.name));
              }
            }}>
            {item?.icon && typeof item.icon === 'string' && <span className={`icon ${item.icon}`} />}
            {item?.icon && typeof item.icon !== 'string' && (
              <span className='icon icon__left'>
                <TintIcon icon={item.icon} />
              </span>
            )}

            {item?.type === 'button' ? (
              <Button type='gray' text={item.name} />
            ) : (
              <span>{!item?.blankOption ? item.name : item?.blankOption}</span>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};

export default DropdownSortList;

DropdownSortList.propTypes = {
  onChange: PropTypes.func,
  currentItems: PropTypes.array,
  parentRef: PropTypes.any,
  onChangeTextComponent: PropTypes.func,
  iconLeft: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  iconRight: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  iconCenter: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
