import { assetDisplayType } from '../../model/asset-display-type';
import { ACTIONS } from '../../actions/asset-collections/asset-collections.actions';
import { produce } from 'immer';

export const initialState = {
  data: [],
  meta: undefined,
  links: undefined,
  isFetching: false,
  error: undefined,
  displayType: assetDisplayType.GRID_SMALL,
  isModalOpened: false,
  isSettingsModalOpened: false,
};

export const assetCollections = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.PATCH_COLLECTION.SUCCESS:
      return produce(state, draftState => {
        const index = draftState.data.findIndex(e => e.id === action.payload.id);
        draftState.data[index].attributes = { ...draftState.data[index].attributes, ...action.payload.attributes };
      });

    case ACTIONS.PATCH_COLLECTION.REQUEST:
    case ACTIONS.GET_COLLECTIONS.REQUEST:
    case ACTIONS.DELETE_COLLECTIONS.REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case ACTIONS.GET_COLLECTIONS.ERROR:
    case ACTIONS.DELETE_COLLECTIONS.ERROR:
    case ACTIONS.GET_COLLECTIONS_PAGE.ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };

    case ACTIONS.GET_COLLECTIONS_PAGE.SUCCESS:
      return {
        ...state,
        data: action.payload.collections,
        links: action.payload.links,
        meta: action.payload.meta,
        isFetching: false,
        isError: false,
      };

    case ACTIONS.GET_COLLECTIONS.SUCCESS:
      return {
        ...state,
        data: action.payload.collections,
        links: action.payload.links,
        meta: action.payload.links,
        isFetching: false,
        isError: false,
      };

    case ACTIONS.OPEN_COLLECTION_MODAL:
      return {
        ...state,
        isModalOpened: true,
      };

    case ACTIONS.DELETE_COLLECTIONS.SUCCESS:
      return produce(state, draft => {
        draft.data = draft.data.filter(draftCollection => {
          return !action.payload.find(collection => {
            return draftCollection.id === collection.id;
          });
        });
      });

    case ACTIONS.CLEAR_COLLECTIONS:
      return { ...initialState, links: state.links, meta: state.meta };

    case ACTIONS.CLOSE_COLLECTION_MODAL:
      return {
        ...state,
        isModalOpened: false,
      };

    case ACTIONS.CLOSE_COLLECTION_SETTINGS_MODAL:
      return {
        ...state,
        isSettingsModalOpened: false,
      };
    case ACTIONS.OPEN_COLLECTION_SETTINGS_MODAL:
      return {
        ...state,
        isSettingsModalOpened: true,
      };

    default:
      return state;
  }
};

export const getSelectedCollections = state => (state ? state.data.filter(e => e.isSelected) : []);

export const isAnyCollectionSelected = state => getSelectedCollections(state).length > 0;

export default assetCollections;
