import React, { Component } from 'react';
import { EditModalBody } from '../../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { EditColorInput, EditInputDropdown } from '../../../../';
import PropTypes from 'prop-types';
import { EditUploadDesign } from '../../../../';

export const CustonButtonOutlineStyle = {
  NONE: 'none',
  SOLID: 'solid',
  DOTTED: 'dotted',
  DASHED: 'dashed',
  DOUBLE: 'double',
  GROOVE: 'groove',
  RIDGE: 'ridge',
  INSET: 'inset',
  OUTSET: 'outset',
};

export class EditCustomButtonStyles extends Component {
  getOutlineOptions() {
    return [
      { value: CustonButtonOutlineStyle.NONE, name: 'None' },
      { value: CustonButtonOutlineStyle.SOLID, name: 'Solid' },
      { value: CustonButtonOutlineStyle.DOTTED, name: 'Doted' },
      { value: CustonButtonOutlineStyle.DASHED, name: 'Dashed' },
      { value: CustonButtonOutlineStyle.DOUBLE, name: 'Double' },
      { value: CustonButtonOutlineStyle.GROOVE, name: 'Groobe' },
      { value: CustonButtonOutlineStyle.RIDGE, name: 'Ridge' },
      { value: CustonButtonOutlineStyle.INSET, name: 'Inset' },
      { value: CustonButtonOutlineStyle.OUTSET, name: 'Outset' },
    ];
  }

  render() {
    return (
      <EditModalBody title='Display icons for your accounts. Insert URLs to add social icons to the layout.'>
        <EditColorInput
          color={this.props.data.styles.buttonTextColor}
          label='Button text color'
          onChange={value => {
            this.props.styleDataUpdate({ buttonTextColor: value });
          }}
        />
        <EditColorInput
          color={this.props.data.styles.buttonColor}
          label='Button color'
          onChange={value => {
            this.props.styleDataUpdate({ buttonColor: value });
          }}
        />
        <EditInputDropdown
          defaultValue={this.props.data.styles.buttonOutline}
          onChange={e => {
            this.props.styleDataUpdate({
              buttonOutline: e.target.value,
            });
          }}
          options={this.getOutlineOptions()}
          label='Button outline'
        />
        <EditColorInput
          color={this.props.data.styles.buttonOutlineBorderColor}
          label='Button outline color'
          onChange={value => {
            this.props.styleDataUpdate({ buttonOutlineBorderColor: value });
          }}
        />
        <EditUploadDesign
          dataUpdate={this.props.dataUpdate}
          styleDataUpdate={this.props.styleDataUpdate}
          data={this.props.data}
        />
      </EditModalBody>
    );
  }
}

EditCustomButtonStyles.propTypes = {
  dataUpdate: PropTypes.func,
  onStylesUpdate: PropTypes.func,
  closeModal: PropTypes.func,
  onDataSave: PropTypes.func,
  dismissModal: PropTypes.func,
  data: PropTypes.shape({
    buttonTextColor: PropTypes.string,
    buttonColor: PropTypes.string,
    buttonOutlineType: PropTypes.number,
    buttonOutline: PropTypes.string,
  }),
};
