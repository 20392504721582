import React, { useEffect, useState, useRef, useCallback } from 'react';
import './social-connections.sass';
import { useDispatch } from 'react-redux';
import { ContentBox } from '../../../../components/interface/content-box/content-box';
import { Config } from '../../../../utils/config';
import { Button } from '../../../../components/interface/button/button';
import { openModal } from '../../actions/social-connections/social-connections-modal.actions';
import { ACTION_TYPES } from '../../reducers/social-connections/social-connections-modal.reducer';
import { getSocialConnections } from '../../actions/social-connections/social-connections.fat-actions';
import PreloaderComponent from '../../../../components/interface/preloaders/preloader/preloader';
import { getCurrentPage } from '../../../../services/functions/url-checker/url-address';
import { Pagination } from '../../../experience/components/experiences/pagination/pagination';
import { FilterBar } from '../filter-bar/filter-bar';
import { createParamsForUrl, filters } from '../../utils/filter-helper/filter-helper';
import { AccountsTable } from '../accounts-table/accounts-table';
import DropdownButton, { ButtonType } from '../../../../components/interface/dropdown/button/dropdown-button';
import { DropdownHeader } from '../../../../components/interface/dropdown/button/header/dropdown-header';
import DropdownSimpleList from '../../../../components/interface/dropdown/lists/simple-list/dropdown-simple-list';
import { debounceDelay, ExpirationStatuses } from '../../common';
import debounce from 'lodash.debounce';
import { TableListEmpty, TableTypes } from '../../../../components/interface/lists/table-list-empty/table-list-empty';
import useFeatureAccessibility from '../../../../components/hooks/use-feature-accessibility/use-feature-accessibility';
import { GuardNames } from '../../../../guards/guards-list';
import { SocialFeedsSource } from '../../../../types/social-feeds';

export const SocialConnectionsTypes = {
  twitter: {
    id: SocialFeedsSource.TWITTER,
    backendMapping: SocialFeedsSource.TWITTER,
    frontendMapping: 'X',
  },
  facebook: {
    id: SocialFeedsSource.TWITTER,
    backendMapping: SocialFeedsSource.FACEBOOK,
    frontendMapping: 'Facebook',
  },
  instagram: {
    id: SocialFeedsSource.INSTAGRAM,
    backendMapping: SocialFeedsSource.INSTAGRAM_BUSINESS,
    frontendMapping: 'Instagram',
  },
  webex: {
    id: SocialFeedsSource.WEBEX,
    backendMapping: SocialFeedsSource.WEBEX,
    frontendMapping: 'Webex',
  },
  hootsuite: {
    id: SocialFeedsSource.HOOT_SUITE,
    backendMapping: SocialFeedsSource.HOOT_SUITE,
    frontendMapping: 'Hootsuite',
    featuresName: GuardNames.SOCIAL_CONNECTIONS_HOOTSUITE,
  },
  linkedin: {
    id: SocialFeedsSource.LINKED_IN,
    backendMapping: SocialFeedsSource.LINKED_IN,
    frontendMapping: 'LinkedIn',
  },
  slack: {
    id: SocialFeedsSource.SLACK,
    backendMapping: SocialFeedsSource.SLACK,
    frontendMapping: 'Slack',
  },
  tiktok: {
    id: SocialFeedsSource.TIK_TOK,
    backendMapping: SocialFeedsSource.TIK_TOK,
    frontendMapping: 'TikTok',
  },
  tumblr: {
    id: SocialFeedsSource.TUMBLR,
    backendMapping: SocialFeedsSource.TUMBLR,
    frontendMapping: 'Tumblr',
  },
  pinterest: {
    id: SocialFeedsSource.PINTEREST,
    backendMapping: SocialFeedsSource.PINTEREST,
    frontendMapping: 'Pinterest',
    featuresName: GuardNames.SOCIAL_FEEDS_PINTEREST,
  },
  flickr: {
    id: SocialFeedsSource.FLICKR,
    backendMapping: SocialFeedsSource.FLICKR,
    frontendMapping: 'Flickr',
  },
  youtube: {
    id: SocialFeedsSource.YOUTUBE,
    backendMapping: SocialFeedsSource.YOUTUBE,
    frontendMapping: 'YouTube',
    features: [Config.features.socialFeedsYouTube],
  },
  threads: {
    id: SocialFeedsSource.THREADS,
    backendMapping: SocialFeedsSource.THREADS,
    frontendMapping: 'Threads',
    featuresName: GuardNames.TEAMS_SOCIAL_ACCOUNTS_THREADS,
  },
  creator_iq: {
    id: SocialFeedsSource.CREATOR_IQ,
    backendMapping: SocialFeedsSource.CREATOR_IQ,
    frontendMapping: 'CreatorIQ',
    featuresName: GuardNames.SOCIAL_FEEDS_CREATOR_IQ,
  },
  power_reviews: {
    id: SocialFeedsSource.POWER_REVIEWS,
    backendMapping: SocialFeedsSource.POWER_REVIEWS,
    frontendMapping: 'Power Reviews',
    featuresName: GuardNames.SOCIAL_FEEDS_POWER_REVIEWS,
  },
  bazaarvoice: {
    id: SocialFeedsSource.BAZAARVOICE,
    backendMapping: SocialFeedsSource.BAZAARVOICE,
    frontendMapping: 'Bazaarvoice',
    featuresName: GuardNames.SOCIAL_FEEDS_BAZAARVOICE,
  },
  re_captcha: {
    id: SocialFeedsSource.RE_CAPTCHA,
    backendMapping: SocialFeedsSource.RE_CAPTCHA,
    frontendMapping: 'reCAPTCHA',
    featuresName: GuardNames.FORMS_CAPTCHA,
  },
};

const mapForDropdown = socials =>
  Object.values(socials).map(value => ({
    name: value.frontendMapping,
    value: value.backendMapping,
  }));

export const SocialConnections = ({
  isFetching,
  socialConnections,
  onDeleteSocialConnection,
  onRefreshSocialConnection,
  onEditSocialConnection,
}) => {
  const dispatch = useDispatch();
  const [activeFilters, setActiveFilters] = useState({
    [filters.QUERY]: '',
    [filters.TYPE]: '',
    [filters.STATUS]: '',
  });

  const { error, ...dropdownStatuses } = ExpirationStatuses;

  const debouncedFunctionRef = useRef();
  debouncedFunctionRef.current = filters => {
    dispatch(
      getSocialConnections({
        url: createParamsForUrl({
          baseUrl: '/accounts',
          filters: [
            ...Object.entries(filters)
              .filter(([, value]) => value)
              .map(([key, value]) => ({ by: key, value })),
          ],
        }),
      })
    );
  };

  const hasPermissionManageAccounts = useFeatureAccessibility(GuardNames.ACCOUNTS_CREATE);

  const openSocialConnectionsModal = () => {
    hasPermissionManageAccounts && dispatch(openModal({ modal: ACTION_TYPES.ADD_SOCIAL_CONNECTION }));
  };

  const onPageChange = url => dispatch(getSocialConnections({ url }));

  const debouncedGetSocialConnections = useCallback(
    debounce((...args) => debouncedFunctionRef.current(...args), debounceDelay),
    []
  );

  const onFilterDropdown = (selected, filter) => {
    setActiveFilters(s => ({
      ...s,
      [filter]: selected.value,
    }));

    const tempActiveFilters = { ...activeFilters, [filter]: selected.value };
    dispatch(
      getSocialConnections({
        url: createParamsForUrl({
          baseUrl: '/accounts',
          filters: [
            ...Object.entries(tempActiveFilters)
              .filter(([, value]) => value)
              .map(([key, value]) => ({ by: key, value })),
          ],
        }),
      })
    );
  };

  const onFilterQuery = value => {
    setActiveFilters(s => ({
      ...s,
      [filters.QUERY]: value,
    }));

    const tempActiveFilters = { ...activeFilters, [filters.QUERY]: value };
    debouncedGetSocialConnections(tempActiveFilters);
  };

  useEffect(() => {
    dispatch(getSocialConnections());
  }, [dispatch]);

  const renderSocialConnectionsTable = accounts => {
    return (
      <AccountsTable
        accounts={accounts}
        onRefresh={onRefreshSocialConnection}
        onDelete={onDeleteSocialConnection}
        onEdit={onEditSocialConnection}
      />
    );
  };

  const getCurrentItem = (list, value) => {
    return list.find(item => item.value === value);
  };

  const renderHeader = () => {
    return (
      <div className='tint-social-connections__action-bar'>
        <FilterBar searchFn={onFilterQuery} searchValue={activeFilters[filters.QUERY]} />
        <DropdownButton
          currentItem={getCurrentItem(mapForDropdown(SocialConnectionsTypes), activeFilters[filters.TYPE])}
          dropdownHeader={props => <DropdownHeader {...props} />}
          dropdownList={props => <DropdownSimpleList {...props} />}
          list={mapForDropdown(SocialConnectionsTypes)}
          placeholder='Type'
          iconLeft='fas fa-filter'
          iconRight='fa fa-caret-down'
          buttonType={ButtonType.BUTTON_DEFAULT}
          onChangeValue={selected => onFilterDropdown(selected, filters.TYPE)}
        />
        <DropdownButton
          currentItem={getCurrentItem(mapForDropdown(dropdownStatuses), activeFilters[filters.STATUS])}
          dropdownHeader={props => <DropdownHeader {...props} />}
          dropdownList={props => <DropdownSimpleList {...props} />}
          list={mapForDropdown(dropdownStatuses)}
          placeholder='Status'
          iconLeft='fas fa-filter'
          iconRight='fa fa-caret-down'
          buttonType={ButtonType.BUTTON_DEFAULT}
          onChangeValue={selected => onFilterDropdown(selected, filters.STATUS)}
        />
        {hasPermissionManageAccounts && (
          <Button id='social-connections-list-add' size='medium' type='primary' onClick={openSocialConnectionsModal}>
            + Add
          </Button>
        )}
      </div>
    );
  };

  const renderFooter = () => {
    return socialConnections.accounts.data.length && socialConnections.accounts.links ? (
      <Pagination
        nextUrl={socialConnections.accounts.links.next}
        previousUrl={socialConnections.accounts.links.prev}
        request={onPageChange}
        fromPage={getCurrentPage(socialConnections.accounts.links.self) || 1}
        pageSize={Config.defaultTintsPageSize}
        isFetching={socialConnections.isFetching}
      />
    ) : null;
  };

  return (
    <ContentBox title='Accounts' headerComponent={renderHeader()} footerComponent={renderFooter()}>
      {isFetching ? (
        <PreloaderComponent />
      ) : socialConnections.accounts.data.length ? (
        renderSocialConnectionsTable(socialConnections.accounts.data)
      ) : (
        <TableListEmpty type={TableTypes.SOCIAL_CONNECTIONS} />
      )}
    </ContentBox>
  );
};
