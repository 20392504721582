var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import {
  ELEMENTS_ID_PHRASES,
  ELEMENTS_CLASSES,
  FORM_BUILDER_NODE_NAMES
} from "./form-builder.types";
import {
  DATE,
  EMAIL,
  FILE_UPLOAD,
  MULTI_LINE_TEXT,
  PHONE,
  PRODUCT_ASSOCIATION,
  SINGLE_LINE_TEXT
} from "./form-builder.form-elements.config";
import { formBuilderCustomTranslations } from "../services/translator/form-builder.translations";
import { _generateTranslation } from "../services/translator/form-builder.translator.service";
import { FormBuilderSlugService } from "../services/builder/form-builder.slug.service";
const hintIdPhrase = "hint";
const createInputId = (phrase, index) => {
  return `${phrase}_${index}`;
};
const createInputHintId = (phrase, index) => {
  return `${phrase}_${hintIdPhrase}_${index}`;
};
export const createInputLabelGroup = ({
  slug,
  index,
  inputValue,
  labelValue,
  inputName,
  inputType,
  combineSlugAndIndex = false,
  placeholder,
  ariaDescribedBy,
  required = false
}) => [
  {
    for: combineSlugAndIndex ? `${slug}_${index}` : slug,
    nodeName: "label",
    nodeValue: labelValue
  },
  __spreadValues(__spreadValues({
    id: combineSlugAndIndex ? `${slug}_${index}` : slug,
    name: inputName || slug,
    value: inputValue,
    type: inputType,
    nodeName: "input",
    "aria-describedby": ariaDescribedBy,
    required
  }, placeholder ? { placeholder } : {}), ariaDescribedBy ? { "aria-describedby": ariaDescribedBy } : {})
];
export const SCALE_ELEMENT = ({
  slug,
  scaleLength,
  customValues = {},
  indexAsValue,
  ariaDescribedBy,
  indexStart,
  inputName,
  required
}) => {
  return Array.from({ length: scaleLength }, (_, i) => {
    const index = indexStart ? indexStart + i : i;
    const ratingNumber = indexAsValue ? index : index + 1;
    return createInputLabelGroup(__spreadValues({
      slug,
      inputName,
      required,
      index: ratingNumber,
      inputValue: ratingNumber,
      labelValue: ratingNumber,
      inputType: "radio",
      combineSlugAndIndex: true,
      ariaDescribedBy
    }, customValues[i]));
  }).flat();
};
export const CHOICE_ELEMENT = ({ slug, options, inputType, ariaDescribedBy, inputName }) => {
  return Array.from({ length: options.length }, (_, index) => {
    const inputNumber = index + 1;
    return createInputLabelGroup({
      slug,
      index: inputNumber,
      inputName,
      inputValue: options[index].value.replace(" ", "_").toLowerCase(),
      labelValue: options[index].value,
      inputType,
      combineSlugAndIndex: true,
      ariaDescribedBy
    });
  }).flat();
};
export const MATRIX_TR = ({ slug, questions, answers, inputType = "radio" }) => {
  return questions.map((question, questionIndex) => {
    return {
      nodeName: "tr",
      elements: [
        { nodeName: "th", nodeValue: question.label, id: question.id },
        ...answers.flatMap((answer, answerIndex) => {
          const inputIndex = questionIndex * questions.length + answerIndex;
          return __spreadValues({}, MATRIX_TD({
            inputType,
            slug,
            inputIndex,
            questionValue: question.value,
            answerValue: answer.value,
            questionId: question.id,
            answerId: answer.id
          }));
        })
      ]
    };
  });
};
export const MATRIX_TD = ({
  slug,
  questionValue,
  questionId,
  answerValue,
  answerId,
  inputType = "radio",
  inputIndex,
  name,
  required = false
}) => {
  const inputName = `${name || slug}[${questionValue}]`;
  return {
    nodeName: "td",
    elements: [
      {
        nodeName: "input",
        type: inputType,
        id: `${slug}_${inputIndex}`,
        name: inputType === "checkbox" ? `${inputName}[]` : inputName,
        value: answerValue,
        "aria-labelledby": `${questionId} ${answerId}`,
        required
      }
    ]
  };
};
const createElement = (nodeName, nodeValue) => ({
  nodeName,
  nodeValue
});
const createLabel = (forString, nodeValue) => ({
  nodeName: FORM_BUILDER_NODE_NAMES.LABEL,
  for: forString,
  nodeValue
});
export const createHint = (id, nodeValue) => ({
  nodeName: FORM_BUILDER_NODE_NAMES.P,
  class: "div-hint",
  id,
  nodeValue: nodeValue || ""
});
export const createFormBuilderElement = (type, i, {
  headerTitleLabelT,
  headerDescriptionLabelT,
  paragraphT,
  emailLabelT,
  fullNameFirstNameLabelT,
  fullNameLastNameLabelT,
  uploadLabelT,
  starRatingLabelT,
  multiLineLabelT,
  multiLinePlaceholderT,
  singleLineLabelT,
  singleChoiceLabelT,
  multipleChoiceLabelT,
  thumbScaleLabelT,
  websiteT,
  phoneLabelT,
  smileyScaleLabelT,
  communityConsentLabelT,
  acceptanceLabelT,
  communityMinimumAge,
  dateLabelT,
  birthdayLabelT,
  addDefaultTranslation,
  numberScaleLabelT,
  numberScaleLabelMinT,
  numberScaleLabelMaxT,
  numberLabelT = "Number",
  props = {},
  allElements = [],
  customNames = {}
} = {}) => {
  const slug = ELEMENTS_ID_PHRASES[type];
  switch (type) {
    case ELEMENTS_CLASSES.PARAGRAPH: {
      return {
        nodeName: "p",
        class: ELEMENTS_CLASSES.PARAGRAPH,
        nodeValue: paragraphT || "We're constantly working to improve your experience. Sharing your feedback helps us understand what you like and what we can do better. Whether it's a glowing review or constructive criticism, your feedback will be kept confidential and will be used to make our offering the best it can be. Thanks for taking the time to help us improve!"
      };
    }
    case ELEMENTS_CLASSES.LINE:
      return {
        nodeName: "hr",
        class: ELEMENTS_CLASSES.LINE
      };
    case ELEMENTS_CLASSES.FULL_NAME: {
      const firstNameSlug = createInputId(slug.FIRST_NAME, i);
      const lastNameSlug = createInputId(slug.LAST_NAME, i);
      return {
        nodeName: "fieldset",
        class: ELEMENTS_CLASSES.FULL_NAME,
        elements: [
          { nodeName: "legend", nodeValue: "Name" },
          {
            nodeName: "div",
            class: "input",
            elements: [
              createLabel(customNames.firstNameSlug || firstNameSlug, fullNameFirstNameLabelT || "First Name"),
              __spreadValues(__spreadProps(__spreadValues({
                id: firstNameSlug,
                name: customNames.firstNameSlug || firstNameSlug
              }, SINGLE_LINE_TEXT), {
                required: true,
                autocomplete: "given-name"
              }), props)
            ]
          },
          {
            nodeName: "div",
            class: "input",
            elements: [
              createLabel(customNames.lastNameSlug || lastNameSlug, fullNameLastNameLabelT || "Last Name"),
              __spreadValues(__spreadProps(__spreadValues({
                id: lastNameSlug,
                name: customNames.lastNameSlug || lastNameSlug
              }, SINGLE_LINE_TEXT), {
                required: true,
                autocomplete: "family-name"
              }), props)
            ]
          }
        ]
      };
    }
    case ELEMENTS_CLASSES.EMAIL: {
      const emailSlug = createInputId(slug, i);
      const emailHintSlug = createInputHintId(slug, i);
      return {
        nodeName: "div",
        class: ELEMENTS_CLASSES.EMAIL,
        elements: [
          createLabel(customNames.emailSlug || emailSlug, emailLabelT || "Email address"),
          __spreadValues(__spreadValues({
            id: emailSlug,
            name: customNames.emailSlug || emailSlug,
            "aria-describedby": emailHintSlug
          }, EMAIL), props),
          createHint(emailHintSlug)
        ]
      };
    }
    case ELEMENTS_CLASSES.DATE: {
      const dateSlug = createInputId(slug, i);
      return {
        nodeName: "div",
        class: ELEMENTS_CLASSES.DATE,
        elements: [createLabel(dateSlug, dateLabelT || "Date"), __spreadValues(__spreadValues({ id: dateSlug, name: dateSlug }, DATE), props)]
      };
    }
    case ELEMENTS_CLASSES.BIRTHDAY: {
      const birthdaySlug = createInputId(slug, i);
      return {
        nodeName: "div",
        class: ELEMENTS_CLASSES.BIRTHDAY,
        elements: [
          createLabel(birthdaySlug, birthdayLabelT || "Birthday"),
          __spreadValues(__spreadProps(__spreadValues({
            id: birthdaySlug,
            name: birthdaySlug
          }, DATE), {
            autocomplete: "bday"
          }), props)
        ]
      };
    }
    case ELEMENTS_CLASSES.PHONE: {
      const phoneSlug = createInputId(slug, i);
      const phoneHintSlug = createInputHintId(slug, i);
      return {
        nodeName: "div",
        class: ELEMENTS_CLASSES.PHONE,
        elements: [
          createLabel(phoneSlug, phoneLabelT || "Phone Number"),
          __spreadValues({ id: phoneSlug, name: phoneSlug, "aria-describedby": phoneHintSlug }, PHONE),
          createHint(phoneHintSlug)
        ]
      };
    }
    case ELEMENTS_CLASSES.HEADING:
      return {
        nodeName: "hgroup",
        class: ELEMENTS_CLASSES.HEADING,
        elements: [createElement("h2", "Heading"), createElement("p", "Heading paragraph")]
      };
    case ELEMENTS_CLASSES.SPACER: {
      const spacerSlug = `spacer_${i}`;
      return {
        id: spacerSlug,
        nodeName: "div",
        class: ELEMENTS_CLASSES.SPACER
      };
    }
    case ELEMENTS_CLASSES.HEADER:
      return {
        nodeName: "hgroup",
        class: ELEMENTS_CLASSES.HEADER,
        elements: [
          createElement("h1", headerTitleLabelT || "Feedback Form"),
          createElement("p", headerDescriptionLabelT || "Submit your feedback to us")
        ]
      };
    case ELEMENTS_CLASSES.WEBSITE: {
      const websiteSlug = createInputId(slug, i);
      const websiteHintSlug = createInputHintId(slug, i);
      return {
        nodeName: "div",
        class: ELEMENTS_CLASSES.WEBSITE,
        elements: [
          createLabel(websiteSlug, websiteT || "Website"),
          __spreadProps(__spreadValues({}, SINGLE_LINE_TEXT), {
            id: websiteSlug,
            name: websiteSlug,
            type: "url",
            "aria-describedby": websiteHintSlug
          }),
          createHint(websiteHintSlug)
        ]
      };
    }
    case ELEMENTS_CLASSES.SINGLE_LINE_TEXT: {
      const singleLineSlug = createInputId(slug, i);
      const singleLineHintSlug = createInputHintId(slug, i);
      return {
        nodeName: "div",
        class: ELEMENTS_CLASSES.SINGLE_LINE_TEXT,
        elements: [
          createLabel(customNames.singleLineSlug || singleLineSlug, singleLineLabelT || "Single Line"),
          __spreadValues({
            id: singleLineSlug,
            name: customNames.singleLineSlug || singleLineSlug,
            "aria-describedby": singleLineHintSlug
          }, SINGLE_LINE_TEXT),
          createHint(singleLineHintSlug)
        ]
      };
    }
    case ELEMENTS_CLASSES.MULTI_LINE_TEXT: {
      const multiLineSlug = createInputId(slug, i);
      const multiLineHintSlug = createInputHintId(slug, i);
      return {
        nodeName: "div",
        class: ELEMENTS_CLASSES.MULTI_LINE_TEXT,
        elements: [
          createLabel(customNames.multiLineSlug || multiLineSlug, multiLineLabelT || "Multi Line"),
          __spreadProps(__spreadValues({
            id: multiLineSlug,
            name: customNames.multiLineSlug || multiLineSlug,
            "aria-describedby": multiLineHintSlug
          }, MULTI_LINE_TEXT), {
            placeholder: multiLinePlaceholderT || ""
          }),
          createHint(multiLineHintSlug)
        ]
      };
    }
    case ELEMENTS_CLASSES.IMAGE: {
      const imageSlug = createInputId(slug, i);
      return {
        nodeName: "figure",
        class: ELEMENTS_CLASSES.IMAGE,
        elements: [{ id: imageSlug, nodeName: "img", src: "", alt: "" }]
      };
    }
    case ELEMENTS_CLASSES.FILE_UPLOAD: {
      const uploadSlug = createInputId(slug, i);
      const uploadHintSlug = createInputHintId(slug, i);
      const parentElement = { class: ELEMENTS_CLASSES.FILE_UPLOAD };
      const dataTranslations = Object.keys(FILE_UPLOAD["data-locale"]).map((key) => {
        return {
          [key]: _generateTranslation(parentElement, FILE_UPLOAD)
        };
      });
      dataTranslations.forEach((tr) => {
        addDefaultTranslation({
          [Object.values(tr)[0]]: formBuilderCustomTranslations["data-locale"][Object.keys(tr)[0]]
        });
      });
      return {
        nodeName: "div",
        class: ELEMENTS_CLASSES.FILE_UPLOAD,
        elements: [
          createLabel(customNames.uploadSlug || uploadSlug, uploadLabelT || "Media"),
          __spreadValues(__spreadProps(__spreadValues({
            id: uploadSlug,
            name: customNames.uploadSlug || uploadSlug,
            nodeValue: "Choose file or drop here",
            "aria-describedby": uploadHintSlug
          }, FILE_UPLOAD), {
            "data-locale": JSON.stringify(
              dataTranslations.reduce((acc, obj) => {
                return __spreadValues(__spreadValues({}, acc), obj);
              }, {})
            )
          }), props),
          createHint(uploadHintSlug)
        ]
      };
    }
    case ELEMENTS_CLASSES.STAR_RATING: {
      const ratingSlug = createInputId(slug, i);
      const starsCount = 5;
      return {
        nodeName: "fieldset",
        class: ELEMENTS_CLASSES.STAR_RATING,
        elements: [
          { nodeName: "legend", nodeValue: starRatingLabelT || "Star Rating" },
          ...SCALE_ELEMENT({
            slug: ratingSlug,
            scaleLength: starsCount,
            inputName: customNames.ratingSlug || ratingSlug
          })
        ]
      };
    }
    case ELEMENTS_CLASSES.SMILEY_SCALE: {
      const smileyScaleSlug = createInputId(slug, i);
      const scaleCount = 5;
      return {
        nodeName: "fieldset",
        class: ELEMENTS_CLASSES.SMILEY_SCALE,
        elements: [
          { nodeName: "legend", nodeValue: smileyScaleLabelT || "Smiley Scale" },
          ...SCALE_ELEMENT({ slug: smileyScaleSlug, scaleLength: scaleCount })
        ]
      };
    }
    case ELEMENTS_CLASSES.NUMBER_SCALE: {
      const numberScaleSlug = customNames.numberScaleSlug || createInputId(slug, i);
      const numberScaleLabelMinSlug = `number_scale_${i}_min`;
      const numberScaleLabelMaxSlug = `number_scale_${i}_max`;
      const restOfElementsCount = 10;
      const restOfElementsStartIndex = 1;
      const firstInputIndex = 0;
      const defaultAriaDescribedByValues = Array.from({ length: restOfElementsCount }, (_, index) => {
        return index === restOfElementsCount - 1 ? { ariaDescribedBy: numberScaleLabelMaxSlug } : {};
      });
      return {
        nodeName: "fieldset",
        class: ELEMENTS_CLASSES.NUMBER_SCALE,
        elements: [
          { nodeName: "legend", nodeValue: numberScaleLabelT || "Number Scale" },
          ...createInputLabelGroup({
            slug: numberScaleSlug,
            index: firstInputIndex,
            inputValue: firstInputIndex,
            labelValue: firstInputIndex,
            inputType: "radio",
            combineSlugAndIndex: true,
            required: props.required,
            ariaDescribedBy: numberScaleLabelMinSlug
          }),
          {
            nodeName: "div",
            nodeValue: numberScaleLabelMinT || "Least Likely",
            "aria-hidden": true,
            id: numberScaleLabelMinSlug
          },
          ...SCALE_ELEMENT({
            slug: numberScaleSlug,
            scaleLength: restOfElementsCount,
            customValues: defaultAriaDescribedByValues,
            indexAsValue: true,
            indexStart: restOfElementsStartIndex,
            required: props.required
          }),
          {
            nodeName: "div",
            nodeValue: numberScaleLabelMaxT || "Most Likely",
            "aria-hidden": true,
            id: numberScaleLabelMaxSlug
          }
        ]
      };
    }
    case ELEMENTS_CLASSES.DROPDOWN: {
      const dropdownSlug = createInputId(slug, i);
      const dropdownHintSlug = createInputHintId(slug, i);
      const defaultDropdownOptions = [{ value: "Option 1" }, { value: "Option 2" }, { value: "Option 3" }];
      return {
        nodeName: "div",
        class: ELEMENTS_CLASSES.DROPDOWN,
        elements: [
          createLabel(dropdownSlug, "Dropdown"),
          {
            id: dropdownSlug,
            name: dropdownSlug,
            nodeName: "select",
            required: false,
            "aria-describedby": dropdownHintSlug,
            elements: [
              ...defaultDropdownOptions.map((option) => {
                return {
                  nodeName: "option",
                  value: option.value,
                  nodeValue: option.value
                };
              })
            ]
          },
          createHint(dropdownHintSlug)
        ]
      };
    }
    case ELEMENTS_CLASSES.SINGLE_CHOICE: {
      const singleChoiceSlug = createInputId(slug, i);
      const defaultSingleChoiceOptions = [{ value: "Option 1" }, { value: "Option 2" }, { value: "Option 3" }];
      return {
        nodeName: "fieldset",
        class: ELEMENTS_CLASSES.SINGLE_CHOICE,
        elements: [
          { nodeName: "legend", nodeValue: singleChoiceLabelT || "Single Choice" },
          ...CHOICE_ELEMENT({
            slug: singleChoiceSlug,
            options: defaultSingleChoiceOptions,
            inputType: "radio"
          })
        ]
      };
    }
    case ELEMENTS_CLASSES.MULTIPLE_CHOICE: {
      const multipleChoiceSlug = createInputId(slug, i);
      const defaultMultipleChoiceOptions = [{ value: "Option 1" }, { value: "Option 2" }, { value: "Option 3" }];
      return {
        nodeName: "fieldset",
        class: ELEMENTS_CLASSES.MULTIPLE_CHOICE,
        elements: [
          { nodeName: "legend", nodeValue: multipleChoiceLabelT || "Multiple Choice" },
          ...CHOICE_ELEMENT({
            slug: multipleChoiceSlug,
            inputName: `${multipleChoiceSlug}[]`,
            options: defaultMultipleChoiceOptions,
            inputType: "checkbox"
          })
        ]
      };
    }
    case ELEMENTS_CLASSES.THUMB_SCALE: {
      const thumbScaleSlug = createInputId(slug, i);
      const defaultThumbScaleValues = [
        { inputValue: "true", labelValue: "Approve" },
        { inputValue: "false", labelValue: "Disapprove" }
      ];
      return {
        nodeName: "fieldset",
        class: ELEMENTS_CLASSES.THUMB_SCALE,
        elements: [
          { nodeName: "legend", nodeValue: thumbScaleLabelT || "Thumb Scale" },
          ...SCALE_ELEMENT({
            slug: thumbScaleSlug,
            scaleLength: defaultThumbScaleValues.length,
            customValues: defaultThumbScaleValues
          })
        ]
      };
    }
    case ELEMENTS_CLASSES.CONSENT: {
      const consentSlug = createInputId(slug, i);
      return {
        nodeName: "div",
        class: ELEMENTS_CLASSES.CONSENT,
        elements: [
          {
            nodeName: "input",
            type: "checkbox",
            value: null,
            name: consentSlug,
            id: consentSlug,
            required: false
          },
          {
            nodeName: "label",
            for: consentSlug,
            elements: [
              { nodeName: "#text", nodeValue: "By submitting my photo and additional information I agree to these " },
              { nodeName: "a", href: null, nodeValue: "terms and conditions", target: "_blank" }
            ]
          }
        ]
      };
    }
    case ELEMENTS_CLASSES.COMMUNITY_CONSENT: {
      const communityConsent = createInputId(slug, i);
      return {
        nodeName: "div",
        class: ELEMENTS_CLASSES.COMMUNITY_CONSENT,
        elements: [
          __spreadValues({
            nodeName: "input",
            type: "checkbox",
            value: null,
            name: communityConsent,
            id: communityConsent,
            required: false,
            "data-mapping": ""
          }, props),
          {
            nodeName: "label",
            for: communityConsent,
            nodeValue: communityConsentLabelT || (communityMinimumAge === "" ? "Sign me up for the exclusive {{name}} community where I can score exclusive offers, try new products and participate in discussions. I agree with both its {{terms_link}} and {{privacy_policy_link}}." : "Sign me up for the exclusive {{name}} community where I can score exclusive offers, try new products and participate in discussions. I attest I'm {{minimum_age}} or older and that I agree with both its {{terms_link}} and {{privacy_policy_link}}.")
          }
        ]
      };
    }
    case ELEMENTS_CLASSES.ACCEPTANCE: {
      const acceptanceSlug = createInputId(slug, i);
      return {
        nodeName: "div",
        class: ELEMENTS_CLASSES.ACCEPTANCE,
        elements: [
          {
            nodeName: "input",
            type: "checkbox",
            value: "true",
            name: acceptanceSlug,
            id: acceptanceSlug,
            required: false
          },
          {
            nodeName: "label",
            for: acceptanceSlug,
            elements: [
              { nodeName: "#text", nodeValue: acceptanceLabelT || "I would like to sign up for the newsletter" },
              { nodeName: "a", href: "", nodeValue: "" }
            ]
          }
        ]
      };
    }
    case ELEMENTS_CLASSES.PRODUCT_ASSOCIATION: {
      const productAssociationSlug = createInputId(slug, i);
      return __spreadValues({
        class: ELEMENTS_CLASSES.PRODUCT_ASSOCIATION,
        name: productAssociationSlug,
        id: productAssociationSlug
      }, PRODUCT_ASSOCIATION);
    }
    case ELEMENTS_CLASSES.HIDDEN: {
      const hiddenSlug = createInputId(slug, i);
      return {
        class: ELEMENTS_CLASSES.HIDDEN,
        name: customNames.hiddenSlug || hiddenSlug,
        id: hiddenSlug,
        type: "hidden",
        nodeName: "input",
        value: ""
      };
    }
    case ELEMENTS_CLASSES.SINGLE_MATRIX: {
      const singleMatrixSlug = createInputId(slug, i);
      const createOptions = (label, value) => {
        const slug2 = `${singleMatrixSlug}_${value}`;
        const oldElements = [...allElements];
        return Array.from({ length: 3 }, () => {
          const elementIndex = FormBuilderSlugService.getSlugIndex({
            customSlug: slug2,
            allElements: oldElements,
            index: 1
          });
          const newOption = {
            label: `${label} ${elementIndex}`,
            value: `${value}_${elementIndex}`,
            id: `${slug2}_${elementIndex}`
          };
          oldElements.push(newOption);
          return newOption;
        });
      };
      const questions = createOptions("Question", "question");
      const answers = createOptions("Answer", "answer");
      return {
        class: ELEMENTS_CLASSES.SINGLE_MATRIX,
        nodeName: "fieldset",
        elements: [
          { nodeName: "legend", nodeValue: "Single Matrix", id: `${singleMatrixSlug}_legend` },
          {
            nodeName: "table",
            "aria-describedby": `${singleMatrixSlug}_legend`,
            elements: [
              {
                nodeName: "thead",
                elements: [
                  {
                    nodeName: "tr",
                    elements: [
                      { nodeName: "th", nodeValue: "" },
                      ...answers.flatMap((answer) => {
                        return { nodeName: "th", nodeValue: answer.label, id: answer.id };
                      })
                    ]
                  }
                ]
              },
              {
                nodeName: "tbody",
                elements: [...MATRIX_TR({ answers, questions, slug: singleMatrixSlug })]
              }
            ]
          }
        ]
      };
    }
    case ELEMENTS_CLASSES.MULTIPLE_MATRIX: {
      const multipleMatrixSlug = createInputId(slug, i);
      const createOptions = (label, value) => {
        const slug2 = `${multipleMatrixSlug}_${value}`;
        const oldElements = [...allElements];
        return Array.from({ length: 3 }, () => {
          const elementIndex = FormBuilderSlugService.getSlugIndex({
            customSlug: slug2,
            allElements: oldElements,
            index: 1
          });
          const newOption = {
            label: `${label} ${elementIndex}`,
            value: `${value}_${elementIndex}`,
            id: `${slug2}_${elementIndex}`
          };
          oldElements.push(newOption);
          return newOption;
        });
      };
      const questions = createOptions("Question", "question");
      const answers = createOptions("Answer", "answer");
      return {
        class: ELEMENTS_CLASSES.MULTIPLE_MATRIX,
        nodeName: "fieldset",
        elements: [
          { nodeName: "legend", nodeValue: "Multiple Matrix", id: `${multipleMatrixSlug}_legend` },
          {
            nodeName: "table",
            "aria-describedby": `${multipleMatrixSlug}_legend`,
            elements: [
              {
                nodeName: "thead",
                elements: [
                  {
                    nodeName: "tr",
                    elements: [
                      { nodeName: "th", nodeValue: "" },
                      ...answers.flatMap((answer) => {
                        return { nodeName: "th", nodeValue: answer.label, id: answer.id };
                      })
                    ]
                  }
                ]
              },
              {
                nodeName: "tbody",
                elements: [...MATRIX_TR({ answers, questions, slug: multipleMatrixSlug, inputType: "checkbox" })]
              }
            ]
          }
        ]
      };
    }
    case ELEMENTS_CLASSES.ADDRESS: {
      const defaultCountry = { value: "US" };
      const addressLine1Slug = createInputId(slug.ADDRESS_LINE_1, i);
      const addressLine2Slug = createInputId(slug.ADDRESS_LINE_2, i);
      const addressLine3Slug = createInputId(slug.ADDRESS_LINE_3, i);
      const addressLevel1Slug = createInputId(slug.ADDRESS_LEVEL_1, i);
      const addressLevel2Slug = createInputId(slug.ADDRESS_LEVEL_2, i);
      const countrySlug = createInputId(slug.COUNTRY, i);
      const postalCodeSlug = createInputId(slug.POSTAL_CODE, i);
      const addressElements = [
        {
          autoComplete: "address-line1",
          id: addressLine1Slug,
          name: addressLine1Slug,
          type: "text",
          nodeName: "input",
          nodeValue: "Address Line 1",
          required: false
        },
        {
          autoComplete: "address-line2",
          id: addressLine2Slug,
          name: addressLine2Slug,
          type: "text",
          nodeName: "input",
          nodeValue: "Address Line 2",
          required: false
        },
        {
          autoComplete: "address-line3",
          id: addressLine3Slug,
          name: addressLine3Slug,
          type: "text",
          nodeName: "input",
          nodeValue: "Address Line 3",
          required: false
        },
        {
          autoComplete: "address-level2",
          id: addressLevel2Slug,
          name: addressLevel2Slug,
          type: "text",
          nodeName: "input",
          nodeValue: "City",
          required: false
        },
        {
          autoComplete: "country",
          id: countrySlug,
          name: countrySlug,
          nodeName: "select",
          nodeValue: "Country",
          "aria-describedby": "country_0",
          required: false,
          elements: [__spreadValues({ nodeName: "option" }, defaultCountry)]
        },
        {
          autoComplete: "address-level1",
          id: addressLevel1Slug,
          name: addressLevel1Slug,
          nodeName: "select",
          nodeValue: "State",
          elements: [],
          required: false
        },
        {
          autoComplete: "postal-code",
          id: postalCodeSlug,
          name: postalCodeSlug,
          type: "text",
          nodeName: "input",
          nodeValue: "Zip Code",
          required: false
        }
      ];
      return {
        class: ELEMENTS_CLASSES.ADDRESS,
        nodeName: "fieldset",
        elements: [
          { nodeName: "legend", nodeValue: "Address" },
          ...addressElements.map((el) => {
            return {
              nodeName: "div",
              class: "input",
              elements: [
                createLabel(el.id, el.nodeValue),
                {
                  nodeName: el.nodeName,
                  autocomplete: el.autoComplete,
                  id: el.id,
                  name: el.name,
                  placeholder: "",
                  required: el.required,
                  type: el.type,
                  elements: el.elements
                }
              ]
            };
          })
        ]
      };
    }
    case ELEMENTS_CLASSES.YOUTUBE: {
      const youtubeSlug = createInputId(slug, i);
      return {
        class: ELEMENTS_CLASSES.YOUTUBE,
        id: youtubeSlug,
        nodeName: "iframe",
        allow: "autoplay; encrypted-media",
        src: "https://www.youtube-nocookie.com/embed/?autoplay=0&controls=0"
      };
    }
    case ELEMENTS_CLASSES.VIMEO: {
      const vimeoSlug = createInputId(slug, i);
      return {
        class: ELEMENTS_CLASSES.VIMEO,
        id: vimeoSlug,
        nodeName: "iframe",
        src: "https://player.vimeo.com/video/?dnt=1&autoplay=0&controls=1"
      };
    }
    case ELEMENTS_CLASSES.NUMBER: {
      const numberSlug = createInputId(slug, i);
      const numberSlugHint = createInputHintId(slug, i);
      return {
        nodeName: FORM_BUILDER_NODE_NAMES.DIV,
        class: ELEMENTS_CLASSES.NUMBER,
        elements: [
          createLabel(customNames.numberSlug || numberSlug, numberLabelT),
          __spreadProps(__spreadValues({
            id: numberSlug,
            name: customNames.numberSlug || numberSlug,
            "aria-describedby": numberSlugHint
          }, SINGLE_LINE_TEXT), {
            type: "number",
            min: "",
            max: ""
          }),
          createHint(numberSlugHint)
        ]
      };
    }
    default:
      return;
  }
};
