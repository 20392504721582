import React from 'react';
import './form-builder.form.interface.image.sass';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';
import { useTranslation } from 'react-i18next';
import { Config } from '../../../../../../utils/config';
import { FormBuilderElementsMappingService } from '../../../../services/builder/form-builder.elements-mapping.service';

const FormBuilderFormInterfaceImage = ({ item, styles, included = [], uploadedImages = [] }) => {
  const { t } = useTranslation();
  const { img: image } = FormBuilderElementsMappingService.getSingleElementHTMLTags(item);
  const imageSrc = t(image?.src) === image?.src ? '' : t(image?.src);

  const match = imageSrc.match(Config.regExp.jsonPathID);
  const id = match && match[1] ? match[1] : null;
  const logoSrc = imageSrc
    ? included.find(el => el.id === id)?.attributes.url || uploadedImages?.find(el => el?.id === id)?.preview
    : null;
  const src = logoSrc;

  const commonStylesNoImg = styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.NO_IMAGE];
  const stylesClass = `div:has(${image.nodeName}[id=${image.id}])`;
  const stylesWrapperClass = `figure:has(${image.nodeName}[id=${image.id}])`;

  const styleImage = {
    ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.IMAGE_IMG],
    ...styles?.[stylesClass],
  };

  const stylesWrapper = {
    ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.IMAGE],
    ...styles?.[stylesWrapperClass],
  };

  return (
    <figure className={`${item.class}`} style={{ ...stylesWrapper, ...(!src ? commonStylesNoImg : {}) }}>
      {src && <img id={image.id} src={src} style={{ ...styleImage }} alt={t(image?.alt)} />}
    </figure>
  );
};

FormBuilderFormInterfaceImage.displayName = 'FormBuilderFormInterfaceImage';
export default React.memo(FormBuilderFormInterfaceImage);
