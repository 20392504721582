import { ACTIONS } from '../../actions/builder/form-builder-submission.builder.actions';
import produce from 'immer';

export const initialState = {
  isFetching: false,
  included: [],
  meta: undefined,
  links: {
    self: undefined,
    prev: undefined,
    next: undefined,
  },
  data: [],
};

export const formBuilderSubmissionBuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_SUBMISSION.REQUEST:
      return produce(state, draft => {
        draft.isFetching = true;
      });

    case ACTIONS.GET_SUBMISSION.SUCCESS:
      return produce(state, draft => {
        draft.data = action.payload?.data.map(el => {
          const relationships = el.attributes?.data?.relationships;
          delete el.attributes?.data?.relationships;

          return {
            ...el,
            attributes: {
              ...el.attributes,
              data: {
                ...el.attributes.data,
                // media should be displayed in sepatate columns based on element ids
                //
                // media: {
                //   element_id_1: { urls: ['image_url.jpg'], type: 'upload' },
                //   element_id_2: { urls: ['image_url.jpg'], type: 'upload' }
                // }
                ...(relationships && Object.keys(relationships).length > 0
                  ? {
                      media: Object.entries(relationships).reduce((obj, [key, value]) => {
                        const urls = value?.data
                          ?.filter(e => e.type === 'upload')
                          .map(file => action.payload?.included.find(asset => asset.id === file.id)?.attributes.url);

                        return Object.assign(obj, { [key]: { urls: JSON.stringify(urls), type: 'upload' } });
                      }, {}),
                    }
                  : {}),
              },
            },
          };
        });

        draft.included = action.payload?.included;
        draft.meta = action.payload?.meta;
        draft.links = action.payload?.links;
        draft.isFetching = false;
      });

    case ACTIONS.GET_SUBMISSION.FAILURE:
      return produce(state, draft => {
        draft.isFetching = false;
      });

    case ACTIONS.RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
