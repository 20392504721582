export const temporaryStyles = `  
  @media (min-width: 992px) {
    .div-form {
      padding: 50px 40px 40px 40px !important;
    }
  }
  
  .input-acceptance a {
    text-decoration: none;
  }
  
  .input-acceptance input {
    border-radius: 4px;
  }
  
  *[dir=rtl] .input-acceptance label {
    margin: 4px 12px 0 0 !important;
  }
  
  .div-form select {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' width='14' height='14' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E");
    outline-width: 1px !important;
    background-repeat: no-repeat;
  }
  
  .div-form select:focus {
    outline-style: solid !important;
    outline-width: 2px !important;
  }
    
  *[dir=rtl] .input-address select {
    padding: 8px 8px 8px 36px !important;
    background-position: 8px 50% !important;
  }
  
  .div-line {
    border-color: #E8EDF4;
  }
  
  .input-choice-two-column {
    grid-template-columns: repeat(2, min-content 1fr) !important;
  }
  
  .input-choice-three-column {
    grid-template-columns: repeat(3, min-content 1fr) !important;
  }
  
  .input-single-choice, .input-multiple-choice {
    display: grid;
    grid-template-columns: min-content 1fr;
  }
  
  .input-single-choice label, .input-multiple-choice label {
    clip-path: initial !important;
  }
  
  .input-single-choice input {
    border-color: #b6b8bb !important;
    border-top-width: 1px !important;
    border-left-width: 1px !important;
    border-right-width: 1px !important;
    border-bottom-width: 1px !important;
    padding: 0 !important
  }
  
  .input-single-choice input:checked {
    border: 3px solid #3B99FC !important;
  }
  
  .input-single-choice label, .input-multiple-choice label {
    line-height: initial !important;
  }
  
  .input-multiple-choice input {
    border-radius: 0 !important;
    padding: 0 !important;
    margin: 2px 8px 16px 0 !important;
  }
  
  *[dir='rtl'] .input-single-choice input, *[dir='rtl'] .input-multiple-choice input {
     margin: 2px 0 16px 8px !important;
  }
  
  .input-multiple-choice input:checked {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%233B99FC' width='24' height='24' viewBox='0 0 24 24'><rect width='100%' height='100%' fill='%233B99FC'/><path fill='white' stroke='white' stroke-width='2' d='M6.293 13.293l2.828 2.828 7.778-7.778 1.414 1.414-9.192 9.192-4.95-4.95 1.122-1.606z'/%3E%3C/svg%3E");
  }
  
  .div-spacer {
      height: 42px;
      margin-bottom: 0;
  }
  
  .input-date input {
    padding: 0 16px;
  }
  
  .input-date input {
    padding: 0 16px;
  }
  
  .input-community-consent {
    display: flex;
  }
  
  .input-community-consent input, .input-consent input, .input-acceptance input {
    padding: 0 !important
  }

  .input-community-consent label {
    margin: 4px 0 0 12px !important;
  }
  
  .input-community-consent input:disabled {
    background: rgba(239, 239, 239, 0.3);
  }
  
  .input-community-consent a {
    text-decoration: none;
  }
  
  *[dir=rtl] .input-community-consent label {
    margin: 4px 12px 0 0 !important;
  }
  
  *[dir=rtl] .input-community-consent input {
    margin: 0 !important;
  }
  
  .input-consent {
    display: flex;
    width: 100%;
  }
  
  .div-form input[type=checkbox] {
    appearance: none !important;
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
    margin: 0;
    border-radius: 0;
    border: 1px solid #b6b8bb;
  }
  
  .div-form input[type="checkbox"]:checked {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%233B99FC' width='24' height='24' viewBox='0 0 24 24'><rect width='100%' height='100%' fill='%233B99FC'/><path fill='white' stroke='white' stroke-width='2' d='M6.293 13.293l2.828 2.828 7.778-7.778 1.414 1.414-9.192 9.192-4.95-4.95 1.122-1.606z'/%3E%3C/svg%3E");
  }
  
  .input-consent label {
    cursor: pointer;
    margin: 4px 0 0 12px;
  }
  
  .input-consent a {
    text-decoration: none;
  }
  
  *[dir=rtl] .input-consent label {
    margin: 4px 12px 0 0 !important;
  }
  
  .input-dropdown select {
    border-color: #767676;
    outline: revert;
    background-position: calc(100% - 8px) 50% !important;
    padding: 8px 36px 8px 8px;
  }
  
  *[dir=rtl] .input-dropdown select {
    padding: 8px 8px 8px 36px;
    background-position: 8px 50% !important;
  }
  
  .input-file input {
    position: relative !important;
    opacity: 1 !important;
    height: 80px !important;
    border-radius: 6px !important;
    white-space: break-spaces;
    padding: 0;
    border: none !important;
  }
  
  .input-file input::before {
    cursor: pointer;
    content: attr(data-content-text);
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f6f7f8;
    text-decoration: underline;
    width: calc(100% - 20px);
    height: 100%;
    padding: 0 10px;
    text-align: center;
  }
  
  .input-file__file {
    display: grid;
    gap: 0 10px;
    grid-template: "name action" auto "progress progress" auto/1fr auto;
    border: 1px solid #DFDFDF;
    padding: 10px;
    margin: 20px 0;
    border-radius: 6px;
  }
  
  .input-file__file-button {
    border: 0;
    color: transparent;
    outline: 0;
    text-indent: -9999px;
    width: 20px;
    height: 20px;
    grid-area: action;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath d='M13 3A1.0001 1.0001 0 0 0 11.986328 4L6 4A1.0001 1.0001 0 1 0 6 6L24 6A1.0001 1.0001 0 1 0 24 4L18.013672 4A1.0001 1.0001 0 0 0 17 3L13 3zM6 8L6 24C6 25.105 6.895 26 8 26L22 26C23.105 26 24 25.105 24 24L24 8L6 8z' fill='%23000000'/%3E%3C/svg%3E");
  }
  
  .input-file__file-name {
    grid-area: name;
  }
  
  .input-file output span {
    margin: 0;
  }
  
  .input-file output li {
    grid-template: "name action" auto "progress progress" auto/1fr auto;
  }
  
  .input-file output ul {
    padding: 0;
  }
  
  .input-file output label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .input-file output progress {
    width: 100%;
    grid-area: progress;
  }
  
  .input.input-name {
    border: none;
    display: flex;
  }
  
  .input.input-name .input {
    display: inline-block;
  }
  
  .input.input-name > .input {
    margin: 0 10px 0 0;
  }
  
  .input.input-name > .input:last-child {
    margin: 0 0 0 10px;
  }
  
  *[dir=rtl] .input-name .input:nth-child(2) {
    margin: 0 0 0 10px !important;
  }
  
   *[dir=rtl] .input-name .input:last-child {
    margin: 0 10px 0 0 !important;
  }
  
  .div-image {
    min-height: 100px;
    text-align: center;
  }
  
  .div-image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: initial;
  }
  
  .div-form hr {
    border-color: #E8EDF4;
    width: 100%;
  }
  
  .input-single-matrix thead th, .input-single-matrix thead td, .input-single-matrix tbody th, .input-single-matrix tbody td, .input-multiple-matrix thead th, .input-multiple-matrix thead td, .input-multiple-matrix tbody th, .input-multiple-matrix tbody td {
    padding: 8px 8px 8px 0;
  }
  
  .input-single-matrix input {
    border-color: #b6b8bb !important;
    border-top-width: 1px !important;
    border-left-width: 1px !important;
    border-right-width: 1px !important;
    border-bottom-width: 1px !important;
    padding: 0 !important;
  }
  
  .input-single-matrix input:checked {
    border: 3px solid #3B99FC !important;
  }
  
  .input-multiple-matrix input {
    padding: 0 !important;
  }
  
  .input-multiple-matrix input:checked {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%233B99FC' width='24' height='24' viewBox='0 0 24 24'><rect width='100%' height='100%' fill='%233B99FC'/><path fill='white' stroke='white' stroke-width='2' d='M6.293 13.293l2.828 2.828 7.778-7.778 1.414 1.414-9.192 9.192-4.95-4.95 1.122-1.606z'/%3E%3C/svg%3E");
  }
  
  .input-single-matrix thead th, .input-multiple-matrix thead th {
    display: table-cell !important
  }
  
  *[dir=rtl] .input-single-matrix thead th, *[dir=rtl] .input-multiple-matrix thead th {
    text-align: center !important
  }
  
  @media screen and (max-width: 600px) {
    .input-single-matrix table thead, .input-multiple-matrix table thead {
      display: none;
    }
    .input-single-matrix table tr, .input-single-matrix table th, .input-multiple-matrix table tr, .input-multiple-matrix table th {
      display: block;
    }
    .input-single-matrix table td, .input-multiple-matrix table td {
      display: flex;
      align-items: center;
    }
    .input-single-matrix table td:after, .input-multiple-matrix table td:after {
      content: attr(data-label);
      padding-left: 10px;
      text-align: initial;
    }
    .input-single-matrix table thead th, .input-single-matrix table thead td, .input-single-matrix table tbody th, .input-single-matrix table tbody td, .input-multiple-matrix table thead th, .input-multiple-matrix table thead td, .input-multiple-matrix table tbody th, .input-multiple-matrix table tbody td {
      padding: 8px 0;
    }
  }
  
  .input-number-scale {
    grid-template-columns: repeat(11, 1fr);
  }
  
  .input-number-scale legend {
    margin-bottom: 16px;
  }
  
  .input-number-scale div:first-of-type {
    grid-column: auto / span 5;
  }
  
  .input-number-scale div:last-of-type {
    text-align: right;
    grid-column: auto / span 6;
  }
  
  .input-number-scale input {
    margin: 0;
    word-break: initial;
    display: flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    border-color: #b6b8bb !important;
    border-top-width: 1px !important;
    border-left-width: 1px !important;
    border-right-width: 1px !important;
    border-bottom-width: 1px !important;
    padding: 10px !important;
    border-radius: 6px;
    cursor: pointer;
  }
  
  .input-number-scale input::before {
    content: attr(value);
  }
  
  .input-number-scale input:checked,
  .input-number-scale input:hover {
    background: #3B99FC;
    color: #ffffff;
  }
  
  *[dir=rtl] .input-number-scale div:last-of-type {
    text-align: left !important
  }
    
  .input-smile-scale {
    position: relative;
  }
  
  .input-smile-scale input {
    padding: 0 !important;
    border: 0 !important
  }
  
  .input-smile-scale input:first-of-type {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242px%22%20height%3D%2242px%22%3E%3Ccircle%20r%3D%2221%22%20fill%3D%22%236D7278%22%20cx%3D%2221%22%20cy%3D%2221%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2214%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Ccircle%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20r%3D%223%22%20cx%3D%2228%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Cline%20x1%3D%2210%22%20y1%3D%2214%22%20x2%3D%2218%22%20y2%3D%2216%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3Cline%20x1%3D%2232%22%20y1%3D%2214%22%20x2%3D%2224%22%20y2%3D%2216%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3Cpath%20d%3D%22M14%2032%20Q%2021%2025%2C%2029%2032%22%20fill%3D%22none%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E");
  }
  
  .input-smile-scale input:first-of-type:checked, .input-smile-scale input:first-of-type:hover {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242px%22%20height%3D%2242px%22%3E%3Ccircle%20r%3D%2221%22%20fill%3D%22%23F04723%22%20cx%3D%2221%22%20cy%3D%2221%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2214%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Ccircle%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20r%3D%223%22%20cx%3D%2228%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Cline%20x1%3D%2210%22%20y1%3D%2214%22%20x2%3D%2218%22%20y2%3D%2216%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3Cline%20x1%3D%2232%22%20y1%3D%2214%22%20x2%3D%2224%22%20y2%3D%2216%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3Cpath%20d%3D%22M14%2032%20Q%2021%2025%2C%2029%2032%22%20fill%3D%22none%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E");
  }
  
  .input-smile-scale input:nth-of-type(2) {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242px%22%20height%3D%2242px%22%3E%3Ccircle%20r%3D%2221%22%20fill%3D%22%236D7278%22%20cx%3D%2221%22%20cy%3D%2221%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2214%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Ccircle%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20r%3D%223%22%20cx%3D%2228%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Cpath%20d%3D%22M13%2031%20Q%2021%2026%2C%2030%2031%22%20fill%3D%22none%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E");
  }
  
  .input-smile-scale input:nth-of-type(2):checked, .input-smile-scale input:nth-of-type(2):hover {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242px%22%20height%3D%2242px%22%3E%3Ccircle%20r%3D%2221%22%20fill%3D%22%23F68E20%22%20cx%3D%2221%22%20cy%3D%2221%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2214%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Ccircle%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20r%3D%223%22%20cx%3D%2228%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Cpath%20d%3D%22M13%2031%20Q%2021%2026%2C%2030%2031%22%20fill%3D%22none%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E");
  }
  
  .input-smile-scale input:nth-of-type(3) {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242px%22%20height%3D%2242px%22%3E%3Ccircle%20r%3D%2221%22%20fill%3D%22%236D7278%22%20cx%3D%2221%22%20cy%3D%2221%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2214%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2228%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%20%3Crect%20x%3D%2212%22%20y%3D%2228%22%20width%3D%2218%22%20height%3D%222%22%20fill%3D%22%23ffffff%22%2F%3E%3C%2Fsvg%3E");
  }
  
  .input-smile-scale input:nth-of-type(3):checked, .input-smile-scale input:nth-of-type(3):hover {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242px%22%20height%3D%2242px%22%3E%3Ccircle%20r%3D%2221%22%20fill%3D%22%23FECC0A%22%20cx%3D%2221%22%20cy%3D%2221%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2214%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2228%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%20%3Crect%20x%3D%2212%22%20y%3D%2228%22%20width%3D%2218%22%20height%3D%222%22%20fill%3D%22%23ffffff%22%2F%3E%20%3C%2Fsvg%3E");
  }
  
  .input-smile-scale input:nth-of-type(4) {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242px%22%20height%3D%2242px%22%3E%3Ccircle%20r%3D%2221%22%20fill%3D%22%236D7278%22%20cx%3D%2221%22%20cy%3D%2221%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2214%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Ccircle%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20r%3D%223%22%20cx%3D%2228%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Cpath%20d%3D%22M13%2028%20Q%2021%2034%2C%2030%2028%22%20fill%3D%22none%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E");
  }
  
  .input-smile-scale input:nth-of-type(4):checked, .input-smile-scale input:nth-of-type(4):hover {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242px%22%20height%3D%2242px%22%3E%3Ccircle%20r%3D%2221%22%20fill%3D%22%2392E53F%22%20cx%3D%2221%22%20cy%3D%2221%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2214%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Ccircle%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20r%3D%223%22%20cx%3D%2228%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Cpath%20d%3D%22M13%2028%20Q%2021%2034%2C%2030%2028%22%20fill%3D%22none%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%2F%3E%3C%2Fsvg%3E");
  }
  
  .input-smile-scale input:nth-of-type(5) {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242px%22%20height%3D%2242px%22%3E%3Ccircle%20r%3D%2221%22%20fill%3D%22%236D7278%22%20cx%3D%2221%22%20cy%3D%2221%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2214%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Ccircle%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20r%3D%223%22%20cx%3D%2228%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Cpath%20d%3D%22M8%2C26%20H34%20A14%2C14%200%200%201%208%2026%22%20fill%3D%22%23ffffff%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
  }
  
  .input-smile-scale input:nth-of-type(5):checked, .input-smile-scale input:nth-of-type(5):hover {
    content: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2242px%22%20height%3D%2242px%22%3E%3Ccircle%20r%3D%2221%22%20fill%3D%22%230FC12F%22%20cx%3D%2221%22%20cy%3D%2221%22%2F%3E%3Ccircle%20r%3D%223%22%20cx%3D%2214%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Ccircle%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20r%3D%223%22%20cx%3D%2228%22%20fill%3D%22%23ffffff%22%20cy%3D%2217%22%2F%3E%3Cpath%20d%3D%22M8%2C26%20H34%20A14%2C14%200%200%201%208%2026%22%20fill%3D%22%23ffffff%22%20stroke%3D%22%23ffffff%22%20stroke-width%3D%222%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E");
  }
  
  .input-rating {
    display: flex;
    padding: 0;
  }
  
  .input-rating label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .input-rating input[type=radio] {
    height: 48px;
    width: 48px;
    margin: 10px 10px 0 0;
    border-radius: 6px;
    border: 0;
    appearance: none;
    background-color: #f5a623;
    padding: 13px;
    cursor: pointer;
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z'/%3E%3C/svg%3E");
  }
  
  .input-rating input[type=radio]:checked ~ input[type=radio],
  .input-rating:not(:has(input[type=radio]:checked)) input[type=radio] {
    background-color: #aab7c4 !important;
  }
  
  *[dir=rtl] .input-rating input:first-of-type,
  *[dir=rtl] .input-smile-scale input:first-of-type {
    margin-right: 0 !important;
  }
  
  .input-thumb-scale input[type=radio] {
    padding: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border: 0;
    cursor: pointer;
    appearance: none;
    margin: 0;
    background: transparent !important
  }
  
  .input-thumb-scale input[type=radio]:before {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .input-thumb-scale label:has(input:checked), .input-thumb-scale label:hover {
    background: #3B99FC;
    color: #ffffff;
  }
  
  .input-thumb-scale label {
    clip-path: initial !important;
  }
  
  .input-thumb-scale label:first-of-type input:before {
    content: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="18" height="18" viewBox="0 0 512 512"%3E%3Cpath fill="black" d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2h144c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48h-97.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7M32 192h64c17.7 0 32 14.3 32 32v224c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32"%2F%3E%3C%2Fsvg%3E');
    font-size: 0;
  }
  
  .input-thumb-scale label:first-of-type:has(input:checked) input:before, .input-thumb-scale label:first-of-type:hover input:before {
    content: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="18" height="18" viewBox="0 0 512 512"%3E%3Cpath fill="white" d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2h144c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48h-97.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7M32 192h64c17.7 0 32 14.3 32 32v224c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32"%2F%3E%3C%2Fsvg%3E');
  }
  
  .input-thumb-scale label:nth-of-type(2) input:before {
    content: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="18" height="18" viewBox="0 0 512 512"%3E%3Cpath fill="black" d="M313.4 479.1c26-5.2 42.9-30.5 37.7-56.5l-2.3-11.4c-5.3-26.7-15.1-52.1-28.8-75.2h144c26.5 0 48-21.5 48-48c0-18.5-10.5-34.6-25.9-42.6C497 236.6 504 223.1 504 208c0-23.4-16.8-42.9-38.9-47.1c4.4-7.3 6.9-15.8 6.9-24.9c0-21.3-13.9-39.4-33.1-45.6c.7-3.3 1.1-6.8 1.1-10.4c0-26.5-21.5-48-48-48h-97.5c-19 0-37.5 5.6-53.3 16.1l-38.5 25.7C176 91.6 160 121.6 160 153.7v111.2c0 29.2 13.3 56.7 36 75l7.4 5.9c26.5 21.2 44.6 51 51.2 84.2l2.3 11.4c5.2 26 30.5 42.9 56.5 37.7M32 384h64c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32v224c0 17.7 14.3 32 32 32"%2F%3E%3C%2Fsvg%3E');
    font-size: 0;
  }
  
  .input-thumb-scale label:nth-of-type(2):has(input:checked) input:before, .input-thumb-scale label:nth-of-type(2):hover input:before {
    content: url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="18" height="18" viewBox="0 0 512 512"%3E%3Cpath fill="white" d="M313.4 479.1c26-5.2 42.9-30.5 37.7-56.5l-2.3-11.4c-5.3-26.7-15.1-52.1-28.8-75.2h144c26.5 0 48-21.5 48-48c0-18.5-10.5-34.6-25.9-42.6C497 236.6 504 223.1 504 208c0-23.4-16.8-42.9-38.9-47.1c4.4-7.3 6.9-15.8 6.9-24.9c0-21.3-13.9-39.4-33.1-45.6c.7-3.3 1.1-6.8 1.1-10.4c0-26.5-21.5-48-48-48h-97.5c-19 0-37.5 5.6-53.3 16.1l-38.5 25.7C176 91.6 160 121.6 160 153.7v111.2c0 29.2 13.3 56.7 36 75l7.4 5.9c26.5 21.2 44.6 51 51.2 84.2l2.3 11.4c5.2 26 30.5 42.9 56.5 37.7M32 384h64c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32v224c0 17.7 14.3 32 32 32"%2F%3E%3C%2Fsvg%3E');
  }
  
  *[dir=rtl] .input-thumb-scale label:first-of-type {
    margin-right: 0 !important;
  }
  
  *[dir=rtl] .input-thumb-scale input {
    margin: 0 !important;
  }
  
  .div-youtube, .div-vimeo {
    aspect-ratio: 16/9;
    border: 0;
  }
  
  .label-hidden label, .label-hidden legend {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .input span {
    margin: 10px 0 0 0;
  }
  
  .input label {
    display: block;
    margin: 0 0 10px 0;
    font-weight: 500;
  }

  *[dir=rtl] .input input {
    margin: 0 0 0 8px;
    padding: 0 16px;
  }
`;
