import { BackendKeys } from "../consts";
export const selectDefaultLocale = (experience) => {
  var _a, _b, _c, _d, _e, _f;
  return (_f = (_e = (_d = (_c = (_b = (_a = experience == null ? void 0 : experience.data) == null ? void 0 : _a.attributes) == null ? void 0 : _b.data) == null ? void 0 : _c.locales) == null ? void 0 : _d.published) == null ? void 0 : _e.find((lang) => lang == null ? void 0 : lang.isDefault)) == null ? void 0 : _f.value;
};
export const selectExperienceSection = (experience, sectionType) => {
  var _a, _b, _c, _d;
  return (_d = (_c = (_b = (_a = experience == null ? void 0 : experience.data) == null ? void 0 : _a.attributes) == null ? void 0 : _b.data) == null ? void 0 : _c.published) == null ? void 0 : _d.find((e) => sectionType == null ? void 0 : sectionType.includes(e.sectionType));
};
export const getTranslation = (experience, key, lang) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l;
  if (((_d = (_c = (_b = (_a = experience == null ? void 0 : experience.data) == null ? void 0 : _a.attributes) == null ? void 0 : _b.data) == null ? void 0 : _c.translations) == null ? void 0 : _d.published) && ((_h = (_g = (_f = (_e = experience == null ? void 0 : experience.data) == null ? void 0 : _e.attributes) == null ? void 0 : _f.data) == null ? void 0 : _g.translations) == null ? void 0 : _h.published[lang][key]) !== void 0) {
    return (_l = (_k = (_j = (_i = experience == null ? void 0 : experience.data) == null ? void 0 : _i.attributes) == null ? void 0 : _j.data) == null ? void 0 : _k.translations) == null ? void 0 : _l.published[lang][key];
  }
  return key;
};
export const selectFormSubmissionKeys = (socialFeed) => {
  var _a;
  return ((_a = socialFeed == null ? void 0 : socialFeed.included) == null ? void 0 : _a.filter((i) => i.type === BackendKeys.FORM_SUBMISSION_KEY)) || [];
};
export const selectFormSubmissionMediaKeys = (keys) => (keys == null ? void 0 : keys.filter((e) => (e == null ? void 0 : e.key) === BackendKeys.MEDIA)) || [];
export const selectFormSubmissionNonMediaKeys = (keys) => (keys == null ? void 0 : keys.filter((e) => (e == null ? void 0 : e.key) !== BackendKeys.MEDIA)) || [];
export const findSocialFeed = (state, source, type) => {
  var _a, _b, _c;
  return (_c = (_b = (_a = state.socialFeeds) == null ? void 0 : _a.tintEditor) == null ? void 0 : _b.data) == null ? void 0 : _c.find((e) => e.attributes.source === source && e.attributes.type === type);
};
export const getSocialFeedLength = (state) => {
  var _a, _b, _c;
  return (_c = (_b = (_a = state.socialFeeds) == null ? void 0 : _a.tintEditor) == null ? void 0 : _b.data) == null ? void 0 : _c.length;
};
