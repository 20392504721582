export const matrix = {
  MATRIX_TABLE: ".input-single-matrix table, .input-multiple-matrix table",
  MATRIX_THEAD: ".input-single-matrix thead, .input-multiple-matrix thead",
  MATRIX_TBODY_TH: ".input-single-matrix tbody th, .input-multiple-matrix tbody th",
  MATRIX_TR: ".input-single-matrix tr, .input-multiple-matrix tr",
  MATRIX_TR_TH: ".input-single-matrix tr, .input-single-matrix th, .input-multiple-matrix tr, .input-multiple-matrix th",
  MATRIX_TD: ".input-single-matrix td, .input-multiple-matrix td",
  MATRIX_TH: ".input-single-matrix th, .input-multiple-matrix th",
  MATRIX_TD_AFTER: ".input-single-matrix td:after, .input-multiple-matrix td:after",
  MATRIX_TD_TH: ".input-single-matrix th, .input-single-matrix td, .input-multiple-matrix th, .input-multiple-matrix td",
  MATRIX_TD_TH_RTL: "*[dir='rtl'] .input-single-matrix th, *[dir='rtl'] .input-single-matrix td, *[dir='rtl'] .input-multiple-matrix th, *[dir='rtl'] .input-multiple-matrix td",
  MATRIX_TD_AFTER_RTL: "*[dir='rtl'] .input-single-matrix td:after, *[dir='rtl'] .input-multiple-matrix td:after",
  MATRIX_INPUT: ".input-single-matrix input, .input-multiple-matrix input"
};
