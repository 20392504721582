import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// Assuming you're using a backend, HttpBackend for instance
// other imports as necessary (language detector, etc.)

export function createI18nInstance(initValues) {
  const newInstance = i18n.createInstance();

  newInstance
    // .use(LanguageDetector) // If you're using automatic language detection
    .use(initReactI18next) // Passes i18n down to react-i18next
    .init(
      {
        ...initValues,
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
      },
      err => {
        if (err) return console.log('something went wrong loading', err);
      }
    );

  return newInstance;
}
