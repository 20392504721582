import React, { Fragment } from 'react';
import './form-builder.form.interface.scale.sass';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';
import { FormBuilderElementsMappingService } from '../../../../services/builder/form-builder.elements-mapping.service';

const FormBuilderFormInterfaceScale = ({
  styles,
  item,
  styleElementFieldset = {},
  styleElementInput = {},
  styleElementLabel = {},
  values,
  analyticForm,
  t,
}) => {
  if (!item) return null;

  const elements = item.elements;

  const { legend, input } = FormBuilderElementsMappingService.getSingleElementHTMLTags(item);
  const defaultValues = values?.getAll(input.name);

  const onFocus = e => {
    e.target.style.outlineColor = styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS]?.outlineColor;
    analyticForm?.trackInputFocused(input.name);
  };

  const onBlur = () => analyticForm?.trackInputBlurred(input.name);

  return (
    <div className={item.class} style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS]}>
      <fieldset style={{ ...styleElementFieldset }}>
        <legend style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.LABEL] }}>
          {`${t(legend?.nodeValue)}${input?.required ? '*' : ''}`}
        </legend>
        {elements
          .filter(el => (el?.nodeName?.includes('input') && el?.type === 'radio') || el?.type === 'checkbox')
          .map((el, index) => {
            const labelNodeValue = elements.find(label => label?.for === el?.id)?.nodeValue;

            return (
              <Fragment key={index}>
                <input
                  type={input?.type}
                  name={el?.name}
                  id={el?.id}
                  defaultChecked={defaultValues?.indexOf(el?.value?.toString()) >= 0}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  value={el?.value}
                  required={input?.required}
                  style={{ ...styleElementInput }}
                />
                <label htmlFor={el?.id} style={{ ...styleElementLabel }}>
                  {t(labelNodeValue)}
                </label>
              </Fragment>
            );
          })}
      </fieldset>
    </div>
  );
};

FormBuilderFormInterfaceScale.displayName = 'FormBuilderFormInterfaceScale';
export default React.memo(FormBuilderFormInterfaceScale);
