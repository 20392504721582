import React from 'react';
import './form-builder.form.interface.input.sass';
import { useTranslation } from 'react-i18next';
import { FORM_BUILDER_INPUT_CLASSES, FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../utils/form-builder.types';
import { FormBuilderFormHint } from './common/hint/form-builder-form.hint';
import { FormBuilderElementsMappingService } from '../../../services/builder/form-builder.elements-mapping.service';

export const FormBuilderFormInterfaceInput = ({ styles, item, values, analyticForm }) => {
  if (!item) return null;

  const inputStyles = {
    [FORM_BUILDER_INPUT_CLASSES.DATE]: styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.DATE_INPUT],
    [FORM_BUILDER_INPUT_CLASSES.BIRTHDAY]: styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.BIRTHDAY_INPUT],
  };

  const { t } = useTranslation();

  const { input, label, p: hint } = FormBuilderElementsMappingService.getSingleElementHTMLTags(item);

  const handleFocus = e => {
    e.target.style.outlineColor = styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS]?.outlineColor;
    analyticForm?.trackInputFocused(input.name);
  };

  const handleBlur = () => {
    analyticForm?.trackInputBlurred(input.name);
  };

  return (
    <div
      className={item.class}
      style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS], ...styles[`div:has(input[id=${input.id}])`] }}>
      <label style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.LABEL]} htmlFor={label.for}>
        {t(label.nodeValue)}
      </label>
      <input
        style={{
          ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT],
          ...styles[`#${input.id}`],
          ...inputStyles?.[item],
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        id={input.id}
        name={input.name}
        min={input.min}
        max={input.max}
        minLength={input.minlength}
        maxLength={input.maxlength}
        type={input.type}
        defaultValue={values?.getAll(input.name)}
        autoComplete={input.autocomplete}
        pattern={input.pattern}
        placeholder={t(input.placeholder)}
        required={input.required}
        aria-describedby={input['aria-describedby']}
      />

      {hint && (
        <FormBuilderFormHint
          item={t(hint?.nodeValue)}
          id={hint?.id}
          style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.HINT]}
        />
      )}
    </div>
  );
};

FormBuilderFormInterfaceInput.displayName = 'FormBuilderFormInterfaceInput';
export default React.memo(FormBuilderFormInterfaceInput);
