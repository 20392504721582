import { ACTIONS } from '../../actions/builder/form-builder.builder.actions';
import produce from 'immer';
import { defaultFormStyles, GroupOneName } from '../../utils/form-builder-list.schema';
import {
  FORM_BUILDER_ELEMENTS_WITH_HINTS,
  FORM_BUILDER_LAYOUT_ELEMENT_TYPE,
  FORM_BUILDER_STYLE_ELEMENT_TYPE,
  itemClassToType,
} from '../../utils/form-builder.types';
import { createHint, createInputLabelGroup } from '../../utils/form-builder.form-elements.schema';
import { Config } from '../../../../utils/config';
import {
  _generateTranslation,
  FormBuilderTranslatorService,
} from '../../services/translator/form-builder.translator.service';
import { getSlugFromId } from '../../helpers/helpers';
import Logger from '../../../../services/logger/logger';

export const initialState = {
  language: Config.language.defaultLanguage,
  languages: [],
  isFetching: false,
  data: {
    groups: [],
    layout: [],
    locales: {},
    styles: {},
    mapping: {},
    draft: {
      groups: [],
      layout: [],
      locales: {},
      styles: {},
    },
  },
  included: [],
  selectedElement: null,
  selectedElementIndex: null,
  type: '',
  id: '',
  uploadedImages: [],
};

export const formBuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_CURRENT_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };

    case ACTIONS.UPLOAD_IMAGE:
      return {
        ...state,
        uploadedImages: [action.payload],
      };

    case ACTIONS.ADD_LOCALES:
      return produce(state, draft => {
        const { lang, locales } = action.payload;
        draft.languages.push(lang);
        draft.data.draft.locales[lang] = locales;
      });

    case ACTIONS.ADD_DEFAULT_LOCALES:
      return produce(state, draft => {
        const locales = draft.data.draft.locales;
        const uniqueKeysSet = new Set();

        for (const locale in locales) {
          for (const key in locales[locale]) {
            uniqueKeysSet.add(key);
          }
        }

        for (const locale in locales) {
          for (const key of uniqueKeysSet) {
            if (!locales[locale].hasOwnProperty(key)) {
              draft.data.draft.locales[locale][key] = '';
            }
          }
        }
      });

    case ACTIONS.REMOVE_LOCALES:
      return produce(state, draft => {
        const { lang } = action.payload;
        draft.languages = draft.languages.filter(language => language !== lang);
        delete draft.data.draft.locales[lang];
      });

    case ACTIONS.ADD_MISSING_HINTS:
      return produce(state, draft => {
        const allElements = draft.data.draft.groups[GroupOneName].elements;
        const locales = draft.data.draft.locales;
        const styles = draft.data.draft.styles;

        // This function is responsible for iterating through all elements and finding all elements
        // which do not contain hints, it also handles translations for them
        const addAllMissingHints = (elements, parentElement) => {
          for (const element of elements) {
            if (!elements.find(el => el.id?.includes('hint')) && element?.id) {
              const splitSlug = element.id?.split('_');

              if (
                typeof splitSlug[splitSlug.length - 1] === 'number' &&
                typeof splitSlug[splitSlug.length - 2] === 'number'
              ) {
                splitSlug.pop();
              }
              splitSlug.splice(splitSlug.length - 1, 0, 'hint');
              const slug = splitSlug.join('_');
              element['aria-describedby'] = slug;
              const hintTranslationKey = _generateTranslation(parentElement, element);
              for (const locale in locales) {
                locales[locale][hintTranslationKey] = '';
                FormBuilderTranslatorService.updateSingleTranslation(locale, hintTranslationKey, '');
              }

              const hint = createHint(slug, hintTranslationKey);
              elements.push(hint);
            }

            if (element.elements) {
              addAllMissingHints(element.elements, parentElement);
            }
          }
        };

        for (const element of allElements) {
          if (element.elements && FORM_BUILDER_ELEMENTS_WITH_HINTS.find(el => itemClassToType(element.class) === el)) {
            addAllMissingHints(element.elements, element);
          }
        }

        // Add Hint styles if they do not exist
        if (!styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.HINT]) {
          styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.HINT] = defaultFormStyles[FORM_BUILDER_STYLE_ELEMENT_TYPE.HINT];
        }
      });

    case ACTIONS.UPDATE_WORKFLOWS_PROPERTIES:
      return produce(state, draft => {
        const { mapping, accountType, accountId, emailType, emailTemplateId } = action.payload;

        if (accountType) {
          draft.data.workflows[accountType] = {
            account_id: accountId || draft.data.workflows?.[accountType]?.account_id,
            mapping: mapping,
          };
        }

        if (emailType) {
          draft.data.workflows[emailType] = {
            email_template_id: emailTemplateId || draft.data.workflows?.[emailType]?.email_template_id,
            mapping: mapping,
          };
        }
      });

    case ACTIONS.GET_FORM.REQUEST:
      return produce(state, draft => {
        draft.isFetching = true;
      });

    case ACTIONS.GET_FORM.FAILURE:
      return produce(state, draft => {
        draft.isFetching = false;
        draft.error = action.payload;
      });

    case ACTIONS.PATCH_FORM.FAILURE:
      return produce(state, draft => {
        draft.error = action.payload;
      });

    case ACTIONS.PATCH_FORM.SUCCESS:
      return produce(state, draft => {
        draft.data.mapping = action?.payload?.data.attributes?.mapping;
        draft.included = action?.payload?.included ? [...action?.payload?.included] : [];
        draft.isFetching = false;
      });

    case ACTIONS.GET_FORM.SUCCESS:
      return produce(state, draft => {
        draft.data = action.payload.data.attributes;
        draft.id = action.payload.data.id;
        draft.type = action.payload.data.type;
        draft.languages = Object.keys(action.payload.data.attributes.draft.locales);

        draft.included = action?.payload?.included ? [...action?.payload?.included] : [];
        draft.isFetching = false;
      });

    case ACTIONS.UPDATE_TRANSLATION:
      return produce(state, draft => {
        draft.data.draft.locales[action.payload.lang][action.payload.key] = action.payload.value;
      });

    case ACTIONS.UPDATE_TRANSLATIONS:
      return produce(state, draft => {
        const { translations } = action.payload;

        for (const lang in draft.data.draft.locales) {
          draft.data.draft.locales[lang] = { ...(draft.data.draft.locales[lang] || {}), ...translations };
        }
      });

    case ACTIONS.SELECT_ELEMENT:
      return {
        ...state,
        selectedElement: action.payload.e,
        selectedElementIndex: action.payload.index,
      };

    case ACTIONS.CANCEL_ELEMENT_SELECTION:
      return {
        ...state,
        selectedElement: null,
        selectedElementIndex: null,
      };

    case ACTIONS.DELETE_FORM_ELEMENT:
      return produce(state, draft => {
        const allElements = draft.data.draft.groups[GroupOneName].elements;
        const element = allElements[action.payload];
        const locales = draft.data.draft.locales;
        const styles = draft.data.draft.styles;

        // we must remove the unique styles of the element we are deleting if they exist

        const removeRelatedStyles = elements => {
          for (const element of elements) {
            for (const style in styles) {
              if (style.includes(element?.id)) {
                delete styles[style];
              }
            }

            if (element?.elements) {
              removeRelatedStyles(element?.elements);
            }
          }
        };

        if (element?.elements) {
          removeRelatedStyles(element.elements);
        }

        // we should also remove unused translation keys so there will be less trash in database

        const uniqueKeys = new Set();
        const translatableKeys = ['nodeValue', 'placeholder', 'content', 'src', 'alt', 'href'];

        const findAllTranslations = elements => {
          if (elements) {
            elements.forEach(e => {
              for (const key in e) {
                if (translatableKeys.some(el => el === key)) {
                  uniqueKeys.add(e[key]);
                }
              }
              if (e?.elements) {
                findAllTranslations(e.elements);
              }
            });
          }
        };

        findAllTranslations(element.elements);

        for (const key of uniqueKeys) {
          for (const locale in locales) {
            if (locales[locale].hasOwnProperty(key)) {
              delete draft.data.draft.locales[locale][key];
            }
          }
        }

        draft.data.draft.groups[GroupOneName].elements.splice(action.payload, 1);
      });

    case ACTIONS.REORDER_ELEMENT:
      return produce(state, draft => {
        const { from, to } = action.payload;
        const elementFrom = draft.data.draft.groups[GroupOneName].elements[from];
        const elementTo = draft.data.draft.groups[GroupOneName].elements[to];
        draft.data.draft.groups[GroupOneName].elements.splice(to, 1, elementFrom);
        draft.data.draft.groups[GroupOneName].elements.splice(from, 1, elementTo);
      });

    case ACTIONS.ADD_FORM_ELEMENT:
      return produce(state, draft => {
        const elements = draft.data.draft.groups[GroupOneName]?.elements;
        if (elements) {
          elements.push(action.payload.element);
        } else {
          draft.data.draft.groups = [{ elements: [action.payload.element] }];
        }
      });

    case ACTIONS.CANCEL_DRAFT_CHANGES:
      return produce(state, draft => {
        draft.data.draft.groups = draft.data.groups;
        draft.data.draft.locales = draft.data.locales;
        draft.data.draft.styles = draft.data.styles;
      });

    case ACTIONS.REMOVE_CLASS:
      return produce(state, draft => {
        const element = draft.data.draft.groups[action.payload.group].elements[action.payload.element];
        element.class = element.class.replace(action.payload.className, '').trim();
      });

    case ACTIONS.ADD_CLASS:
      return produce(state, draft => {
        const element = draft.data.draft.groups[action.payload.group].elements[action.payload.element];
        element.class = `${element.class} ${action.payload.className}`;
      });

    case ACTIONS.CHANGE_POST_SUBMIT_ACTION:
      return produce(state, draft => {
        draft.data.draft.layout.postSubmit = action.payload;
      });

    case ACTIONS.UPDATE_NODE_NAME_PROPERTY:
      return produce(state, draft => {
        const updateElementProperty = (elements, nodeName, property, value) => {
          // Check if elements exist or are an array
          if (!elements || !Array.isArray(elements)) {
            return;
          }

          for (const elem of elements) {
            // Check if this is the element we need to update
            if (elem.nodeName === nodeName) {
              // Check if the element has the property we want to update, to avoid adding a new one
              if (Object.prototype.hasOwnProperty.call(elem, property)) {
                // Update the property with the new value
                elem[property] = value;
              } else {
                Logger.warning(`Property "${property}" does not exist on element with nodeName: "${nodeName}"`);
              }
            }

            // If the current element has nested elements, recursively update them as well
            if (elem.elements) {
              updateElementProperty(elem.elements, nodeName, property, value);
            }
          }
        };

        const { group, element, nodeName, property, value } = action.payload;

        const targetGroup = draft.data.draft.groups[group];

        if (targetGroup) {
          updateElementProperty(targetGroup.elements[element].elements, nodeName, property, value);
        } else {
          Logger.warning(`No group found with index: "${group}"`);
        }
      });

    case ACTIONS.UPDATE_ELEMENT_PROPERTY:
      return produce(state, draft => {
        const element =
          draft.data.draft.groups[action.payload.group].elements[action.payload.element].elements[
            action.payload.selectedSubElementIndex
          ];
        if (element) {
          element[action.payload.property] = action.payload.value;
        }
      });

    case ACTIONS.UPDATE_ELEMENT_PROPERTY_BY_UNIQUE_PROPERTY:
      return produce(state, draft => {
        const { propertyNameId } = action.payload;
        const updateElementProperty = (elements, id, property, value) => {
          if (!elements || !Array.isArray(elements)) {
            return;
          }

          for (const elem of elements) {
            if (elem[propertyNameId] === id) {
              if (Object.prototype.hasOwnProperty.call(elem, property)) {
                elem[property] = value;
              } else {
                Logger.warning(`Property "${property}" does not exist on element with id: "${id}"`);
              }
            }

            if (elem.elements) {
              updateElementProperty(elem.elements, id, property, value);
            }
          }
        };

        const { id, property, value } = action.payload;

        const targetGroup = draft.data.draft.groups[GroupOneName];

        if (targetGroup) {
          updateElementProperty(targetGroup.elements, id, property, value);
        } else {
          Logger.warning(`No group found with index: "${GroupOneName}"`);
        }
      });

    case ACTIONS.UPDATE_ELEMENT_PROPERTY_BY_ID:
      return produce(state, draft => {
        //only god can judge me
        const updateElementProperty = (elements, id, property, value, addDefault) => {
          if (!elements || !Array.isArray(elements)) {
            return;
          }

          for (const elem of elements) {
            if (elem.id === id) {
              if (Object.prototype.hasOwnProperty.call(elem, property) || addDefault) {
                elem[property] = value;
              } else {
                Logger.warning(`Property "${property}" does not exist on element with id: "${id}"`);
              }
            }

            if (elem.elements) {
              updateElementProperty(elem.elements, id, property, value, addDefault);
            }
          }
        };

        const { id, property, value, addDefault } = action.payload;

        const targetGroup = draft.data.draft.groups[GroupOneName];

        if (targetGroup) {
          updateElementProperty(targetGroup.elements, id, property, value, addDefault);
        } else {
          Logger.warning(`No group found with index: "${GroupOneName}"`);
        }
      });

    case ACTIONS.CHANGE_SINGLE_MATRIX_VALUES:
      return produce(state, draft => {
        const { value, elementIndex, selectedElementIndex, changeQuestion } = action.payload;
        const group = draft.data.draft.groups[GroupOneName];
        const tbody = group.elements[selectedElementIndex].elements[1].elements[1];

        if (changeQuestion) {
          const tr = tbody.elements[elementIndex];

          for (const elem of tr.elements) {
            if (elem?.elements) {
              const input = elem?.elements[0];

              const question = input.name.match(/\[(.*?)\]/)[1];
              const splitName = input.name.split(`[${question}]`);
              splitName[1] = `[${value}]`;
              input.name = splitName.join('');
            }
          }
        } else {
          for (const tr of tbody.elements) {
            const input = tr.elements[elementIndex + 1].elements[0];
            input.value = value;
          }
        }
      });

    case ACTIONS.CHANGE_SINGLE_MATRIX_ELEMENT_ID:
      return produce(state, draft => {
        const { value, selectedElementIndex } = action.payload;
        const group = draft.data.draft.groups[GroupOneName];
        const tbody = group.elements[selectedElementIndex].elements[1].elements[1];

        // This logic is here because Single Matrix element name consists of the value of its id
        // and value of associated question put inside an array,
        // which means the names vary between different inputs inside the element.
        // Example: single_matrix_0[question_1], single_matrix_0[question_2]
        const updateAllNames = elements => {
          for (const elem of elements) {
            if (elem?.name) {
              const questionValue = elem.name.match(/\[(.*?)\]/)[1];
              const elementName = `${value}[${questionValue}]`;

              if (elem?.type === 'checkbox') {
                elem.name = `${elementName}[]`;
              } else {
                elem.name = elementName;
              }
            }
            if (elem?.elements) {
              updateAllNames(elem.elements);
            }
          }
        };

        for (const tr of tbody.elements) {
          updateAllNames(tr.elements);
        }
      });

    // This action is responsible for adding answers and questions in single matrix element
    case ACTIONS.ADD_SINGLE_MATRIX_OPTIONS:
      return produce(state, draft => {
        const { selectedElementIndex, addQuestion, newOption, newTheadElement } = action.payload;
        const group = draft.data.draft.groups[GroupOneName];
        const fieldset = group.elements[selectedElementIndex];
        const thead = fieldset.elements[1].elements[0];
        const tbody = fieldset.elements[1].elements[1];

        if (addQuestion) {
          tbody.elements.push(newOption);
        } else {
          thead.elements[0].elements.push(newTheadElement);
          tbody.elements = [...newOption];
        }
      });

    // This action is responsible for deleting answers and questions in single matrix element
    case ACTIONS.DELETE_SINGLE_MATRIX_OPTIONS:
      return produce(state, draft => {
        const { selectedElementIndex, deleteQuestion, elementIndex } = action.payload;
        const group = draft.data.draft.groups[GroupOneName];
        const fieldset = group.elements[selectedElementIndex];
        const tbody = fieldset.elements[1].elements[1];
        const thead = fieldset.elements[1].elements[0];

        if (deleteQuestion) {
          tbody.elements.splice(elementIndex, 1);
        } else {
          for (const tr of tbody.elements) {
            tr.elements.splice(elementIndex, 1);
          }
          thead.elements[0].elements.splice(elementIndex, 1);
        }
      });

    case ACTIONS.UPDATE_STYLES:
      return produce(state, draft => {
        draft.data.draft.styles = { ...action.payload.style };
      });

    case ACTIONS.UPDATE_STYLE:
      return produce(state, draft => {
        const style = draft.data.draft.styles[action.payload.id];
        if (style) {
          draft.data.draft.styles[action.payload.id] = { ...style, ...action.payload.style };
        } else {
          draft.data.draft.styles[action.payload.id] = action.payload.style;
        }
      });

    case ACTIONS.ADD_SUCCESS_MESSAGE_LINK:
      return produce(state, draft => {
        const successMessageElements =
          draft.data.draft.groups[FORM_BUILDER_LAYOUT_ELEMENT_TYPE.SUCCESS_MESSAGE].elements;
        draft.data.draft.groups[FORM_BUILDER_LAYOUT_ELEMENT_TYPE.SUCCESS_MESSAGE].elements = [
          ...successMessageElements,
          action.payload,
        ];
      });

    case ACTIONS.PATCH_SUCCESS_MESSAGE_LINK:
      return produce(state, draft => {
        const successMessageElement = draft.data.draft.groups[
          FORM_BUILDER_LAYOUT_ELEMENT_TYPE.SUCCESS_MESSAGE
        ].elements.find(el => el.key === action.payload.key);
        const getCurrentPatchProperty = () => {
          return action.payload.nodeName ? { name: action.payload.data } : { nodeValue: action.payload.data };
        };

        const itemIndex = draft.data.draft.groups[FORM_BUILDER_LAYOUT_ELEMENT_TYPE.SUCCESS_MESSAGE].elements.indexOf(
          successMessageElement
        );
        draft.data.draft.groups[FORM_BUILDER_LAYOUT_ELEMENT_TYPE.SUCCESS_MESSAGE].elements[itemIndex] = {
          ...successMessageElement,
          ...getCurrentPatchProperty(),
        };
      });

    case ACTIONS.CHANGE_RATING_OPTIONS_COUNT:
      return produce(state, draft => {
        const { group, element: elementIndex, value } = action.payload;
        const elementGroup = draft.data.draft.groups[group];
        const element = elementGroup.elements[elementIndex];

        const numberOfStars = element.elements.filter(el => el.nodeName === 'input').length;

        if (numberOfStars > value) {
          element.elements.splice(value * 2 + 1);
        } else {
          const inputElement = element.elements.find(el => el.nodeName.includes('input'));
          const slug = getSlugFromId(inputElement?.id);
          const newStars = Array.from({ length: value - numberOfStars }, (_, index) => {
            const startValue = index + numberOfStars + 1;
            return createInputLabelGroup({
              slug,
              inputName: inputElement.name,
              index: startValue,
              labelValue: startValue,
              inputValue: startValue,
              inputType: 'radio',
              combineSlugAndIndex: true,
            });
          }).flat();
          element.elements = [...element.elements, ...newStars];
        }
      });

    case ACTIONS.CHANGE_NUMBER_SCALE_OPTIONS_COUNT:
      return produce(state, draft => {
        const { group, elementIndex, newElements } = action.payload;
        const elementGroup = draft.data.draft.groups[group];
        const element = elementGroup.elements[elementIndex];
        element.elements = [...newElements];
      });

    case ACTIONS.ADD_NESTED_FORM_ELEMENT_NEW:
      return produce(state, draft => {
        const { parentId, element, replaceOldWithNew } = action.payload;

        const findParentElement = (elements, id) => {
          for (const element of elements) {
            if (element.id === id) {
              return element;
            } else if (element.elements) {
              const foundElement = findParentElement(element.elements, id);
              if (foundElement) {
                return foundElement;
              }
            }
          }
        };

        const parentElement = findParentElement(draft.data.draft.groups[GroupOneName].elements, parentId);
        if (parentElement) {
          if (replaceOldWithNew) {
            parentElement.elements = Array.isArray(element) ? [...element] : [element];
          } else {
            parentElement.elements = [
              ...(parentElement.elements || []),
              ...(Array.isArray(element) ? element : [element]),
            ];
          }
        }
      });

    case ACTIONS.ADD_NESTED_FORM_ELEMENT:
      return produce(state, draft => {
        const { group, elementIndex, element: newElement } = action.payload;
        const elementGroup = draft.data.draft.groups[group];
        const element = elementGroup.elements[elementIndex];
        element.elements = [...element.elements, ...newElement.elements];
      });

    case ACTIONS.DELETE_NESTED_FORM_ELEMENT:
      return produce(state, draft => {
        const {
          group,
          selectedElementIndex,
          property,
          parentId,
          deleteElementIndex,
          deleteElementId,
          deleteAll,
        } = action.payload;
        const elementGroup = draft.data.draft.groups[group];
        const element = elementGroup.elements[selectedElementIndex];

        const deleteElement = elements => {
          let parentElement;
          if (parentId) {
            parentElement = elements.find(el => el?.id === parentId);
          } else {
            parentElement = element?.elements?.find(el => el?.id === deleteElementId)
              ? element
              : elements.find(el => el?.elements?.find(el => el?.id === deleteElementId));
          }

          if (parentElement) {
            if (deleteAll) {
              parentElement.elements = [];
              return;
            }

            if (property) {
              parentElement.elements = parentElement.elements.filter(el => {
                if (Array.isArray(deleteElementId)) {
                  return !deleteElementId.includes(el?.[property]);
                } else {
                  return !(el?.[property] === deleteElementId);
                }
              });
              return;
            }

            if (deleteElementIndex >= 0) {
              parentElement.elements.splice(deleteElementIndex, 1);
              return;
            }

            if (deleteElementId) {
              parentElement.elements = parentElement.elements.filter(el => {
                return !((el?.for && el?.for === deleteElementId) || (el?.id && el?.id === deleteElementId));
              });
            }
          } else {
            for (const element of elements) {
              if (element?.elements) {
                deleteElement(element.elements);
              }
            }
          }
        };

        deleteElement(element.elements);
      });

    case ACTIONS.PUBLISH_FORM.SUCCESS:
      return produce(state, draft => {
        draft.data.styles = draft.data.draft.styles;
        draft.data.locales = draft.data.draft.locales;
        draft.data.groups = draft.data.draft.groups;
        draft.data.layout = draft.data.draft.layout;
      });

    case ACTIONS.RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
