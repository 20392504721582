import React, { useEffect, useState } from 'react';
import { InputGroup } from '../../../../../../../../components/interface/inputs/input-group/input-group';
import {
  dropdownAdditionalSelectField,
  dropdownAdditionalFormSelectField,
  dropdownMissionHubsSelectField,
  dropdownAdditionalExperienceSelectField,
} from '../../utils/common/common';
import { filters } from '../../../../../../../account-settings/utils/filter-helper/filter-helper';
import { getSocialConnectionSubaccountDrodpown } from '../../../../../../../account-settings/actions/social-connections/social-connections.fat-actions';
import { handleApiError } from '../../../../../../../../services/functions/error-handler/error-handler';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { SocialFeedsSource } from '../../../../../../../../types/social-feeds';
import { getExperience, getForm } from '../../actions/social-feeds.fat-actions';
import TintIcon from '../../../../../../../../components/interface/icon/icon';
import { faArrowUpRight, faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import './account-dropdown.sass';
import { Config } from '../../../../../../../../utils/config';
import { SocialFeedsAccountTypes } from '../../consts';
import { FORM_BUILDER_PUBLISHED } from '../../../../../../../../types/form-builder';
import { FORM_BUILDER_TEMPLATE_TYPE } from '../../../../../../../form-builder/utils/templates/helpers/config';
import { DropdownAccounts } from '../../../../../../../../components/api-dropdown/accounts/dropdown-accounts';
import { openSidebar } from '../../../../../../../../actions/ui/ui.actions';
import { socialAccountFormType } from '../../../../../../../account-settings/components/social-connections-modal/social-account-form/social-account-form';

const dropdownAccountDefaultValue = {
  name: 'Select Account...',
  value: 'select-account',
};

const dropdownNameDefaultValue = {
  name: 'Select...',
  value: 'select-name',
};

export const AccountStatus = {
  EXPIRED: 'expired',
  OK: 'ok',
};

const AccountDropdown = ({
  activeSocialFeedSource,
  accountSourceType,
  label,
  icon,
  id,
  formik,
  clearSubAccountsDropdown,
}) => {
  const [newAddedAccountId, setNewAddedAccountId] = useState();
  const socialAccounts = useSelector(state => state.socialConnections);
  const dispatch = useDispatch();
  const accountSocialFeed =
    activeSocialFeedSource !== SocialFeedsSource.EXPERIENCE_BUILDER &&
    activeSocialFeedSource !== SocialFeedsSource.VESTA &&
    activeSocialFeedSource !== SocialFeedsSource.FORMS &&
    activeSocialFeedSource !== SocialFeedsSource.PUBLIC_POST &&
    activeSocialFeedSource !== SocialFeedsSource.REVIEWS_RATINGS;

  const subAccountsSocialFeedList = [
    SocialFeedsSource.FACEBOOK,
    SocialFeedsSource.LINKED_IN,
    SocialFeedsSource.SLACK,
    SocialFeedsSource.HOOT_SUITE,
    SocialFeedsSource.WEBEX,
    SocialFeedsSource.INSTAGRAM_BUSINESS,
    SocialFeedsSource.PINTEREST,
    SocialFeedsSource.YOUTUBE,
    SocialFeedsSource.CREATOR_IQ,
  ];

  const hasAddAccountForm = [
    SocialFeedsSource.CREATOR_IQ,
    SocialFeedsSource.BAZAARVOICE,
    SocialFeedsSource.POWER_REVIEWS,
    SocialFeedsSource.RE_CAPTCHA,
  ].includes(accountSourceType);

  useEffect(() => {
    if (newAddedAccountId !== undefined) {
      const externalIdSocialFeedList = [
        SocialFeedsSource.FACEBOOK,
        SocialFeedsSource.LINKED_IN,
        SocialFeedsSource.TIK_TOK,
      ];

      const account = socialAccounts.accounts.data.find(social => social.id === newAddedAccountId);
      const currentItem = !externalIdSocialFeedList.includes(activeSocialFeedSource)
        ? { name: account.attributes.name || account.attributes.username, value: account.id }
        : {
            name: account.attributes.name || account.attributes.username,
            value: account.id,
            externalId: account.attributes.external_id,
          };

      formik.setFieldValue(['account'], currentItem, false);
      setNewAddedAccountId(undefined);
    }
  }, [activeSocialFeedSource, formik, newAddedAccountId, setNewAddedAccountId, socialAccounts]);

  const queryParams = {
    [SocialFeedsAccountTypes.MISSION]: {
      baseUrl: '/missions',
      placeholder: 'Select Mission...',
      additionalOption: {
        name: dropdownMissionHubsSelectField.name,
        value: dropdownMissionHubsSelectField.value,
        link: Config.routePaths.missionHubs.path,
      },
    },
    [SocialFeedsAccountTypes.FORM]: {
      baseUrl: '/forms',
      filters: [
        {
          by: filters.PUBLISHED,
          value: FORM_BUILDER_PUBLISHED.YES,
        },
        ...(activeSocialFeedSource === SocialFeedsSource.PUBLIC_POST
          ? [
              {
                by: filters.TYPE,
                value: `${FORM_BUILDER_TEMPLATE_TYPE.PUBLIC_POST},${FORM_BUILDER_TEMPLATE_TYPE.BLANK}`,
              },
            ]
          : []),
        ...(activeSocialFeedSource === SocialFeedsSource.REVIEWS_RATINGS
          ? [
              {
                by: filters.TYPE,
                value: `${FORM_BUILDER_TEMPLATE_TYPE.PRODUCT_REVIEW},${FORM_BUILDER_TEMPLATE_TYPE.BLANK}`,
              },
            ]
          : []),
      ],
      placeholder: 'Select Form...',
      additionalOption: {
        name: dropdownAdditionalFormSelectField.name,
        value: dropdownAdditionalFormSelectField.value,
        link: Config.routePaths.formBuilder.new.path,
      },
    },
    [SocialFeedsAccountTypes.ACCOUNT]: {
      baseUrl: '/accounts',
      filters: [
        {
          by: filters.TYPE,
          value: accountSourceType,
        },
      ],
      placeholder: 'Select Account...',
      ...(hasAddAccountForm
        ? {
            additionalOption: {
              action: () => dispatch(openSidebar(socialAccountFormType)),
              name: dropdownAdditionalSelectField.name,
              value: dropdownAdditionalSelectField.value,
            },
          }
        : { additionalOption: undefined }),
    },
    [SocialFeedsAccountTypes.EXPERIENCE]: {
      baseUrl: '/experiences',
      fetchFnMapper: e => ({ name: e.attributes.name, value: e.id, externalId: e.attributes?.external_id }),
      filterDataFn: e => e?.relationships?.form_submission_keys?.data?.length > 0,
      placeholder: 'Select Experience...',
      additionalOption: {
        name: dropdownAdditionalExperienceSelectField.name,
        value: dropdownAdditionalExperienceSelectField.value,
        link: Config.routePaths.experienceBuilder.new.path,
      },
      include: 'form_submission_keys',
    },
  };

  const getSubAccounts = value => {
    dispatch(
      getSocialConnectionSubaccountDrodpown({
        socialConnectionId: value,
      })
    ).catch(err => handleApiError(err, toast.error));
  };

  const onChangeValue = (selectedItem, id) => {
    switch (activeSocialFeedSource) {
      case SocialFeedsSource.FORMS:
      case SocialFeedsSource.PUBLIC_POST:
      case SocialFeedsSource.REVIEWS_RATINGS:
        if (id === SocialFeedsAccountTypes.FORM) {
          dispatch(getForm({ formId: selectedItem.value }))
            .then(() => formik.resetForm())
            .catch(err => handleApiError(err, toast.error));
        }
        formik.setFieldValue([id], selectedItem);
        break;

      case SocialFeedsSource.EXPERIENCE_BUILDER:
        if (id === SocialFeedsAccountTypes.EXPERIENCE) {
          formik.setFieldValue('name', dropdownNameDefaultValue);
          formik.setFieldValue('body', dropdownNameDefaultValue);
          dispatch(getExperience({ experienceId: selectedItem.value })).catch(err => handleApiError(err, toast.error));
        }
        formik.setFieldValue([id], selectedItem);
        break;

      default:
        clearSubAccountsDropdown && clearSubAccountsDropdown(formik);
        if (selectedItem.value === dropdownAdditionalSelectField.value) {
          formik.setFieldValue('account', dropdownAccountDefaultValue);
        } else {
          if (subAccountsSocialFeedList.includes(activeSocialFeedSource)) {
            if (selectedItem.value && selectedItem.value !== '') getSubAccounts(selectedItem.value);
          }
          formik.setFieldValue([id], selectedItem);
        }
        break;
    }
  };

  return (
    <>
      <InputGroup label={label} inputGroupIcon={icon} error={formik.errors[id]} touched={formik.touched[id]}>
        <div>
          <DropdownAccounts
            currentElement={formik.values?.[id]}
            queryParams={queryParams?.[id] || ''}
            placeholder={queryParams?.[id]?.placeholder}
            onChange={e => onChangeValue(e, id)}
            accountType={accountSourceType}
            include={queryParams?.[id]?.include}
            onAccountCreate={data => {
              setNewAddedAccountId(data.id);
              getSubAccounts(data.id);
            }}
            additionalOption={queryParams?.[id]?.additionalOption}
            fetchFnMapper={queryParams?.[id]?.fetchFnMapper}
            filterDataFn={queryParams?.[id]?.filterDataFn}
          />
        </div>
      </InputGroup>
      {accountSocialFeed && !formik.values[id] && (
        <div className='account-dropdown__info-card'>
          <div>
            <TintIcon icon={faQuestionCircle} fontSize='16px' />
            <p>
              Can't find the account you're
              <br />
              looking for?
            </p>
          </div>
          <a href={Config.url.zenDesk?.[activeSocialFeedSource]} target='_blank' rel='noreferrer'>
            <span>Learn More</span>
            <TintIcon icon={faArrowUpRight} fontSize='16px' />
          </a>
        </div>
      )}
    </>
  );
};
export default React.memo(AccountDropdown);
