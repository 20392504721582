import React, { Component } from 'react';
import { EditInput } from '../../../';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { clone } from '../../../../../../../services/functions/clone/clone';
import { createTranslationKey } from '../../../../../containers/experience/translation-creator';

const inputOrder = {
  instagram: 0,
  twitter: 1,
  facebook: 2,
  tiktok: 3,
  linkedin: 4,
  youtube: 5,
  pinterest: 6,
  tumblr: 7,
  vimeo: 8,
};

const editInputs = [
  { type: 'instagram', url: 'instagram.com/example' },
  { type: 'twitter', url: 'twitter.com/example' },
  { type: 'facebook', url: 'facebook.com/example' },
  { type: 'tiktok', url: 'tiktok.com/example' },
  { type: 'linkedin', url: '' },
  { type: 'youtube', url: '' },
  { type: 'pinterest', url: '' },
  { type: 'tumblr', url: '' },
  { type: 'vimeo', url: 'vimeo.com/example' },
];

export class EditSocialContent extends Component {
  componentWillMount() {
    this.socialLinks = this.props.data.socialLinks;
  }

  componentDidMount() {
    editInputs.map(editValue => {
      let _value = this.socialLinks.find(socialLink => socialLink.type === editValue.type)?.url;
      if (!_value) {
        const socialLinkTranslationKey = createTranslationKey(
          this.props.experienceType,
          'SOCIAL',
          'LINK',
          this.socialLinks.length
        );
        let tmpSocialLinks = clone(this.socialLinks);
        tmpSocialLinks.push({ type: editValue.type, url: socialLinkTranslationKey });
        this.socialLinks = tmpSocialLinks;
        this.props.dataUpdate({ ['socialLinks']: tmpSocialLinks });
        this.props.onNewTranslationUpdate(socialLinkTranslationKey, '');
      }
    });
  }

  renderEditInput() {
    let dataClone = editInputs.slice();
    dataClone.sort((a, b) => {
      return inputOrder[a.type] > inputOrder[b.type];
    });

    return dataClone.map((e, i) => {
      let _defaultValue = this.socialLinks.find(el => el.type === e.type)?.url;
      return (
        <div key={i}>
          <EditInput
            onChange={event => {
              this.props.onTranslationUpdate(_defaultValue, event.target.value);
            }}
            label={e.type}
            placeholder='Enter URL'
            defaultValue={this.props.getTranslation(_defaultValue)}
          />
        </div>
      );
    });
  }

  render() {
    return (
      <EditModalBody title='Display icons for your accounts. Insert URLs to add social icons to the layout.'>
        <form
          noValidate
          onSubmit={e => {
            e.preventDefault();
            this.props.saveData();
          }}>
          {this.renderEditInput()}
          <button style={{ display: 'none' }} type='submit'>
            Submit
          </button>
        </form>
      </EditModalBody>
    );
  }
}
