import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
import { FORM_HEADER_STYLES_VARIABLES } from "../../root/schema/variables/form-header";
export const header = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.HEADER_H1]: {
    margin: 0,
    color: `var(${FORM_HEADER_STYLES_VARIABLES.TITLE_COLOR})`,
    "font-size": `var(${FORM_HEADER_STYLES_VARIABLES.TITLE_FONT_SIZE})`,
    "font-weight": `var(${FORM_HEADER_STYLES_VARIABLES.TITLE_FONT_WEIGHT})`,
    "font-style": `var(${FORM_HEADER_STYLES_VARIABLES.TITLE_FONT_STYLE})`
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.HEADER_P]: {
    margin: 0,
    color: `var(${FORM_HEADER_STYLES_VARIABLES.CAPTION_COLOR})`,
    "font-size": `var(${FORM_HEADER_STYLES_VARIABLES.CAPTION_FONT_SIZE})`
  }
};
