import { Config } from '../../utils/config';

export const isModalOpen = (state, id) => {
  if (!state) return false;

  return !!state?.ui?.modals[id];
};
export const getModalData = (state, id) => {
  if (!state) return false;

  return state?.ui?.modals[id];
};

export const isSidebarOpen = (state, id) => {
  if (!state) return false;

  return !!state?.ui?.sidebars[id];
};

export const getSidebarData = (state, id) => {
  if (!state) return false;

  return {
    ...state?.ui?.sidebars[id],
    isOpen: isSidebarOpen(state, id),
  };
};

export const UISelectorKeys = {
  DROPDOWNS: 'dropdowns',
  INFINITE_LIST: 'infiniteLists',
  SIDEBARS: 'sidebars',
  MODALS: 'modals',
};

export const getInfiniteData = (key, state, id, fetchFnMapper, filterDataFn = Boolean) => {
  if (!state) return false;

  const _data = state?.ui?.[key]?.[id];

  return {
    ..._data,
    data:
      _data?.query?.length > 0
        ? _data?.data?.filter(filterDataFn)?.map(el => ({ value: el.id, name: el.attributes.name }))
        : mapDropdownList(_data?.data, fetchFnMapper, filterDataFn),
    isAbleToLoad: _data?.links?.next && _data?.data?.length !== 0,
    page: Math.round(_data?.data?.length / Config.defaultInfiniteListPageSize),
  };
};

const mapDropdownList = (data, fetchFnMapper, filterDataFn) => data?.filter(filterDataFn)?.map(fetchFnMapper) || [];

export const getDropdownData = (state, id) => {
  if (!state) return false;

  return state?.ui?.dropdowns[id];
};
