import { ACTIONS } from '../actions/asset-manager.actions';
import { assetDisplayType } from '../model/asset-display-type';
import produce from 'immer';

export const initialState = {
  data: [],
  meta: { aggregations: { emotions: [] } },
  links: undefined,
  isFetching: false,
  error: undefined,
  isBulkTagSidebarOpen: false,
  selectedLightBoxAsset: undefined,
  isCreateAssetModalOpened: false,
  displayType: assetDisplayType.GRID_SMALL,
};

export const assetManager = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SELECT_LIGHT_BOX_ASSET:
      return { ...state, selectedLightBoxAsset: action.payload };

    case ACTIONS.CLEAR_ASSETS_STATE:
      return { ...initialState, links: state.links, meta: state.meta };

    case ACTIONS.TOGGLE_BULK_TAG_SIDEBAR:
      return {
        ...state,
        isBulkTagSidebarOpen: action.payload,
      };

    case ACTIONS.UPDATE_ASSET.SUCCESS:
      return {
        ...state,
        ...{
          data: state.data.map(asset => {
            return {
              ...asset,
            };
          }),
        },
      };

    case ACTIONS.UPDATE_BULK_TAG.SUCCESS:
      return produce(state, draft => {
        draft.data = state.data.map(asset => {
          const currentAsset = action.payload.find(_asset => _asset.id === asset.id);
          return currentAsset
            ? {
                ...asset,
                ...currentAsset,
              }
            : asset;
        });
        draft.isFetching = false;
      });

    case ACTIONS.UPDATE_ASSET_TAGS.SUCCESS:
      return {
        ...state,
        ...{
          data: state.data.map(asset => {
            asset.id === action.payload.id ? (asset.attributes.tags = action.payload.attributes.tags) : [];
            return asset;
          }),
        },
      };

    case ACTIONS.UPDATE_ASSETS_TO_COLLECTIONS.REQUEST:
    case ACTIONS.UPDATE_BULK_TAG.REQUEST:
    case ACTIONS.POST_ASSETS.REQUEST:
    case ACTIONS.GET_ASSETS.REQUEST:
      return {
        ...state,
        ...{
          isFetching: true,
          isError: false,
        },
      };

    case ACTIONS.UPDATE_ASSETS_TO_COLLECTIONS.SUCCESS:
      return produce(state, draftState => {
        draftState.data = draftState.data.map(item => {
          const update = action.payload.data.find(el => el.id === item.id);

          if (update) {
            const mapUpdatedElement = {
              ...update,
              isSelected: false,
              collections: action.payload.included?.filter(
                e => e.type === 'collection' && update?.relationships?.collections?.data?.some(r => r.id === e.id)
              ),
            };

            return { ...item, ...mapUpdatedElement };
          } else {
            return item;
          }
        });

        draftState.isFetching = false;
        draftState.isError = false;
      });

    case ACTIONS.GET_ASSETS.SUCCESS:
      return {
        ...state,
        ...{
          data: action.payload.assets,
          included: action.payload.included,
          links: action.payload.links,
          meta: action.payload.meta,
          isFetching: false,
          isError: false,
        },
      };

    case ACTIONS.POST_ASSETS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        isError: false,
      };

    case ACTIONS.UPDATE_ASSETS_TO_COLLECTIONS.FAILURE:
    case ACTIONS.UPDATE_BULK_TAG.FAILURE:
    case ACTIONS.GET_ASSETS.FAILURE:
    case ACTIONS.POST_ASSETS.FAILURE:
      return {
        ...state,
        ...{
          isError: action.payload,
          isFetching: false,
        },
      };

    case ACTIONS.OPEN_CREATE_ASSET_MODAL:
      return {
        ...state,
        isCreateAssetModalOpened: true,
      };

    case ACTIONS.CLOSE_CREATE_ASSET_MODAL:
      return {
        ...state,
        isCreateAssetModalOpened: false,
      };

    default:
      return state;
  }
};

export const getSelectedAssets = state => {
  return state && state.data ? state.data.filter(e => e.isSelected) : [];
};

export const getBulkTagSidebarVisibility = state => {
  return state && state?.assetManager.isBulkTagSidebarOpen;
};

export const isOneAssetSelected = state => getSelectedAssets(state).length === 1;
export const isAnyAssetSelected = state => {
  return getSelectedAssets(state).length > 0;
};

export const getAllAssets = state => {
  return state && state?.assetManager?.data;
};

export const getSelectedAsset = state => {
  if (!state) return;

  const assets = getAllAssets(state);

  return getSelectedAssets(state.assetManager).length === 1
    ? assets.find(el => el.id === state.assetManager.selectedAssetId)
    : {};
};

export const getSelectedAssetCollections = state => {
  if (!state) return [];

  const selectedAssetsLength = state.assetManager.data.filter(e => e.isSelected).length;

  const currentAsset = selectedAssetsLength === 1 ? state.assetManager.data.find(el => el.isSelected) : {};

  return currentAsset
    ? currentAsset?.collections?.map(collection => ({
        value: collection.id,
        name: collection?.attributes?.name,
      }))
    : [];
};

export const getCurrentAsset = state => {
  return state && state?.asset?.asset;
};
