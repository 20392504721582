import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../../elements-classes/form-builder.styles.elements-classes";
export const matrix = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MATRIX_THEAD]: {
    display: "none"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MATRIX_TR]: {
    "margin-bottom": "24px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MATRIX_TR_TH]: {
    display: "block"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MATRIX_TD]: {
    display: "flex",
    "align-items": "center"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MATRIX_TH]: {
    "text-align": "initial"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MATRIX_TD_AFTER]: {
    content: "attr(data-label)",
    "padding-left": "10px",
    "font-weight": "400"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MATRIX_TD_AFTER_RTL]: {
    "padding-left": 0,
    "padding-right": "10px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MATRIX_TD_TH]: {
    padding: "8px 0",
    "font-weight": "400"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MATRIX_INPUT]: {
    width: "16px",
    height: "16px",
    "min-width": "16px",
    "min-height": "16px",
    padding: "0"
  }
};
