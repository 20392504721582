import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const thumbScale = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.THUMB_SCALE_LABEL]: {
    position: "relative",
    display: "inline-block",
    border: "1px solid #DFDFDF",
    padding: "10px 10px 10px 32px",
    "border-radius": "6px",
    margin: "0 10px 10px 0"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.THUMB_SCALE_INPUT]: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    "border-radius": "6px",
    border: 0,
    margin: 0,
    background: "transparent",
    "min-height": "initial"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.THUMB_SCALE_INPUT_BEFORE]: {
    position: "absolute",
    left: "8px",
    top: "50%",
    transform: "translateY(-50%)",
    "font-size": 0
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.THUMB_SCALE_LABEL_ACTIVE]: {
    background: "#3B99FC",
    color: "#ffffff"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.THUMB_SCALE_LABEL_THUMB_UP]: {
    content: `url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="18" height="18" viewBox="0 0 512 512"%3E%3Cpath fill="black" d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2h144c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48h-97.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7M32 192h64c17.7 0 32 14.3 32 32v224c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32"%2F%3E%3C%2Fsvg%3E')`
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.THUMB_SCALE_LABEL_THUMB_UP_ACTIVE]: {
    content: `url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="18" height="18" viewBox="0 0 512 512"%3E%3Cpath fill="white" d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2h144c26.5 0 48 21.5 48 48c0 18.5-10.5 34.6-25.9 42.6C497 275.4 504 288.9 504 304c0 23.4-16.8 42.9-38.9 47.1c4.4 7.3 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48h-97.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7M32 192h64c17.7 0 32 14.3 32 32v224c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32"%2F%3E%3C%2Fsvg%3E')`
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.THUMB_SCALE_LABEL_THUMB_DOWN]: {
    content: `url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="18" height="18" viewBox="0 0 512 512"%3E%3Cpath fill="black" d="M313.4 479.1c26-5.2 42.9-30.5 37.7-56.5l-2.3-11.4c-5.3-26.7-15.1-52.1-28.8-75.2h144c26.5 0 48-21.5 48-48c0-18.5-10.5-34.6-25.9-42.6C497 236.6 504 223.1 504 208c0-23.4-16.8-42.9-38.9-47.1c4.4-7.3 6.9-15.8 6.9-24.9c0-21.3-13.9-39.4-33.1-45.6c.7-3.3 1.1-6.8 1.1-10.4c0-26.5-21.5-48-48-48h-97.5c-19 0-37.5 5.6-53.3 16.1l-38.5 25.7C176 91.6 160 121.6 160 153.7v111.2c0 29.2 13.3 56.7 36 75l7.4 5.9c26.5 21.2 44.6 51 51.2 84.2l2.3 11.4c5.2 26 30.5 42.9 56.5 37.7M32 384h64c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32v224c0 17.7 14.3 32 32 32"%2F%3E%3C%2Fsvg%3E')`
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.THUMB_SCALE_LABEL_THUMB_DOWN_ACTIVE]: {
    content: `url('data:image/svg+xml,%3Csvg xmlns="http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg" width="18" height="18" viewBox="0 0 512 512"%3E%3Cpath fill="white" d="M313.4 479.1c26-5.2 42.9-30.5 37.7-56.5l-2.3-11.4c-5.3-26.7-15.1-52.1-28.8-75.2h144c26.5 0 48-21.5 48-48c0-18.5-10.5-34.6-25.9-42.6C497 236.6 504 223.1 504 208c0-23.4-16.8-42.9-38.9-47.1c4.4-7.3 6.9-15.8 6.9-24.9c0-21.3-13.9-39.4-33.1-45.6c.7-3.3 1.1-6.8 1.1-10.4c0-26.5-21.5-48-48-48h-97.5c-19 0-37.5 5.6-53.3 16.1l-38.5 25.7C176 91.6 160 121.6 160 153.7v111.2c0 29.2 13.3 56.7 36 75l7.4 5.9c26.5 21.2 44.6 51 51.2 84.2l2.3 11.4c5.2 26 30.5 42.9 56.5 37.7M32 384h64c17.7 0 32-14.3 32-32V128c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32v224c0 17.7 14.3 32 32 32"%2F%3E%3C%2Fsvg%3E')`
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.THUMB_SCALE_RTL]: {
    "margin-right": 0
  }
};
