import { ACTIONS } from '../../actions/email-templates/email-templates-builder.actions';
import { produce } from 'immer';
import { Config } from '../../../../utils/config';

export const initialState = {
  data: undefined,
  id: undefined,
  isFetching: false,
  error: undefined,
};

export const emailTemplatesBuilder = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_EMAIL_TEMPLATE.REQUEST:
    case ACTIONS.POST_EMAIL_TEMPLATE.REQUEST:
    case ACTIONS.UPDATE_EMAIL_TEMPLATE.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };

    case ACTIONS.UPDATE_EMAIL_TEMPLATE.SUCCESS:
      return produce(state, draft => {
        draft.data = action.payload.attributes;
        draft.id = action.payload.id;
        draft.isFetching = false;
        draft.error = undefined;
      });

    case ACTIONS.POST_EMAIL_TEMPLATE.SUCCESS:
      return {
        ...state,
        data: action.payload.data.attributes,
        id: action.payload.data.id,
        links: action.payload.links,
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.GET_EMAIL_TEMPLATE.FAILURE:
    case ACTIONS.POST_EMAIL_TEMPLATE.FAILURE:
    case ACTIONS.UPDATE_EMAIL_TEMPLATE.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };

    case ACTIONS.GET_EMAIL_TEMPLATE.SUCCESS:
      return produce(state, draft => {
        draft.data = action.payload?.data?.attributes;
        draft.id = action.payload?.data?.id;
        draft.isFetching = false;
        draft.error = undefined;
      });

    case ACTIONS.DISCARD_DRAFT_EMAIL_TEMPLATE:
      return produce(state, draft => {
        draft.data = undefined;
      });

    case ACTIONS.UPDATE_DRAFT_EMAIL_TEMPLATE:
      return produce(state, draft => {
        const { key, value } = action.payload;

        if (draft.data) {
          draft.data[key] = value;
        } else {
          draft.data = { [key]: value };
        }
      });

    case ACTIONS.ADD_LOCALES:
      return produce(state, draft => {
        const { lang, locales } = action.payload;

        if (draft?.data?.locales) {
          draft.data.locales[lang] = locales;
        } else {
          draft.data = {
            ...draft.data,
            locales: {
              ...draft.data?.locales,
              [lang]: locales,
            },
          };
        }
      });

    case ACTIONS.UPDATE_TRANSLATION:
      return produce(state, draft => {
        const { lang, key, value } = action.payload;

        draft.data = {
          ...(draft.data || {}),
          locales: {
            ...(draft.data?.locales || {}),
            [lang]: {
              ...(draft.data?.locales?.[lang] || {}),
              ...{
                [key]:
                  typeof value === 'object'
                    ? {
                        ...(draft.data?.locales?.[lang]?.[key] || {}),
                        ...value,
                      }
                    : value,
              },
            },
          },
        };
      });

    case ACTIONS.REMOVE_LOCALES:
      return produce(state, draft => {
        const { lang } = action.payload;
        delete draft.data.locales[lang];
      });

    default: {
      return state;
    }
  }
};
