import React from 'react';
import './form-builder.form.interface.textarea.sass';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';
import { FormBuilderFormHint } from '../common/hint/form-builder-form.hint';

const FormBuilderFormInterfaceTextarea = ({ item, styles, values, analyticForm, t }) => {
  if (!item) return null;

  const elements = item.elements;

  const input = elements.find(e => e.nodeName === 'textarea') || {};
  const label = elements.find(e => e.nodeName === 'label') || {};
  const hint = elements.find(e => e.id?.includes('hint')) || {};

  const onFocus = e => {
    e.target.style.outlineColor = styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS]?.outlineColor;
    analyticForm?.trackInputFocused(input.name);
  };

  const onBlur = () => {
    analyticForm?.trackInputBlurred(input.name);
  };

  return (
    <div
      className={item.class}
      style={{
        ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS],
        ...styles[`div:has(textarea[id=${input.id}])`],
      }}>
      <label style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.LABEL]} htmlFor={label.for}>
        {t(label.nodeValue)}
      </label>
      <textarea
        style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT], ...styles[`#${input.id}`] }}
        onFocus={onFocus}
        onBlur={onBlur}
        name={input.name}
        defaultValue={values?.getAll(input.name)}
        id={input.id}
        placeholder={t(input.placeholder)}
        required={input.required}
        aria-describedby={input['aria-describedby']}
      />
      <FormBuilderFormHint
        item={t(hint?.nodeValue)}
        id={hint?.id}
        style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.HINT]}
      />
    </div>
  );
};

FormBuilderFormInterfaceTextarea.displayName = 'FormBuilderFormInterfaceTextarea';
export default React.memo(FormBuilderFormInterfaceTextarea);
