var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import React, { useState } from "react";
import "./form-dropdown.sass";
import { InputGroup } from "../../../../../../../../components/interface/inputs/input-group/input-group";
import DropdownButton, {
  ButtonType,
  DropdownButtonType
} from "../../../../../../../../components/interface/dropdown/button/dropdown-button";
import { DropdownHeader } from "../../../../../../../../components/interface/dropdown/button/header/dropdown-header";
import DropdownSortList from "../../../../../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list";
import { selectFormSubmissionMediaKeys, selectFormSubmissionNonMediaKeys } from "../../reducers/social-feeds.selector";
import { CheckboxColors, dropdownStyles, dropdownDefaultSelectField } from "../../utils/common/common";
import { FormMediaKeys, OptionsKeys, BackendKeys } from "../../consts";
import InputPrimary from "../../../../../../../../components/interface/inputs/input-primary/input-primary";
import {
  Typography,
  TypographyVariants,
  TypographyColors
} from "../../../../../../../../components/interface/typography/typography";
import { Button } from "../../../../../../../../components/interface/button/button";
import TextareaPrimary from "../../../../../../../../components/interface/inputs/textarea-primary/textarea-primary";
import Checkbox, {
  CheckboxButtonType
} from "../../../../../../../../components/interface/inputs/checkbox-button/checkbox-button";
import { GroupOneName } from "../../../../../../../form-builder/utils/form-builder.types";
import { useDropdownFields } from "../../../../../../../form-builder/hooks/use-dropdown-fields";
const getFormElementsSelector = (state) => {
  var _a, _b, _c, _d, _e, _f;
  return ((_f = (_e = (_d = (_c = (_b = (_a = state.socialFeeds) == null ? void 0 : _a.form) == null ? void 0 : _b.data) == null ? void 0 : _c.attributes) == null ? void 0 : _d.groups) == null ? void 0 : _e[GroupOneName]) == null ? void 0 : _f.elements) || [];
};
const FormDropdown = ({ label, icon, id, placeholder, disabled, formik, advanced, advancedLabel }) => {
  var _a, _b;
  const fields = useDropdownFields(getFormElementsSelector);
  const formSubmissions = Object.entries(fields).map((el) => ({
    key: el[0],
    fields: el[1]
  }));
  const filteredFormSubmissions = id === FormMediaKeys.MEDIA || id === FormMediaKeys.AVATAR ? selectFormSubmissionMediaKeys(formSubmissions) : selectFormSubmissionNonMediaKeys(formSubmissions);
  const formSubmissionList = filteredFormSubmissions.flatMap((el) => el.fields) || [];
  const defaultList = formik.values[id] ? [dropdownDefaultSelectField] : [];
  const list = [...formSubmissionList, ...defaultList];
  const hasDropdownValue = list.some((el) => {
    var _a2;
    return el.value === ((_a2 = formik.values[id]) == null ? void 0 : _a2.value);
  });
  const [isAdvanced, setIsAdvanced] = useState(((_a = formik.values[id]) == null ? void 0 : _a.value) && !hasDropdownValue);
  const onChangeValue = (selectedItem) => {
    if (selectedItem.value === dropdownDefaultSelectField.value) {
      if (id === FormMediaKeys.MEDIA) {
        if (formik.values[FormMediaKeys.POST_MEDIA_ALT_TEXT]) {
          formik.setFieldValue(FormMediaKeys.POST_MEDIA_ALT_TEXT, "");
        }
      }
      formik.setFieldValue([id], "");
    } else {
      formik.setFieldValue([id], selectedItem);
    }
  };
  const renderAdvancedField = () => {
    var _a2, _b2, _c;
    switch (id) {
      case OptionsKeys.POST_TEXT_FIELD:
      case BackendKeys.POST_BODY_FIELD:
        return /* @__PURE__ */ React.createElement(
          TextareaPrimary,
          {
            label,
            placeholder: "Enter template",
            name: "text",
            value: (_a2 = formik.values[id]) == null ? void 0 : _a2.value,
            handleChange: (e) => onChangeValue({ value: e.target.value, name: e.target.value })
          }
        );
      case OptionsKeys.INCENTIVIZED:
        return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Typography, { component: "label", variant: TypographyVariants.LABEL }, label), /* @__PURE__ */ React.createElement("div", { className: "form-dropdown__checkbox" }, /* @__PURE__ */ React.createElement(
          Checkbox,
          {
            color: CheckboxColors.BLUE,
            borderColor: CheckboxColors.GRAY,
            markColor: CheckboxColors.WHITE,
            backgroundColor: CheckboxColors.WHITE,
            id,
            defaultChecked: ((_b2 = formik.values[id]) == null ? void 0 : _b2.value) === true,
            onStatusChange: (_, _event, status) => {
              onChangeValue({ value: status, name: id });
            },
            type: CheckboxButtonType.PRIMARY,
            name: "checked",
            value: id
          }
        ), /* @__PURE__ */ React.createElement(Typography, { component: "p", variant: TypographyVariants.TITLE, color: TypographyColors.SECONDARY }, advancedLabel)));
      default:
        return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Typography, { component: "label", variant: TypographyVariants.LABEL }, label), /* @__PURE__ */ React.createElement(
          InputPrimary,
          {
            defaultValue: (_c = formik.values[id]) == null ? void 0 : _c.value,
            placeholder: "Enter template",
            type: "text",
            handleChange: (e) => onChangeValue({ value: e.target.value, name: e.target.value })
          }
        ));
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: `form-dropdown ${advanced ? "form-dropdown--advanced" : ""}` }, !isAdvanced ? /* @__PURE__ */ React.createElement(InputGroup, { label, inputGroupIcon: icon, error: formik.errors[id], touched: formik.touched[id] }, /* @__PURE__ */ React.createElement(
    DropdownButton,
    {
      styles: dropdownStyles,
      dropdownHeader: (props) => /* @__PURE__ */ React.createElement(DropdownHeader, __spreadValues({}, props)),
      dropdownType: DropdownButtonType.SEARCH_INPUT,
      dropdownList: (props) => /* @__PURE__ */ React.createElement(DropdownSortList, __spreadValues({}, props)),
      currentItem: formik.values[id],
      list,
      placeholder,
      iconRight: "fa fa-caret-down",
      buttonType: ButtonType.BUTTON_BORDER_FULL_WIDTH,
      name: id,
      onChangeValue,
      disabled: disabled && disabled(formik.values)
    }
  )) : /* @__PURE__ */ React.createElement("div", { className: "form-dropdown__advanced" }, renderAdvancedField()), advanced && /* @__PURE__ */ React.createElement(
    Button,
    {
      type: "link",
      onClick: () => {
        setIsAdvanced((s) => !s);
        if (isAdvanced) {
          formik.setFieldValue([id], hasDropdownValue ? formik.values[id] : "");
        }
      }
    },
    isAdvanced || isAdvanced === void 0 && ((_b = formik.values[id]) == null ? void 0 : _b.value) && !hasDropdownValue ? "Simple" : "Advanced"
  ));
};
export default React.memo(FormDropdown);
