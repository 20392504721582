import React from "react";
import { Config } from "../../../../../../utils/config";
import { FormBuilderElementsMappingService } from "../../../../services/builder/form-builder.elements-mapping.service";
const FormBuilderFormInterfaceImage = ({ item, included = [], uploadedImages = [], t }) => {
  var _a, _b;
  const { img: image } = FormBuilderElementsMappingService.getSingleElementHTMLTags(item);
  const imageSrc = t(image == null ? void 0 : image.src) === (image == null ? void 0 : image.src) ? "" : t(image == null ? void 0 : image.src);
  const match = imageSrc.match(Config.regExp.jsonPathID);
  const id = match && match[1] ? match[1] : null;
  const logoSrc = imageSrc ? ((_a = included.find((el) => el.id === id)) == null ? void 0 : _a.attributes.url) || ((_b = uploadedImages == null ? void 0 : uploadedImages.find((el) => (el == null ? void 0 : el.id) === id)) == null ? void 0 : _b.preview) : null;
  const src = logoSrc;
  return /* @__PURE__ */ React.createElement("figure", { className: `${item.class}` }, /* @__PURE__ */ React.createElement("img", { id: image.id, src, alt: t(image == null ? void 0 : image.alt) }));
};
FormBuilderFormInterfaceImage.displayName = "FormBuilderFormInterfaceImage";
export default React.memo(FormBuilderFormInterfaceImage);
