export const SidebarItemTypes = {
  TEXT: 'text',
  SELECT: 'select',
  EXPERIENCE_SELECT: 'experience_select',
  FORM_SELECT: 'form_select',
  TEXTAREA: 'textarea',
  CHECKBOX: 'checkbox',
  TILE: 'tile',
  ACCOUNT: 'account',
  TERMS_CONDITIONS: 'terms_conditions',
  DATE_TEXT: 'date_range',
  LABEL: 'label',
  EMAIL_TEMPLATE_SELECT: 'email_template_select',
};

export const SidebarIcons = {
  USER: 'fas fa-user',
  BOOKMARK: 'fas fa-bookmark',
  RSS: 'fas fa-rss',
  COMPANY: 'fad fa-building',
  MAIL: 'fas fa-at',
  HASHTAG: 'fas fa-hashtag',
  SEARCH: 'far fa-search',
  LIST: 'fas fa-list',
  FLAG: 'fas fa-flag',
  LOCATION: 'fas fa-map-marker-alt',
  GLOBE: 'fas fa-globe',
  CHART: 'fas fa-chart-line',
  ID: 'fas fa-id-card',
  KEY: 'fas fa-key',
  PROFILE: 'fas fa-file-user',
  MEGAPHONE: 'fas fa-bullhorn',
  BRACKETS: 'fas fa-brackets-curly',
  EMAIL: 'fas fa-envelope',
  GRID: 'fas fa-th-large',
};
