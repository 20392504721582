import React from 'react';
import { useTranslation } from 'react-i18next';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';
import { FormBuilderElementsMappingService } from '../../../../services/builder/form-builder.elements-mapping.service';

const FormBuilderFormInterfaceHeading = ({ item, styles }) => {
  if (!item) return null;

  const { t } = useTranslation();

  const { h2, p } = FormBuilderElementsMappingService.getSingleElementHTMLTags(item);

  //FIXME! THAT STYLES INJECTION ARE TEMPORARY SOLUTION, WE SHOULD JUST INCLUDE CSS RETURNED FROM BACKEND AND MAKE SURE THOSE CSS MATCHES OUR STRUCTURE
  return (
    <hgroup className={`${item.class}`} style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.HEADING]}>
      <h2 style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.HEADING_TITLE]}>{t(h2.nodeValue)}</h2>
      {p && <p style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.HEADING_CAPTION]}>{t(p.nodeValue)}</p>}
    </hgroup>
  );
};

FormBuilderFormInterfaceHeading.displayName = 'FormBuilderFormInterfaceHeading';
export default React.memo(FormBuilderFormInterfaceHeading);
