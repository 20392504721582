export const CommunityContentTypes = {
  TIKTOK: 'tiktok',
  LINKED_IN: 'linkedin',
  FACEBOOK: 'facebook',
  FACEBOOK_PAGE: 'facebook_page',
  TWITTER: 'twitter',
  PINTEREST: 'pinterest',
  INSTAGRAM: 'instagram',
  BLOG: 'blog',
  YOUTUBE: 'youtube',
  SHARE_LINK: 'share_link',
  UPLOAD_PHOTO: 'upload_photo',
  UPLOAD_PHOTO_TWITTER: 'upload_photo_twitter',
  UPLOAD_PHOTO_FACEBOOK: 'upload_photo_facebook',
  UPLOAD_PHOTO_FB_PAGE: 'upload_photo_fb_page',
  BRAND_CONNECT: 'brand_connect',
  CUSTOM_UPLOAD: 'custom_upload',
  BAZAARVOICE: 'bazaarvoice',
  POWER_REVIEWS: 'power_reviews',
  PRODUCT_REVIEW: 'form',
  THREADS: 'threads',
  UPLOAD_PHOTO_THREADS: 'upload_photo_threads',
  RETAIL_REVIEW: 'retail_review',
  RATING: 'rating',
  EMAIL_GROUP_INVITE: 'email_group_invite',
  FACE2FACE: 'face2face',
  REDDIT: 'reddit',
  CUSTOM_FORM: 'custom_form',
};
