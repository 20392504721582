import React, { useEffect, useRef } from 'react';
import './dropdown-search-input.sass';

export const DropdownSearchInput = ({ searchTerm = '', onChange, onFocus, disabled }) => {
  const ref = useRef(null);
  useEffect(() => {
    if (!disabled && ref.current) {
      ref.current.focus();
    }
  }, [disabled]);

  return (
    <div className='dropdown-search-input'>
      <input
        ref={ref}
        placeholder='Search...'
        value={searchTerm}
        onChange={onChange}
        onFocus={onFocus}
        disabled={disabled}
        data-testid='dropdown-search-input'
      />
    </div>
  );
};
