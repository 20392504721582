export const fileUpload = {
  FILE_UPLOAD_FILE: ".input-file output li",
  FILE_UPLOAD_INPUT: ".input-file input",
  FILE_UPLOAD_INPUT_BEFORE: ".input-file input:before",
  FILE_UPLOAD_OUTPUT_LABEL: ".input-file output label",
  FILE_UPLOAD_UL: ".input-file ul",
  FILE_UPLOAD_LI: ".input-file li",
  FILE_UPLOAD_FILE_NAME: ".input-file output li div:first-of-type",
  FILE_UPLOAD_ERROR: ".input-file output li div:last-of-type",
  FILE_UPLOAD_BUTTON: ".input-file output button",
  FILE_UPLOAD_PROGRESS: ".input-file output progress"
};
