import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const starRating = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.STAR_RATING]: {
    display: "flex"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.STAR_RATING_INPUT]: {
    height: "48px",
    width: "48px",
    margin: "0 10px 0 0",
    border: 0,
    "border-radius": "6px",
    "background-color": "#f5a623",
    padding: "13px",
    content: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z'/%3E%3C/svg%3E")`
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.STAR_RATING_LABEL]: {
    border: "0",
    "clip-path": "inset(50%)",
    height: "1px",
    margin: "-1px",
    overflow: "hidden",
    padding: "0",
    position: "absolute",
    width: "1px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.STAR_RATING_INPUT_ACTIVE]: {
    "background-color": "#aab7c4"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.STAR_RATING_RTL]: {
    "margin-right": 0
  }
};
