var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { ELEMENTS_CLASSES } from "../../form-builder.types";
import { header } from "./elements/header";
import { heading } from "./elements/heading";
import { submitButton } from "./elements/submit-button";
import { successMessage } from "./elements/success-message";
import { image } from "./elements/image";
import { singleChoice } from "./elements/single-choice";
import { common } from "./common/common";
import { spacer } from "./elements/spacer";
import { smileyScale } from "./elements/smiley-scale";
import { fileUpload } from "./elements/file-upload";
import { thumbScale } from "./elements/thumb-scale";
import { numberScale } from "./elements/number-scale";
import { singleMatrix } from "./elements/single-matrix";
import { multipleMatrix } from "./elements/multiple-matrix";
import { multiLineText } from "./elements/multi-line-text";
import { starRating } from "./elements/star-rating";
import { line } from "./elements/line";
import { fullName } from "./elements/full-name";
export const FORM_BUILDER_STYLE_ELEMENT_CLASSES = __spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues({}, common), header), heading), submitButton), successMessage), image), singleChoice), spacer), smileyScale), fileUpload), thumbScale), numberScale), singleMatrix), multipleMatrix), multiLineText), starRating), line), fullName);
export const FORM_BUILDER_INPUT_TYPE_TO_CLASSES = {
  // common is an exception to store styles which are shared by many elements or meant for specific style cases
  common: [...Object.values(common)],
  [ELEMENTS_CLASSES.HEADER]: [...Object.values(header)],
  [ELEMENTS_CLASSES.HEADING]: [...Object.values(heading)],
  [ELEMENTS_CLASSES.SPACER]: [...Object.values(spacer)],
  [ELEMENTS_CLASSES.SINGLE_CHOICE]: [...Object.values(singleChoice)],
  [ELEMENTS_CLASSES.MULTI_LINE_TEXT]: [...Object.values(multiLineText)],
  [ELEMENTS_CLASSES.FULL_NAME]: [...Object.values(fullName)],
  [ELEMENTS_CLASSES.FILE_UPLOAD]: [...Object.values(fileUpload)],
  [ELEMENTS_CLASSES.STAR_RATING]: [...Object.values(starRating)],
  [ELEMENTS_CLASSES.IMAGE]: [...Object.values(image)],
  [ELEMENTS_CLASSES.SMILEY_SCALE]: [...Object.values(smileyScale)],
  [ELEMENTS_CLASSES.THUMB_SCALE]: [...Object.values(thumbScale)],
  [ELEMENTS_CLASSES.NUMBER_SCALE]: [...Object.values(numberScale)],
  [ELEMENTS_CLASSES.SINGLE_MATRIX]: [...Object.values(singleMatrix)],
  [ELEMENTS_CLASSES.MULTIPLE_MATRIX]: [...Object.values(multipleMatrix)]
};
