var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { formatCSSForPreview, objectToCss } from "../../helpers/helpers";
import cssbeautify from "cssbeautify";
import { FORM_BUILDER_BREAKPOINTS } from "../../utils/styles/breakpoints";
const getFormattedStyles = (styles, isPreview) => {
  const stylesCopy = __spreadValues({}, styles);
  const mediaStyles = {};
  Object.values(FORM_BUILDER_BREAKPOINTS).forEach((selector) => {
    mediaStyles[selector] = styles[selector];
    delete stylesCopy[selector];
  });
  const mediaCSSString = objectToCss(formatCSSForPreview(mediaStyles, isPreview));
  const cssString = objectToCss(formatCSSForPreview(stylesCopy, isPreview));
  const formattedMediaCss = cssbeautify(mediaCSSString, {
    indent: "  ",
    openbrace: "end-of-line",
    autosemicolon: true
  });
  const formattedRestOfCss = cssbeautify(cssString, {
    indent: "  ",
    openbrace: "end-of-line",
    autosemicolon: true
  });
  return { formattedMediaCss, formattedRestOfCss };
};
export const FormBuilderStylesService = {
  getFormattedStyles
};
