import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from './form-builder.types';
import { roundCornerRadiusSize } from '../components/sidebar/styles/styles.options';

export const GroupOneName = 'group-1';

export const defaultFormStyles = {
  input: {
    'border-style': 'solid',
    'border-radius': roundCornerRadiusSize,
    'border-top-width': '1px',
    'border-left-width': '1px',
    'border-right-width': '1px',
    'border-bottom-width': '1px',
    'border-color': '#767676',
    'font-size': '16px',
    'font-family': 'inherit',
    'background-color': '#ffffff',
    color: '#767676',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS]: {
    'outline-color': '#3B99FC',
  },
  label: {
    'font-size': '16px',
    color: '#2C2D30',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS]: {
    'margin-bottom': '40px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.LINKS]: {
    color: '#3B99FC',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SELECT]: {
    width: '100%',
    height: '42px',
    'border-radius': '6px',
    outline: 'revert',
    appearance: 'none',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    'border-color': '#DFDFDF',
    'border-style': 'solid',
    'border-width': '1px',
    color: '#6D7278',
    'font-size': '15px',
    'background-repeat': 'no-repeat',
    'background-image':
      "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' width='14' height='14' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E\")",
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.FORM]: {
    'font-size': '16px',
    'background-color': '#ffffff',
    transition: '0.3s ease background-color',
    'border-radius': '12px',
    height: 'max-content',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.HEADER_TITLE]: {
    color: '#000000',
    'font-size': '32px',
    'font-weight': '700',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SPACER]: {
    height: '42px',
    width: '100%',
    'margin-bottom': '0px',
    transition: 'height 0.5s',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.HEADER_CAPTION]: {
    color: '#727272',
    'font-size': '16px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.HEADING]: {
    width: '100%',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.HEADING_TITLE]: {
    color: '#000000',
    'font-size': '20px',
    'font-weight': '700',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.HEADING_CAPTION]: {
    color: '#727272',
    'font-size': '16px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SUBMIT_BUTTON]: {
    'background-color': '#0472EF',
    'box-shadow': 'none',
    width: '100%',
    'justify-self': 'left',
    'font-size': '16px',
    'padding-top': '12px',
    'padding-right': '12px',
    'padding-bottom': '12px',
    'padding-left': '12px',
    color: '#ffffff',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SUCCESS_MESSAGE_TITLE]: {
    'font-size': '32px',
    'line-height': '32px',
    'font-weight': '700',
    color: '#000000',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SUCCESS_MESSAGE_CAPTION]: {
    'font-size': '16px',
    'line-height': '16px',
    color: '#000000',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SUCCESS_MESSAGE_BUTTON]: {
    'font-size': '14px',
    color: '#ffffff',
    'background-color': '#3B99FC',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.IMAGE]: {
    'text-align': 'center',
    'padding-top': '0',
    'padding-bottom': '0',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.IMAGE_IMG]: {
    'border-radius': 0,
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.NO_IMAGE]: {
    display: 'flex',
    'align-items': 'center',
    'justify-content': 'center',
    border: '1px solid #F6F7F8',
    'padding-top': '12px',
    'padding-bottom': '12px',
    'padding-right': '12px',
    'padding-left': '12px',
    height: '150px',
    width: '100%',
    'border-radius': '6px',
    'background-image':
      'linear-gradient(45deg, #F6F7F8 25%, transparent 25%), linear-gradient(-45deg, #F6F7F8 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #F6F7F8 75%), linear-gradient(-45deg, transparent 75%, #F6F7F8 75%)',
    'background-size': '20px 20px',
    'background-position': '0 0, 0 10px, 10px -10px, -10px 0px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_CHOICE_FIELDSET]: {
    display: 'grid',
    padding: 0,
    border: 0,
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_CHOICE_LEGEND]: {
    'margin-bottom': '16px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_CHOICE_INPUT]: {
    position: 'relative',
    cursor: 'pointer',
    width: '16px',
    height: '16px',
    'border-radius': '50%',
    padding: 0,
    appearance: 'none',
    margin: '2px 8px 16px 0',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_CHOICE_INPUT_CHECKED]: {
    border: '3px solid #3B99FC',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_CHOICE_LABEL]: {
    clip: 'initial',
    overflow: 'initial',
    position: 'static',
    margin: '4px 0 16px 0',
    width: 'initial',
    height: 'initial',
    'word-break': 'break-word',
    'padding-right': '4px',
    'line-height': '14px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_CHOICE_FIELDSET]: {
    display: 'grid',
    padding: 0,
    border: 0,
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_CHOICE_LEGEND]: {
    'margin-bottom': '16px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_CHOICE_INPUT]: {
    position: 'relative',
    cursor: 'pointer',
    width: '18px',
    height: '18px',
    padding: 0,
    appearance: 'none',
    margin: '2px 8px 16px 0',
    border: '1px solid #b6b8bb',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_CHOICE_LABEL]: {
    clip: 'initial',
    overflow: 'initial',
    position: 'static',
    margin: '5px 0 16px 0',
    width: 'initial',
    height: 'initial',
    'word-break': 'break-word',
    'padding-right': '4px',
    'line-height': '14px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SMILEY_SCALE_FIELDSET]: {
    display: 'flex',
    border: 'none',
    padding: 0,
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SMILEY_SCALE_INPUT]: {
    width: '42px',
    height: '42px',
    cursor: 'pointer',
    margin: '10px 16px 0 0',
    padding: 0,
    'border-radius': '50%',
    appearance: 'none',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SMILEY_SCALE_LABEL]: {
    border: '0',
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: '0',
    position: 'absolute',
    width: '1px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.FILE_UPLOAD_FILE]: {
    display: 'grid',
    gap: '0 10px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.FILE_UPLOAD_FILE_NAME]: {
    'grid-area': 'name',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.FILE_UPLOAD_PROGRESS]: {
    width: '100%',
    'grid-area': 'progress',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.FILE_UPLOAD_BUTTON]: {
    outline: '0',
    border: '0',
    color: 'transparent',
    'text-indent': '-9999px',
    width: '20px',
    height: '20px',
    'grid-area': 'action',
    background:
      "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath d='M13 3A1.0001 1.0001 0 0 0 11.986328 4L6 4A1.0001 1.0001 0 1 0 6 6L24 6A1.0001 1.0001 0 1 0 24 4L18.013672 4A1.0001 1.0001 0 0 0 17 3L13 3zM6 8L6 24C6 25.105 6.895 26 8 26L22 26C23.105 26 24 25.105 24 24L24 8L6 8z' fill='%23000000'/%3E%3C/svg%3E\")",
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.FILE_UPLOAD_ERROR]: {
    'grid-area': 'progress',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.STAR_RATING_FIELDSET]: {
    display: 'flex',
    border: 'none',
    padding: 0,
    position: 'relative',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.STAR_RATING_INPUT]: {
    height: '48px',
    width: '48px',
    margin: '10px 10px 0 0',
    'border-radius': '6px',
    appearance: 'none',
    padding: '13px',
    cursor: 'pointer',
    content:
      "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath d='M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z'/%3E%3C/svg%3E\")",
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.STAR_RATING_LABEL]: {
    border: '0',
    clip: 'rect(0 0 0 0)',
    height: '1px',
    margin: '-1px',
    overflow: 'hidden',
    padding: '0',
    position: 'absolute',
    width: '1px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.CONSENT]: {
    display: 'flex',
    width: '100%',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.CONSENT_INPUT]: {
    appearance: 'auto',
    'min-width': '16px',
    'min-height': '16px',
    'max-width': '16px',
    'max-height': '16px',
    margin: 0,
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.CONSENT_LABEL]: {
    cursor: 'pointer',
    margin: '4px 0 0 12px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.CONSENT_A]: {
    color: '#0472EF',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.COMMUNITY_CONSENT]: {
    display: 'flex',
    width: '100%',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.COMMUNITY_CONSENT_INPUT]: {
    appearance: 'auto',
    'min-width': '16px',
    'min-height': '16px',
    'max-width': '16px',
    'max-height': '16px',
    margin: 0,
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.COMMUNITY_CONSENT_LABEL]: {
    cursor: 'pointer',
    margin: '4px 0 0 12px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.THUMB_SCALE_FIELDSET]: {
    border: 'none',
    padding: 0,
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.THUMB_SCALE_LABEL]: {
    position: 'relative',
    display: 'inline-block',
    border: '1px solid #DFDFDF',
    padding: '10px 10px 10px 32px',
    'border-radius': '6px',
    margin: '10px 10px 10px 0',
    clip: 'initial',
    overflow: 'initial',
    'word-break': 'break-word',
    'line-height': '14px',
    width: 'initial',
    height: 'initial',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.THUMB_SCALE_INPUT]: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    'border-radius': '6px',
    cursor: 'pointer',
    appearance: 'none',
    margin: 0,
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.ACCEPTANCE]: {
    display: 'flex',
    width: '100%',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.ACCEPTANCE_INPUT]: {
    appearance: 'auto',
    'min-width': '16px',
    'min-height': '16px',
    'max-width': '16px',
    'max-height': '16px',
    margin: 0,
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.ACCEPTANCE_LABEL]: {
    cursor: 'pointer',
    margin: '4px 0 0 12px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.DATE_INPUT]: {
    padding: '0 16px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.BIRTHDAY_INPUT]: {
    padding: '0 16px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.NUMBER_SCALE]: {
    display: 'grid',
    'grid-column-gap': '10px',
    'grid-row-gap': '10px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.NUMBER_SCALE_FIELDSET]: {
    margin: 0,
    padding: 0,
    border: 'none',
    display: 'contents',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.NUMBER_SCALE_LEGEND]: {
    'grid-column': 'auto/span 11',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.NUMBER_SCALE_INPUT]: {
    display: 'flex',
    'justify-content': 'center',
    'align-items': 'center',
    appearance: 'none',
    border: '1px solid #DFDFDF',
    padding: '10px',
    'border-radius': '6px',
    cursor: 'pointer',
    margin: 0,
    'word-break': 'initial',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.NUMBER_SCALE_LABEL]: {
    display: 'none',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.NUMBER_SCALE_DIV]: {
    'word-break': 'break-word',
    'grid-row': 3,
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.HINT]: {
    display: 'inline-block',
    'font-weight': 400,
    'font-family': 'inherit',
    color: '#6D7278',
    margin: '12px 0',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.PARAGRAPH]: {
    width: '100%',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX]: {
    padding: 0,
    border: 0,
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX_LEGEND]: {
    'margin-bottom': '16px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX_TABLE]: {
    width: '100%',
    'table-layout': 'fixed',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX_TBODY_TH]: {
    'font-weight': 'initial',
    'text-align': 'initial',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX_TBODY_TD]: {
    'text-align': 'center',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX_THEAD_TH]: {
    'font-weight': 'initial',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX_INPUT]: {
    appearance: 'none',
    width: '16px',
    'min-width': '16px',
    height: '16px',
    margin: 0,
    'border-radius': '50%',
    padding: 0,
    cursor: 'pointer',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX]: {
    padding: 0,
    border: 0,
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX_LEGEND]: {
    'margin-bottom': '16px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX_TABLE]: {
    width: '100%',
    'table-layout': 'fixed',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX_TBODY_TH]: {
    'font-weight': 'initial',
    'text-align': 'initial',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX_TBODY_TD]: {
    'text-align': 'center',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX_THEAD_TH]: {
    'font-weight': 'initial',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX_INPUT]: {
    position: 'relative',
    cursor: 'pointer',
    width: '18px',
    height: '18px',
    padding: 0,
    appearance: 'none',
    border: '1px solid #b6b8bb',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.ADDRESS]: {
    padding: 0,
    border: 0,
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.ADDRESS_LABEL]: {
    color: '#6D7278',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.ADDRESS_LEGEND]: {
    'margin-bottom': '16px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.ADDRESS_DIV]: {
    width: '100%',
    'margin-bottom': '24px',
  },
  [FORM_BUILDER_STYLE_ELEMENT_TYPE.ADDRESS_SELECT]: {
    padding: '8px 36px 8px 8px',
    'background-position': 'calc(100% - 8px)',
  },
};
