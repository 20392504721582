import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../../elements-classes/form-builder.styles.elements-classes";
export const choice = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.CHOICE_FIELDSET]: {
    display: "grid",
    "grid-template-columns": "min-content 1fr",
    padding: 0,
    border: 0
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.CHOICE_TWO_COLUMN]: {
    "grid-template-columns": "repeat(2, min-content 1fr) !important"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.CHOICE_THREE_COLUMN]: {
    "grid-template-columns": "repeat(3, min-content 1fr) !important"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.CHOICE_INPUT]: {
    margin: "2px 8px 16px 0",
    position: "relative"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.CHOICE_INPUT_RTL]: {
    margin: "2px 0 16px 8px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.CHOICE_LABEL]: {
    margin: "4px 0 16px 0",
    padding: "0 4px 0 0"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.CHOICE_LABEL_RTL]: {
    padding: "0 0 0 4px"
  }
};
