import React from 'react';
import './form-builder.form.interface.consent.sass';
import { useTranslation } from 'react-i18next';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';
import { FormBuilderElementsMappingService } from '../../../../services/builder/form-builder.elements-mapping.service';

const FormBuilderFormInterfaceConsent = ({ item, styles, analyticForm }) => {
  if (!item) return null;

  const { t } = useTranslation();

  const { input: checkbox, a, label, ['#text']: text } = FormBuilderElementsMappingService.getSingleElementHTMLTags(
    item
  );

  const onFocus = e => {
    e.target.style.outlineColor = styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS]?.outlineColor;
    analyticForm?.trackInputFocused(checkbox.name);
  };

  const onBlur = () => {
    analyticForm?.trackInputBlurred(checkbox.name);
  };

  return (
    <div
      className={`${item.class}`}
      style={{
        ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS],
        ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.CONSENT],
      }}>
      <input
        style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.CONSENT_INPUT] }}
        type={checkbox?.type}
        name={checkbox?.name}
        onFocus={onFocus}
        onBlur={onBlur}
        id={checkbox?.id}
        required={checkbox?.required}
        value={checkbox.value ? checkbox.value : ''}
      />

      <label htmlFor={label.for} style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.CONSENT_LABEL] }}>
        {text && `${t(text?.nodeValue)} `}
        {a && (
          <a
            href={a?.href}
            target='_blank'
            rel='noopener noreferrer'
            style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.CONSENT_A]}>
            {t(a?.nodeValue)}
          </a>
        )}
      </label>
    </div>
  );
};

FormBuilderFormInterfaceConsent.displayName = 'FormBuilderFormInterfaceConsent';
export default React.memo(FormBuilderFormInterfaceConsent);
