import * as Yup from "yup";
export const validationSchemaPublicPosting = () => Yup.object().shape(
  {
    form: Yup.object().required("Form is required."),
    text: Yup.object().when(["media", "alternative_text"], {
      is: (media, alternative_text) => (!media || media.length === 0) && (!alternative_text || alternative_text.length === 0),
      then: Yup.object().required("Text is required."),
      otherwise: Yup.object()
    }),
    media: Yup.object().when(["text", "alternative_text"], {
      is: (text, alternative_text) => !text || text.length === 0 || alternative_text && alternative_text.length > 0,
      then: Yup.object().required("Media is required."),
      otherwise: Yup.object()
    })
  },
  [["text", "media"]]
);
export const validationSchemaRatingsAndReviews = () => Yup.object().shape(
  {
    email_from: Yup.string().notRequired().test("is-email", "Invalid Email", (value) => {
      if (value) {
        return Yup.string().email().isValidSync(value);
      }
      return true;
    }),
    form: Yup.object().required("Form is required."),
    text: Yup.object().when(["media", "alternative_text"], {
      is: (media, alternative_text) => (!media || media.length === 0) && (!alternative_text || alternative_text.length === 0),
      then: Yup.object().required("Text is required."),
      otherwise: Yup.object()
    }),
    media: Yup.object().when(["text", "alternative_text"], {
      is: (text, alternative_text) => !text || text.length === 0 || alternative_text && alternative_text.length > 0,
      then: Yup.object().required("Media is required."),
      otherwise: Yup.object()
    })
  },
  [["text", "media"]]
);
export const hasSelectedForm = (socialFeed, form) => {
  var _a, _b;
  return ((_b = (_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.options) == null ? void 0 : _b.form_id) && (form == null ? void 0 : form.id) && Number(socialFeed.attributes.options.form_id) === Number(form.id);
};
export const initializeFormId = (socialFeed, form) => {
  var _a;
  if (hasSelectedForm(socialFeed, form)) {
    const formId = socialFeed.attributes.options.form_id;
    return formId ? { value: formId, name: form.attributes.name } : "";
  } else {
    return form ? { value: form == null ? void 0 : form.id, name: (_a = form == null ? void 0 : form.attributes) == null ? void 0 : _a.name } : "";
  }
};
export const initializeEmailTemplateValue = (socialFeed, form) => {
  var _a, _b;
  if (hasSelectedForm(socialFeed, form)) {
    const emailTemplateId = (_b = (_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.options) == null ? void 0 : _b["email_template_id"];
    return emailTemplateId ? { value: emailTemplateId } : "";
  } else {
    return "";
  }
};
export const initializeAuthorNameValue = (socialFeed, form) => {
  var _a, _b;
  if (hasSelectedForm(socialFeed, form)) {
    const authorName = (_b = (_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.options) == null ? void 0 : _b["author.name"];
    return authorName ? { value: authorName, name: authorName } : "";
  } else {
    return "";
  }
};
export const initializeAuthorEmailValue = (socialFeed, form) => {
  var _a, _b;
  if (hasSelectedForm(socialFeed, form)) {
    const authorEmail = (_b = (_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.options) == null ? void 0 : _b["author.email"];
    return authorEmail ? { value: authorEmail, name: authorEmail } : "";
  } else {
    return "";
  }
};
export const initializeAuthorAvatarValue = (socialFeed, form) => {
  var _a, _b;
  if (hasSelectedForm(socialFeed, form)) {
    const authorAvatar = (_b = (_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.options) == null ? void 0 : _b["author.avatar"];
    return authorAvatar ? { value: authorAvatar, name: authorAvatar } : "";
  } else {
    return "";
  }
};
export const initializeMediaValue = (socialFeed, form) => {
  var _a, _b;
  if (hasSelectedForm(socialFeed, form)) {
    const media = (_b = (_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.options) == null ? void 0 : _b.media;
    return media ? { value: media, name: media } : "";
  } else {
    return "";
  }
};
export const initializePostTextValue = (socialFeed, form) => {
  var _a, _b;
  if (hasSelectedForm(socialFeed, form)) {
    const text = (_b = (_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.options) == null ? void 0 : _b.text;
    return text ? { value: text, name: text } : "";
  } else {
    return "";
  }
};
export const initializePostIncentivizedValue = (socialFeed, form) => {
  var _a, _b;
  if (hasSelectedForm(socialFeed, form)) {
    const incentivized = (_b = (_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.options) == null ? void 0 : _b.incentivized;
    const value = incentivized === "true" || incentivized;
    return incentivized ? { value, name: incentivized } : "";
  } else {
    return "";
  }
};
export const initializeTagsValue = (socialFeed, form) => {
  var _a, _b;
  if (hasSelectedForm(socialFeed, form)) {
    const tags = (_b = (_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.options) == null ? void 0 : _b.tags;
    return tags ? { value: tags, name: tags } : "";
  } else {
    return "";
  }
};
export const initializePostAlternativeTextValue = (socialFeed, form) => {
  var _a, _b;
  if (hasSelectedForm(socialFeed, form)) {
    const altText = (_b = (_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.options) == null ? void 0 : _b.alternative_text;
    return altText ? { value: altText, name: altText } : "";
  } else {
    return "";
  }
};
export const initializeRatingValue = (socialFeed, form) => {
  var _a, _b;
  if (hasSelectedForm(socialFeed, form)) {
    const rating = (_b = (_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.options) == null ? void 0 : _b.rating;
    return rating ? { value: rating, name: rating } : "";
  } else {
    return "";
  }
};
export const initializeTitleValue = (socialFeed, form) => {
  var _a, _b;
  if (hasSelectedForm(socialFeed, form)) {
    const title = (_b = (_a = socialFeed == null ? void 0 : socialFeed.attributes) == null ? void 0 : _a.options) == null ? void 0 : _b.title;
    return title ? { value: title, name: title } : "";
  } else {
    return "";
  }
};
