import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const image = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.IMAGE]: {
    "text-align": "center",
    "padding-top": "0",
    "padding-bottom": "0",
    "min-height": "100px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.IMAGE_IMG]: {
    "border-radius": 0,
    "max-width": "100%",
    "max-height": "100%",
    "object-fit": "initial"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.NO_IMAGE]: {
    display: "flex",
    "align-items": "center",
    "justify-content": "center",
    border: "1px solid #F6F7F8",
    "padding-top": "12px",
    "padding-bottom": "12px",
    "padding-right": "12px",
    "padding-left": "12px",
    height: "150px",
    "border-radius": "6px",
    "background-image": "linear-gradient(45deg, #F6F7F8 25%, transparent 25%), linear-gradient(-45deg, #F6F7F8 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #F6F7F8 75%), linear-gradient(-45deg, transparent 75%, #F6F7F8 75%)",
    "background-size": "20px 20px",
    "background-position": "0 0, 0 10px, 10px -10px, -10px 0px"
  }
};
