import { roundCornerRadiusSize } from "../../../../components/sidebar/styles/utils/form-builder.styles.options";
import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const common = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FORM]: {
    "font-size": "16px",
    "background-color": "#ffffff",
    "border-radius": "12px",
    height: "max-content",
    padding: "16px",
    width: "initial"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.INPUT_TEXT_RTL]: {
    padding: "0 16px 0 0"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.INPUT_CHOICE_RTL]: {
    margin: "2px 0 16px 8px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.INPUT_FOCUS]: {
    "outline-color": "#3B99FC"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.INPUT_CHECKBOX]: {
    "min-width": "16px",
    "min-height": "16px",
    "max-width": "16px",
    "max-height": "16px",
    margin: 0,
    padding: 0,
    border: "1px solid #b6b8bb",
    "border-radius": 0
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FIELDSET]: {
    border: "none"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.INPUT_CHECKBOX_CHECKED]: {
    content: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%233B99FC' width='24' height='24' viewBox='0 0 24 24'><rect width='100%' height='100%' fill='%233B99FC'/><path fill='white' stroke='white' stroke-width='2' d='M6.293 13.293l2.828 2.828 7.778-7.778 1.414 1.414-9.192 9.192-4.95-4.95 1.122-1.606z'/%3E%3C/svg%3E")`
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.INPUT_CONSENT]: {
    "border-radius": "4px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.LABEL_REQUIRED]: {
    content: "'*'"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.INPUT_CONSENT_REQUIRED]: {
    content: "'*'"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.REQUIRED]: {
    content: "'*'"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.INPUT_CLASS]: {
    padding: "10px 0",
    width: "100%",
    margin: "0 10px 40px",
    position: "relative"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.INPUT_EDIT]: {
    "border-style": "solid",
    "border-radius": roundCornerRadiusSize,
    "border-top-width": "1px",
    "border-left-width": "1px",
    "border-right-width": "1px",
    "border-bottom-width": "1px",
    "border-color": "#767676",
    "font-size": "16px",
    "font-family": "inherit",
    "background-color": "#ffffff",
    color: "#767676",
    padding: "0 0 0 16px",
    width: "100%",
    height: "42px",
    appearance: "none",
    "-webkit-appearance": "none",
    "-moz-appearance": "none"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SELECT]: {
    "background-repeat": "no-repeat",
    "background-image": `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' width='14' height='14' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E")`,
    padding: "8px 36px 8px 8px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.LINKS]: {
    color: "#3B99FC"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FORM_ELEMENTS]: {
    margin: "0 10px 40px",
    width: "100%"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.LINE]: {
    margin: "0 10px 40px",
    "border-color": "#E8EDF4",
    width: "100%"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.LABEL]: {
    display: "block",
    "font-size": "16px",
    color: "#2C2D30",
    padding: 0,
    margin: "0 0 10px 0"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.LABEL_HIDDEN]: {
    border: "0",
    "clip-path": "inset(50%)",
    height: "1px",
    margin: "-1px",
    overflow: "hidden",
    padding: "0",
    position: "absolute",
    width: "1px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FORM_RTL]: {
    "text-align": "right"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FORM_DIV]: {
    display: "flex",
    "flex-wrap": "wrap"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.ALL]: {
    "font-family": "inherit",
    "box-sizing": "border-box",
    "word-break": "normal",
    "overflow-wrap": "anywhere"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.CHOICE_TWO_COLUMN]: {
    "grid-template-columns": "repeat(2, min-content 1fr) !important"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.CHOICE_THREE_COLUMN]: {
    "grid-template-columns": "repeat(3, min-content 1fr) !important"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.HINT]: {
    display: "inline-block",
    "font-weight": 400,
    "font-family": "inherit",
    color: "#6D7278",
    margin: "12px 0"
  }
};
