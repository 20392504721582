const mediaSelectorPrefix = "@media";
export const hasPhrase = (element, phrase, exceptionalSlug) => {
  const extractedPhrase = getSlugFromId(element == null ? void 0 : element.id);
  if (extractedPhrase == null ? void 0 : extractedPhrase.includes(exceptionalSlug)) {
    const elementIndex = extractedPhrase.split("_").pop();
    const extractedNestedPhrase = `${exceptionalSlug}_${elementIndex}`;
    return extractedPhrase === extractedNestedPhrase;
  } else {
    return phrase === extractedPhrase;
  }
};
export const getCleanFormElementId = (formElementId) => {
  const regex = /^([a-zA-Z0-9_]+)(?:\[[a-zA-Z0-9_]+\])?(?:\[\])?$/;
  const match = formElementId.match(regex);
  const cleanSlugIndex = 1;
  return (match == null ? void 0 : match[cleanSlugIndex]) || "";
};
export const getSlugFromId = (id) => {
  const parts = id.split("_");
  parts.pop();
  return parts.join("_");
};
export const formatCSSForPreview = (styles, isPreview) => {
  if (!isPreview) return styles;
  const _addPrefixToComplexSelector = (selector) => {
    return selector.split(", ").map((className) => {
      return `.div-form ${className}`;
    }).join(", ");
  };
  const newStyles = {};
  for (const className in styles) {
    if (className.includes("dir='rtl'") || className.includes(".div-form") || className.includes("host") && !isPreview) {
      newStyles[className] = styles[className];
    } else if (className.includes("host") && isPreview) {
      newStyles[":root"] = styles[className];
    } else if (className.includes(mediaSelectorPrefix)) {
      const nestedStyles = styles[className];
      const formattedStyles = {};
      for (const selector in nestedStyles) {
        if (selector.includes(".div-form")) {
          formattedStyles[selector] = nestedStyles[selector];
        } else {
          if (selector.includes(",")) {
            const formattedClassName = _addPrefixToComplexSelector(selector);
            formattedStyles[formattedClassName] = nestedStyles[selector];
          } else {
            formattedStyles[`.div-form ${selector}`] = nestedStyles[selector];
          }
        }
      }
      newStyles[className] = formattedStyles;
    } else if (className.includes("[id=")) {
      newStyles[`.div-form div ${className}`] = styles[className];
    } else if (className.includes(",")) {
      const formattedClassName = _addPrefixToComplexSelector(className);
      newStyles[formattedClassName] = styles[className];
    } else {
      newStyles[`.div-form ${className}`] = styles[className];
    }
  }
  return newStyles;
};
export const kebabToCamelCaseProperties = (obj) => {
  const _camelCaseKey = (key) => {
    if (key.includes("--")) {
      return key;
    }
    return key.replace(/-([a-z])/g, (match, p1) => p1.toUpperCase());
  };
  const _setFormattedProperty = (newObj2, selector, selectorStyles) => {
    newObj2[selector] = {};
    for (let prop in selectorStyles) {
      newObj2[selector][_camelCaseKey(prop)] = selectorStyles[prop];
    }
  };
  let newObj = {};
  for (let selector in obj) {
    const selectorStyles = obj[selector];
    if (selector.includes(mediaSelectorPrefix)) {
      newObj[selector] = {};
      for (let nestedSelector in selectorStyles) {
        _setFormattedProperty(newObj[selector], nestedSelector, selectorStyles[nestedSelector]);
      }
    } else {
      _setFormattedProperty(newObj, selector, selectorStyles);
    }
  }
  return newObj;
};
export function objectToCss(styles) {
  let cssString = "";
  const _addProperty = (numberOfSpaces = 0, selectorStyles) => {
    var _a;
    let spaces = "";
    for (let i = 0; i < numberOfSpaces; i++) {
      spaces += " ";
    }
    for (const property in selectorStyles) {
      const value = (_a = selectorStyles[property]) == null ? void 0 : _a.toString();
      if (value) {
        const kebabProperty = property.replace(/([a-z0-9])([A-Z])/g, "$1-$2").toLowerCase();
        cssString += `${spaces}${kebabProperty}: ${value};
`;
      }
    }
  };
  for (const selector in styles) {
    cssString += `${selector} {
`;
    const selectorStyles = styles[selector];
    if (selector.includes(mediaSelectorPrefix)) {
      for (const nestedSelector in selectorStyles) {
        cssString += `  ${nestedSelector} {
`;
        const numberOfSpacesForMediaProperty = 4;
        _addProperty(numberOfSpacesForMediaProperty, selectorStyles[nestedSelector]);
        cssString += "  }\n";
      }
    } else {
      const numberOfSpacesForProperty = 2;
      _addProperty(numberOfSpacesForProperty, selectorStyles);
    }
    cssString += "}\n";
  }
  return cssString;
}
