import React, { useMemo, Fragment, useEffect, useState } from 'react';
import './form-builder.form.sass';
import {
  FORM_BUILDER_INPUT_CLASSES,
  FORM_BUILDER_LAYOUT_ELEMENT_TYPE,
  FORM_BUILDER_STYLE_ELEMENT_TYPE,
  itemClassToType,
} from '../../utils/form-builder.types';
import { useSelector } from 'react-redux';
import { GroupOneName } from '../../utils/form-builder-list.schema';
import { Config } from '../../../../utils/config';
import { FormBuilderTranslatorService } from '../../services/translator/form-builder.translator.service';
import FormBuilderFormInterfaceInput from './interface/form-builder.form.interface.input';
import FormBuilderFormInterfaceHeader from './interface/header/form-builder.form.interface.header';
import FormBuilderFormInterfaceTextarea from './interface/textarea/form-builder.form.interface.textarea';
import FormBuilderFormInterfaceFileUpload from './interface/file-upload/form-builder.form.interface.file-upload';
import FormBuilderFormInterfaceSubmitButton from './interface/submit-button/form-builder.form.interface.submit-button';
import FormBuilderFormInterfaceSuccessMessage from './interface/success-message/form-builder.form.interface.success-message';
import FormBuilderFormEmpty from './empty/form-builder.form-empty';
import FormBuilderFormInterfaceLine from './interface/line/form-builder.form.interface.line';
import FormBuilderFormInterfaceParagraph from './interface/paragraph/form-builder.form.interface.paragraph';
import FormBuilderFormInterfaceConsent from './interface/consent/form-builder.form.interface.consent';
import FormBuilderFormInterfaceFullName from './interface/full-name/form-builder.form.interface.full-name';
import FormBuilderFormInterfaceImage from './interface/image/form-builder.form.interface.image';
import FormBuilderFormInterfaceHeading from './interface/heading/form-builder.form.interface.heading';
import FormBuilderFormInterfaceSpacer from './interface/spacer/form-builder.form.interface.spacer';
import FormBuilderFormInterfaceChoice from './interface/choice/form-builder.form.interface.choice';
import FormBuilderFormInterfaceDropdown from './interface/dropdown/form-builder.form.interface.dropdown';
import FormBuilderFormInterfaceScale from './interface/scale/form-builder.form.interface.scale';
import FormBuilderFormInterfaceCommunityConsent from './interface/community-consent/form-builder.form.interface.community-consent';
import FormBuilderFormInterfaceThumbScale from './interface/thumb-scale/form-builder.form.interface.thumb-scale';
import FormBuilderFormInterfaceNumberScale from './interface/number-scale/form-builder.form.interface.number-scale';
import FormBuilderFormInterfaceAcceptance from './interface/acceptance/form-builder.form.interface.acceptance';
import FormBuilderFormInterfaceHidden from './interface/hidden/form-builder.form.interface.hidden';
import FormBuilderFormInterfaceMatrix from './interface/matrix/form-builder.form.interface.matrix';
import FormBuilderFormInterfaceVideo from './interface/video/form-builder.form.interface.video';
import FormBuilderFormInterfaceAddress from './interface/address/form-builder.form.interface.address';
import { isoLanguageList } from '../../../../services/functions/language/language';
import useFormAnalytics from '../../../../components/hooks/use-form-analytics/use-form-analytics';

const kebabToCamelCaseProperties = obj => {
  const camelCaseKey = key => {
    if (key === '--stars') {
      return key;
    }
    return key.replace(/-([a-z])/g, (match, p1) => p1.toUpperCase());
  };

  let newObj = {};
  for (let selector in obj) {
    newObj[selector] = {};
    for (let prop in obj[selector]) {
      newObj[selector][camelCaseKey(prop)] = obj[selector][prop];
    }
  }
  return newObj;
};

export const FormBuilderForm = ({
  forceSuccessMessageVisibility = false,
  injectedData,
  language,
  tintAnonymousUid,
  element,
  isPreview,
  formName = 'tint-form-name',
  tintAnalyticsClient,
}) => {
  const [isFormSent, setIsFormSent] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [pendingComponents, setPendingComponents] = useState({});

  const isAnyComponentsPending = useMemo(() => {
    for (let key in pendingComponents) {
      if (pendingComponents[key] === true) {
        return true;
      }
    }
    return false;
  }, [pendingComponents]);

  const [currentLocale, setCurrentLocale] = useState(language);

  let translations,
    elements,
    styles,
    groups,
    teamId,
    formId,
    isPublicForm,
    apiUrl,
    layout,
    included,
    uploadedImages,
    noTrack,
    trackingEnabled,
    formValues;

  if (!injectedData) {
    const _formBuilder = useSelector(state => state.formBuilder);
    const data = _formBuilder?.data;
    uploadedImages = _formBuilder.uploadedImages;
    translations = data?.draft?.locales;
    elements = data?.draft.groups[GroupOneName]?.elements;
    styles = data?.draft.styles;
    groups = data?.draft.groups;
    layout = data?.draft.layout;
    //TODO GET TEAM ID SOMEHOW OR IGNORE IT CUZ ITS ONLY FOR LOCAL DEV FOR NOW :pray:!
    formValues = data?.formValues;
    teamId = '1';
    formId = _formBuilder.id;
    apiUrl = Config.getApiUrl();
    isPublicForm = false;
    included = _formBuilder.included;
    noTrack = false;
    trackingEnabled = false;
  } else {
    included = injectedData?.included;
    translations = injectedData.locales;
    elements = injectedData.groups[GroupOneName]?.elements;
    styles = injectedData.styles;
    groups = injectedData.groups;
    layout = injectedData.layout;
    formValues = injectedData?.formValues;
    teamId = injectedData.teamId;
    formId = injectedData.formId;
    apiUrl = injectedData.apiUrl;
    isPublicForm = true;
    noTrack = injectedData.noTrack;
    trackingEnabled = injectedData.tracking_enabled;
  }

  styles = kebabToCamelCaseProperties(styles);

  //loading translations on component mount
  useEffect(() => {
    const _currentLocale = language
      ? // our default form language is 'en_US' and since 'en-US' and 'en_US'
        // are two different languages we have to make this exception here
        language.toLowerCase() === 'en-us'
        ? language
        : //we want to keep '-' in html so we need to malform it to _ for i18next
          language.replace('-', '_')
      : translations[Config.language.defaultLanguage]
      ? Config.language.defaultLanguage
      : Object.keys(translations)[0];

    const locale = FormBuilderTranslatorService.init(JSON.parse(JSON.stringify(translations)), _currentLocale);
    setCurrentLocale(locale);
  }, [language]);

  const analyticForm = useFormAnalytics({
    formName,
    formId,
    teamId,
    tintAnonymousUid,
    tracker: tintAnalyticsClient,
    noTrack,
    featureFlag: trackingEnabled,
  });

  useEffect(() => {
    try {
      element.dispatchEvent(new CustomEvent('afterLoad'));
      if (tintAnalyticsClient) {
        tintAnalyticsClient.recordTraits({ embed_url: window.location.href, team_id: teamId, form_id: formId });
        // these events once recorded will be sent to the analytics server with every track event
        tintAnalyticsClient?.track('form-builder-loaded', { form_id: formId });
      }
    } catch (error) {
      console.error('Error in onLoad function', error);
    }
    analyticForm?.trackFormViewed();
  }, []);

  const formValuesObj = new URLSearchParams(formValues);

  const renderElement = item => {
    if (item) {
      switch (itemClassToType(item.class)) {
        case FORM_BUILDER_INPUT_CLASSES.DROPDOWN:
          return <FormBuilderFormInterfaceDropdown item={item} styles={styles} values={formValuesObj} />;
        case FORM_BUILDER_INPUT_CLASSES.SPACER:
          return <FormBuilderFormInterfaceSpacer item={item} styles={styles} />;
        case FORM_BUILDER_INPUT_CLASSES.HEADING:
          return <FormBuilderFormInterfaceHeading item={item} styles={styles} />;
        case FORM_BUILDER_INPUT_CLASSES.FULL_NAME:
          return (
            <FormBuilderFormInterfaceFullName
              item={item}
              styles={styles}
              values={formValuesObj}
              analyticForm={analyticForm}
            />
          );

        case FORM_BUILDER_INPUT_CLASSES.SINGLE_LINE_TEXT:
        case FORM_BUILDER_INPUT_CLASSES.NUMBER:
        case FORM_BUILDER_INPUT_CLASSES.WEBSITE:
        case FORM_BUILDER_INPUT_CLASSES.EMAIL:
        case FORM_BUILDER_INPUT_CLASSES.PHONE:
        case FORM_BUILDER_INPUT_CLASSES.DATE:
        case FORM_BUILDER_INPUT_CLASSES.BIRTHDAY:
          return (
            <FormBuilderFormInterfaceInput
              item={item}
              styles={styles}
              values={formValuesObj}
              analyticForm={analyticForm}
            />
          );

        case FORM_BUILDER_INPUT_CLASSES.HEADER:
          return <FormBuilderFormInterfaceHeader item={item} styles={styles} />;
        case FORM_BUILDER_INPUT_CLASSES.MULTI_LINE_TEXT:
          return (
            <FormBuilderFormInterfaceTextarea
              item={item}
              styles={styles}
              values={formValuesObj}
              analyticForm={analyticForm}
            />
          );
        case FORM_BUILDER_INPUT_CLASSES.FILE_UPLOAD:
          return (
            <FormBuilderFormInterfaceFileUpload
              item={item}
              styles={styles}
              teamId={teamId}
              formId={formId}
              apiUrl={apiUrl}
              analyticForm={analyticForm}
              onPending={(isPending, inputName) => setPendingComponents(s => ({ ...s, [inputName]: isPending }))}
            />
          );
        case FORM_BUILDER_INPUT_CLASSES.STAR_RATING:
          return (
            <FormBuilderFormInterfaceScale
              item={item}
              styles={styles}
              styleElementFieldset={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.STAR_RATING_FIELDSET]}
              styleElementInput={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.STAR_RATING_INPUT]}
              styleElementLabel={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.STAR_RATING_LABEL]}
              values={formValuesObj}
              analyticForm={analyticForm}
            />
          );
        case FORM_BUILDER_INPUT_CLASSES.LINE:
          return <FormBuilderFormInterfaceLine item={item} styles={styles} />;
        case FORM_BUILDER_INPUT_CLASSES.PARAGRAPH:
          return <FormBuilderFormInterfaceParagraph item={item} styles={styles} />;
        case FORM_BUILDER_INPUT_CLASSES.CONSENT:
          return <FormBuilderFormInterfaceConsent item={item} styles={styles} analyticForm={analyticForm} />;
        case FORM_BUILDER_INPUT_CLASSES.COMMUNITY_CONSENT:
          return (
            <FormBuilderFormInterfaceCommunityConsent
              item={item}
              styles={styles}
              formId={formId}
              analyticForm={analyticForm}
            />
          );
        case FORM_BUILDER_INPUT_CLASSES.ACCEPTANCE:
          return <FormBuilderFormInterfaceAcceptance item={item} styles={styles} analyticForm={analyticForm} />;

        case FORM_BUILDER_INPUT_CLASSES.MULTIPLE_CHOICE:
        case FORM_BUILDER_INPUT_CLASSES.SINGLE_CHOICE:
          return (
            <FormBuilderFormInterfaceChoice
              item={item}
              styles={styles}
              styleElementFieldset={
                styles[
                  item.class.includes(FORM_BUILDER_INPUT_CLASSES.SINGLE_CHOICE)
                    ? FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_CHOICE_FIELDSET
                    : FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_CHOICE_FIELDSET
                ]
              }
              styleElementLegend={
                styles[
                  item.class.includes(FORM_BUILDER_INPUT_CLASSES.SINGLE_CHOICE)
                    ? FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_CHOICE_LEGEND
                    : FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_CHOICE_LEGEND
                ]
              }
              styleElementInput={
                styles[
                  item.class.includes(FORM_BUILDER_INPUT_CLASSES.SINGLE_CHOICE)
                    ? FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_CHOICE_INPUT
                    : FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_CHOICE_INPUT
                ]
              }
              styleElementLabel={
                styles[
                  item.class.includes(FORM_BUILDER_INPUT_CLASSES.SINGLE_CHOICE)
                    ? FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_CHOICE_LABEL
                    : FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_CHOICE_LABEL
                ]
              }
              formId={formId}
              values={formValuesObj}
              analyticForm={analyticForm}
            />
          );

        case FORM_BUILDER_INPUT_CLASSES.IMAGE:
          return (
            <FormBuilderFormInterfaceImage
              item={item}
              styles={styles}
              included={included}
              uploadedImages={uploadedImages}
            />
          );
        case FORM_BUILDER_INPUT_CLASSES.THUMB_SCALE:
          return <FormBuilderFormInterfaceThumbScale item={item} styles={styles} values={formValuesObj} />;
        case FORM_BUILDER_INPUT_CLASSES.SMILEY_SCALE:
          return (
            <FormBuilderFormInterfaceScale
              item={item}
              styles={styles}
              styleElementFieldset={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SMILEY_SCALE_FIELDSET]}
              styleElementInput={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SMILEY_SCALE_INPUT]}
              styleElementLabel={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SMILEY_SCALE_LABEL]}
              values={formValuesObj}
              analyticForm={analyticForm}
            />
          );
        case FORM_BUILDER_INPUT_CLASSES.NUMBER_SCALE:
          return (
            <FormBuilderFormInterfaceNumberScale
              item={item}
              styles={styles}
              values={formValuesObj}
              analyticForm={analyticForm}
            />
          );

        //TODO nie wiem co to jest
        case FORM_BUILDER_INPUT_CLASSES.PRODUCT_ASSOCIATION:
        case FORM_BUILDER_INPUT_CLASSES.HIDDEN:
          return <FormBuilderFormInterfaceHidden item={item} values={formValuesObj} />;
        case FORM_BUILDER_INPUT_CLASSES.SINGLE_MATRIX:
          return (
            <FormBuilderFormInterfaceMatrix
              item={item}
              styles={styles}
              values={formValuesObj}
              styleElement={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX]}
              styleElementLegend={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX_LEGEND]}
              styleElementTable={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX_TABLE]}
              styleElementTheadTh={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX_THEAD_TH]}
              styleElementTbodyTh={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX_TBODY_TH]}
              styleElementTbodyTd={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX_TBODY_TD]}
              styleElementInput={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX_INPUT]}
              analyticForm={analyticForm}
            />
          );
        case FORM_BUILDER_INPUT_CLASSES.MULTIPLE_MATRIX:
          return (
            <FormBuilderFormInterfaceMatrix
              item={item}
              styles={styles}
              values={formValuesObj}
              styleElement={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX]}
              styleElementLegend={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX_LEGEND]}
              styleElementTable={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX_TABLE]}
              styleElementTheadTh={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX_THEAD_TH]}
              styleElementTbodyTh={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX_TBODY_TH]}
              styleElementTbodyTd={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX_TBODY_TD]}
              styleElementInput={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX_INPUT]}
              formId={formId}
              analyticForm={analyticForm}
            />
          );
        case FORM_BUILDER_INPUT_CLASSES.ADDRESS:
          return (
            <FormBuilderFormInterfaceAddress
              item={item}
              styles={styles}
              values={formValuesObj}
              formId={formId}
              locale={language}
              onPending={(isPending, inputName) => setPendingComponents(s => ({ ...s, [inputName]: isPending }))}
              isPreview={isPreview}
              analyticForm={analyticForm}
            />
          );
        case FORM_BUILDER_INPUT_CLASSES.VIMEO:
          return <FormBuilderFormInterfaceVideo item={item} styles={styles} isPreview={isPreview} />;

        case FORM_BUILDER_INPUT_CLASSES.YOUTUBE:
          return (
            <FormBuilderFormInterfaceVideo item={item} styles={styles} isPreview={isPreview} language={language} />
          );
        default:
          return;
      }
    }

    console.error('Invalid item type');
  };

  let url = `${apiUrl}/v2/teams/${teamId}/forms/${formId}/form_submissions`;
  if (currentLocale) {
    url += `?include=files&locale=${currentLocale?.replace('_', '-')}`;
  }

  const handleSubmit = async e => {
    e.preventDefault();
    if (isSubmitted) return;
    if (!element.dispatchEvent(new CustomEvent('beforeSubmit', { cancelable: true }))) return;

    setIsSubmitted(true);
    const headers = {};
    const formData = new FormData(e.target);
    const filteredFormData = new FormData();

    for (const [key, value] of formData.entries()) {
      // Skip file types data
      if (!(value instanceof File)) {
        filteredFormData.append(key, value);
      }
    }
    if (tintAnonymousUid) {
      headers['X-TINT-Anonymous-UID'] = tintAnonymousUid;
    }

    const deviceFingerprint = window.bbDeviceFingerprint;
    if (deviceFingerprint) {
      headers['X-TINT-Device-Fingerprint'] = deviceFingerprint;
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        body: filteredFormData,
        headers,
        referrerPolicy: 'no-referrer-when-downgrade',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseq = await response.json();

      // Assuming the server responds with JSON-formatted data
      try {
        setIsFormSent(true);
        (async () => {
          await analyticForm?.trackFormSubmitted();
          element.dispatchEvent(new CustomEvent('afterSubmit', { detail: responseq }));
        })();
      } catch (error) {
        console.error('Error in onLoad function', error);
      }
    } catch (error) {
      console.error('There was an error submitting the form!', error);
    }
  };

  const direction = isoLanguageList[language]?.direction || 'ltr';

  return (
    <>
      {elements?.length === 0 ? (
        <FormBuilderFormEmpty />
      ) : (
        <form
          dir={direction}
          name={formName}
          className={`div div-form ${!isPublicForm ? 'div-form--builder' : ''}`}
          method={'POST'}
          action={url}
          encType={'multipart/form-data'}
          style={styles?.[FORM_BUILDER_STYLE_ELEMENT_TYPE.FORM]}
          onSubmit={handleSubmit}>
          <div className='div-form__body'>
            {forceSuccessMessageVisibility || isFormSent ? (
              <FormBuilderFormInterfaceSuccessMessage
                styles={styles}
                item={layout?.[FORM_BUILDER_LAYOUT_ELEMENT_TYPE.SUCCESS_MESSAGE]}
              />
            ) : (
              <>
                {elements?.map((item, i) => {
                  return <Fragment key={i}>{renderElement(item)}</Fragment>;
                })}
                <FormBuilderFormInterfaceSubmitButton
                  styles={styles}
                  disabled={isAnyComponentsPending}
                  item={layout?.[FORM_BUILDER_LAYOUT_ELEMENT_TYPE.SUBMIT_BUTTON]}
                />
              </>
            )}
          </div>
        </form>
      )}
    </>
  );
};
