import { SocialAccountsFooterLabels } from '../helpers/helpers';
import { SidebarItemTypes } from '../../../../tint-editor/components/tint-editor/sidebars/social-feed-sidebar/utils/meta/consts';
import { SocialFeedsSource } from '../../../../../types/social-feeds';

import * as Yup from 'yup';

export const validateSchema = () => {
  return Yup.object({
    access_token: Yup.string().required('API Key is required.'),
    external_id: Yup.string().required('Merchant ID is required.'),
  });
};

export const powerReviews = [
  {
    footer: {
      leftButtonLabel: SocialAccountsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialAccountsFooterLabels.firstStepNext,
    },
  },
  {
    footer: {
      leftButtonLabel: SocialAccountsFooterLabels.finalStepPrev,
      rightButtonLabel: SocialAccountsFooterLabels.finalStepNext,
    },
    accountSourceType: SocialFeedsSource.POWER_REVIEWS,
    header: 'Add PowerReviews',
    selectedOption: { id: 'account', title: 'Add PowerReviews' },
    formSchema: {
      account: {
        submitMapper: values => ({
          access_token: values.access_token,
          external_id: values.external_id,
        }),
        validationSchema: validateSchema(),
        inputs: [
          {
            id: 'access_token',
            backendId: 'access_token',
            label: 'API KEY',
            placeholder: 'Enter API Key',
            type: SidebarItemTypes.TEXT,
          },
          {
            id: 'external_id',
            backendId: 'external_id',
            label: 'Merchant ID',
            placeholder: 'Enter Merchant ID',
            type: SidebarItemTypes.TEXT,
          },
        ],
      },
    },
  },
];
