import { ACTIONS } from '../../actions/ui/ui.actions';
import { produce } from 'immer';

export const initialState = {
  modals: {},
  sidebars: {},
  dropdowns: {},
  infiniteLists: {},
};

export const ui = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_MEDIA_DATA:
      return {
        ...state,
        sidebars: {
          ...state?.sidebars,
          [action.payload?.id]: {
            media: action.payload?.data,
          },
        },
      };

    case ACTIONS.OPEN_MODAL:
      return {
        ...state,
        modals: {
          ...state?.modals,
          [action.payload.id]: action.payload.data ? action.payload.data : {},
        },
      };

    case ACTIONS.CLOSE_MODAL: {
      const modals = state?.modals;
      delete modals[action.payload];

      return {
        ...state,
        modals: modals,
      };
    }

    case ACTIONS.CLOSE_ALL_MODALS: {
      return {
        ...state,
        modals: {},
      };
    }

    case ACTIONS.OPEN_SIDEBAR:
      return {
        ...state,
        sidebars: {
          ...state?.sidebars,
          [action.payload.id]: action.payload.data ? action.payload.data : {},
        },
      };

    case ACTIONS.CLOSE_SIDEBAR: {
      const sidebars = { ...state?.sidebars };
      delete sidebars?.[action.payload];

      return {
        ...state,
        sidebars: sidebars,
      };
    }
    case ACTIONS.RESET_UI: {
      return initialState;
    }

    case ACTIONS.SET_DROPDOWN_DATA:
      return produce(state, draft => {
        if (action.payload?.query) {
          draft.dropdowns[action.payload.id].query = action.payload?.query;
          draft.dropdowns[action.payload.id].links = action.payload?.links;

          const hasOverride = action.payload?.query === state.dropdowns[action.payload.id].query;

          draft.dropdowns[action.payload.id].data = removeDuplicates(
            hasOverride
              ? [...(state.dropdowns?.[action.payload.id]?.data ?? []), ...(action.payload?.data || [])]
              : action.payload?.data
          );
        } else {
          const hasOverride = !!(
            state.dropdowns[action.payload.id] && action.payload?.query === state.dropdowns[action.payload.id]?.query
          );
          draft.dropdowns[action.payload.id] = {
            data: removeDuplicates(
              hasOverride
                ? [...(state.dropdowns?.[action.payload.id]?.data ?? []), ...(action.payload?.data || [])]
                : action.payload?.data || []
            ),
            query: undefined,
            links: action.payload?.links,
          };
        }
      });

    case ACTIONS.SET_INFINITE_LIST_DATA:
      return produce(state, draft => {
        if (action.payload?.query) {
          draft.infiniteLists[action.payload.id].query = action.payload?.query;
          draft.infiniteLists[action.payload.id].links = action.payload?.links;

          const hasOverride = action.payload?.query === state.infiniteLists[action.payload.id].query;

          draft.infiniteLists[action.payload.id].data = removeDuplicates(
            hasOverride
              ? [...(state.infiniteLists?.[action.payload.id]?.data ?? []), ...(action.payload?.data || [])]
              : action.payload?.data
          );
        } else {
          const hasOverride = !!(
            state.infiniteLists[action.payload.id] &&
            action.payload?.query === state.infiniteLists[action.payload.id]?.query
          );
          draft.infiniteLists[action.payload.id] = {
            data: removeDuplicates(
              hasOverride
                ? [...(state.infiniteLists?.[action.payload.id]?.data ?? []), ...(action.payload?.data || [])]
                : action.payload?.data || []
            ),
            query: undefined,
            links: action.payload?.links,
          };
        }
      });

    case ACTIONS.UPDATE_DROPDOWN_DATA:
      return produce(state, draft => {
        const currentElementIndex = draft.dropdowns[action.payload.id]?.data?.findIndex(
          el => el.id === action?.payload?.id
        );

        if (!currentElementIndex) {
          return;
        }

        draft.dropdowns[action.payload.id].data[currentElementIndex] = action.payload.data;
      });

    case ACTIONS.CLEAR_DROPDOWN_DATA:
      return produce(state, draft => {
        draft.dropdowns[action.payload.id] = {
          data: [],
          query: undefined,
          links: undefined,
        };
      });

    case ACTIONS.CLEAR_INFINITE_LIST_DATA:
      return produce(state, draft => {
        draft.infiniteLists[action.payload.id] = {
          data: [],
          query: undefined,
          links: undefined,
        };
      });

    default:
      return state;
  }
};

const removeDuplicates = data => Array.from(new Set(data.map(JSON.stringify))).map(JSON.parse);
