import React, { useEffect } from "react";
import { useFormBuilderCountries } from "../../../../hooks/use-form-builder-countries";
import { FormBuilderElementsMappingService } from "../../../../services/builder/form-builder.elements-mapping.service";
const FormBuilderFormInterfaceAddress = ({ item, values, formId, locale, onPending, isPreview, analyticForm, t }) => {
  var _a, _b, _c;
  if (!item) return null;
  const { localizedCountriesList, onCountryChange, updateCountryDetails, countryDetails } = useFormBuilderCountries({
    item,
    formId,
    locale,
    onPending
  });
  const tags = FormBuilderElementsMappingService.getAllSingleElementHTMLTags(item);
  const legend = tags.legend[0];
  const divs = tags.div;
  const defaultSubdivision = (_a = countryDetails == null ? void 0 : countryDetails.included) == null ? void 0 : _a.find((el) => (el == null ? void 0 : el.type) === "subdivision");
  const codePattern = (_c = (_b = countryDetails == null ? void 0 : countryDetails.data) == null ? void 0 : _b.attributes) == null ? void 0 : _c.postal_code_pattern;
  useEffect(() => {
    var _a2;
    onCountryChange({ localizeCountryNames: true });
    if (isPreview && localizedCountriesList.length) {
      const defaultCountry = (_a2 = localizedCountriesList == null ? void 0 : localizedCountriesList[0]) == null ? void 0 : _a2.value;
      updateCountryDetails(defaultCountry);
    }
  }, []);
  const onFocus = (e, input) => {
    analyticForm == null ? void 0 : analyticForm.trackInputFocused(input.name);
  };
  const onBlur = (input) => analyticForm == null ? void 0 : analyticForm.trackInputBlurred(input.name);
  const renderSelectElement = (element, label, isStateInput, previewList) => {
    var _a2;
    const list = isStateInput ? countryDetails == null ? void 0 : countryDetails.subdivisionsList : localizedCountriesList;
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("label", { htmlFor: label.for }, t(label.nodeValue)), /* @__PURE__ */ React.createElement(
      "select",
      {
        onChange: isStateInput ? null : (e) => onCountryChange({ e }),
        onFocus: (e) => onFocus(e, element),
        onBlur: () => onBlur(element),
        name: element.name,
        id: element.id,
        autoComplete: element.autocomplete,
        defaultValue: ((_a2 = values == null ? void 0 : values.getAll(element.name)) == null ? void 0 : _a2.length) ? values.getAll(element.name)[0] : "",
        required: element.required
      },
      (isPreview ? previewList : list).map((option, index) => {
        return /* @__PURE__ */ React.createElement("option", { key: `${option.value}-${index}`, value: option.value, id: option.value }, option.name);
      })
    ));
  };
  return /* @__PURE__ */ React.createElement("fieldset", { className: item.class }, /* @__PURE__ */ React.createElement("legend", null, t(legend.nodeValue)), divs.map((div, index) => {
    var _a2, _b2, _c2, _d, _e;
    const label = div.elements.find((el) => (el == null ? void 0 : el.nodeName) === "label");
    const element = div.elements.find((el) => (el == null ? void 0 : el.nodeName) === "input" || (el == null ? void 0 : el.nodeName) === "select");
    const isPostalCodeInput = element.autocomplete.includes("postal-code");
    const isStateInput = element.autocomplete.includes("address-level1");
    const previewList = isStateInput ? [{ value: defaultSubdivision == null ? void 0 : defaultSubdivision.id, name: (_a2 = defaultSubdivision == null ? void 0 : defaultSubdivision.attributes) == null ? void 0 : _a2.name }] : [{ value: (_b2 = countryDetails == null ? void 0 : countryDetails.data) == null ? void 0 : _b2.id, name: (_d = (_c2 = countryDetails == null ? void 0 : countryDetails.data) == null ? void 0 : _c2.attributes) == null ? void 0 : _d.name }];
    return /* @__PURE__ */ React.createElement("div", { className: "input", key: `${element.id}-${index}` }, element.nodeName === "select" ? isStateInput ? ((_e = countryDetails == null ? void 0 : countryDetails.subdivisionsList) == null ? void 0 : _e.length) || isPreview ? renderSelectElement(element, label, isStateInput, previewList) : null : renderSelectElement(element, label, isStateInput, previewList) : isPostalCodeInput && codePattern || isPreview || !isPostalCodeInput ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("label", { htmlFor: label.for }, t(label.nodeValue)), /* @__PURE__ */ React.createElement(
      "input",
      {
        onFocus: (e) => onFocus(e, element),
        type: element.type,
        autoComplete: element.autocomplete,
        id: element.id,
        defaultValue: values == null ? void 0 : values.getAll(element.name),
        name: element.name,
        pattern: isPostalCodeInput ? codePattern : null,
        placeholder: t(element.placeholder),
        required: element.required
      }
    )) : null);
  }));
};
FormBuilderFormInterfaceAddress.displayName = "FormBuilderFormInterfaceAddress";
export default React.memo(FormBuilderFormInterfaceAddress);
