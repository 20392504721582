import React from 'react';
import { getTintsUrlWithParams } from '../../../feature/tint-editor/utils/filter-helper/filter-helper';
import { Config } from '../../../utils/config';
import ApiDropdown from '../api-dropdown';
import { getUrl } from '../api-dropdown.helpers';
import { filters } from '../../../feature/account-settings/utils/filter-helper/filter-helper';

const tintsDropdownId = 'tints';

const createTintAdditionalOption = {
  name: 'Create Tint',
  value: 'create_tint',
  link: Config.routePaths.main.react_path,
};

export const DropdownTints = ({ currentElement, onChange, fixedOption = true }) => {
  return (
    <div className='tint-tints-dropdown'>
      <ApiDropdown
        label={'Select Board'}
        placeholder={'Select a Board'}
        fetchUrl={(url, query) =>
          getUrl({
            url,
            query,
            baseUrl: '/tints',
            filters: [
              {
                by: filters.QUERY,
                value: query,
              },
            ],
          })
        }
        baseUrl='/tints'
        currentElement={currentElement}
        dropdownId={tintsDropdownId}
        onChange={onChange}
        fixedOption={fixedOption}
        additionalOption={fixedOption ? createTintAdditionalOption : undefined}
      />
    </div>
  );
};
