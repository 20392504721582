var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import React, { Fragment, useEffect, useMemo, useState } from "react";
import {
  ELEMENTS_CLASSES,
  FORM_BUILDER_LAYOUT_ELEMENT_TYPE,
  GroupOneName,
  itemClassToType
} from "../../utils/form-builder.types";
import { Config } from "../../../../utils/config";
import { FormBuilderTranslatorService } from "../../services/translator/form-builder.translator.service";
import FormBuilderFormInterfaceInput from "./interface/form-builder.form.interface.input";
import FormBuilderFormInterfaceHeader from "./interface/header/form-builder.form.interface.header";
import FormBuilderFormInterfaceTextarea from "./interface/textarea/form-builder.form.interface.textarea";
import FormBuilderFormInterfaceFileUpload from "./interface/file-upload/form-builder.form.interface.file-upload";
import FormBuilderFormInterfaceSubmitButton from "./interface/submit-button/form-builder.form.interface.submit-button";
import FormBuilderFormInterfaceSuccessMessage from "./interface/success-message/form-builder.form.interface.success-message";
import FormBuilderFormEmpty from "./empty/form-builder.form-empty";
import FormBuilderFormInterfaceLine from "./interface/line/form-builder.form.interface.line";
import FormBuilderFormInterfaceParagraph from "./interface/paragraph/form-builder.form.interface.paragraph";
import FormBuilderFormInterfaceConsent from "./interface/consent/form-builder.form.interface.consent";
import FormBuilderFormInterfaceFullName from "./interface/full-name/form-builder.form.interface.full-name";
import FormBuilderFormInterfaceImage from "./interface/image/form-builder.form.interface.image";
import FormBuilderFormInterfaceHeading from "./interface/heading/form-builder.form.interface.heading";
import FormBuilderFormInterfaceSpacer from "./interface/spacer/form-builder.form.interface.spacer";
import FormBuilderFormInterfaceChoice from "./interface/choice/form-builder.form.interface.choice";
import FormBuilderFormInterfaceDropdown from "./interface/dropdown/form-builder.form.interface.dropdown";
import FormBuilderFormInterfaceScale from "./interface/scale/form-builder.form.interface.scale";
import FormBuilderFormInterfaceCommunityConsent from "./interface/community-consent/form-builder.form.interface.community-consent";
import FormBuilderFormInterfaceThumbScale from "./interface/thumb-scale/form-builder.form.interface.thumb-scale";
import FormBuilderFormInterfaceNumberScale from "./interface/number-scale/form-builder.form.interface.number-scale";
import FormBuilderFormInterfaceAcceptance from "./interface/acceptance/form-builder.form.interface.acceptance";
import FormBuilderFormInterfaceHidden from "./interface/hidden/form-builder.form.interface.hidden";
import FormBuilderFormInterfaceMatrix from "./interface/matrix/form-builder.form.interface.matrix";
import FormBuilderFormInterfaceVideo from "./interface/video/form-builder.form.interface.video";
import FormBuilderFormInterfaceAddress from "./interface/address/form-builder.form.interface.address";
import { isoLanguageList } from "../../../../services/functions/language/language";
import useFormAnalytics from "../../../../components/hooks/use-form-analytics/use-form-analytics";
import Logger from "../../../../services/logger/logger";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { POST_SUBMIT_ACTION } from "../../../../../../../client-scripts/form-builder";
import { FormBuilderStylesService } from "../../services/builder/form-builder.styles.service";
export const FormBuilderForm = ({
  forceSuccessMessageVisibility = false,
  injectedData,
  language,
  tintAnonymousUid,
  element,
  isPreview,
  formName = "tint-form-name",
  tintAnalyticsClient
}) => {
  var _a, _b, _c, _d;
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [instanceT, setInstanceT] = useState(() => () => "");
  const { t } = useTranslation();
  const translateFn = isPreview ? t : instanceT;
  const [pendingComponents, setPendingComponents] = useState({});
  const isAnyComponentsPending = useMemo(() => {
    for (let key in pendingComponents) {
      if (pendingComponents[key] === true) {
        return true;
      }
    }
    return false;
  }, [pendingComponents]);
  const [currentLocale, setCurrentLocale] = useState(language);
  let translations, elements, styles, groups, teamId, formId, apiUrl, layout, included, uploadedImages, noTrack, trackingEnabled, formValues;
  if (!injectedData) {
    const _formBuilder = useSelector((state) => state.formBuilder);
    const data = _formBuilder == null ? void 0 : _formBuilder.data;
    uploadedImages = _formBuilder.uploadedImages;
    translations = (_a = data == null ? void 0 : data.draft) == null ? void 0 : _a.locales;
    elements = (_b = data == null ? void 0 : data.draft.groups[GroupOneName]) == null ? void 0 : _b.elements;
    styles = data == null ? void 0 : data.draft.styles;
    groups = data == null ? void 0 : data.draft.groups;
    layout = data == null ? void 0 : data.draft.layout;
    formValues = data == null ? void 0 : data.formValues;
    teamId = "1";
    formId = _formBuilder.id;
    apiUrl = Config.getApiUrl();
    included = _formBuilder.included;
    noTrack = false;
    trackingEnabled = false;
  } else {
    included = injectedData == null ? void 0 : injectedData.included;
    translations = injectedData.locales;
    elements = (_c = injectedData.groups[GroupOneName]) == null ? void 0 : _c.elements;
    styles = injectedData.styles;
    groups = injectedData.groups;
    layout = injectedData.layout;
    formValues = injectedData == null ? void 0 : injectedData.formValues;
    teamId = injectedData.teamId;
    formId = injectedData.formId;
    apiUrl = injectedData.apiUrl;
    noTrack = injectedData.noTrack;
    trackingEnabled = injectedData.tracking_enabled;
  }
  useEffect(() => {
    const _currentLocale = language ? (
      // our default form language is 'en_US' and since 'en-US' and 'en_US'
      // are two different languages we have to make this exception here
      language.toLowerCase() === "en-us" ? language : (
        //we want to keep '-' in html so we need to malform it to _ for i18next
        language.replace("-", "_")
      )
    ) : translations[Config.language.defaultLanguage] ? Config.language.defaultLanguage : Object.keys(translations)[0];
    const { language: locale, i18Instance } = FormBuilderTranslatorService.init(
      JSON.parse(JSON.stringify(translations)),
      _currentLocale,
      isPreview
    );
    setCurrentLocale(locale);
    setInstanceT((state) => (i18Instance == null ? void 0 : i18Instance.t) || state);
  }, [language]);
  const analyticForm = useFormAnalytics({
    formName,
    formId,
    teamId,
    tintAnonymousUid,
    tracker: tintAnalyticsClient,
    noTrack,
    featureFlag: trackingEnabled
  });
  useEffect(() => {
    if (element) {
      try {
        element.dispatchEvent(new CustomEvent("afterLoad"));
        if (tintAnalyticsClient) {
          tintAnalyticsClient.recordTraits({ embed_url: window.location.href, team_id: teamId, form_id: formId });
          tintAnalyticsClient == null ? void 0 : tintAnalyticsClient.track("form-builder-loaded", { form_id: formId });
        }
      } catch (error) {
        console.error("Error in onLoad function", error);
      }
    }
    analyticForm == null ? void 0 : analyticForm.trackFormViewed();
  }, []);
  const formValuesObj = new URLSearchParams(formValues);
  const renderElement = (item) => {
    if (item) {
      switch (itemClassToType(item.class)) {
        case ELEMENTS_CLASSES.DROPDOWN:
          return /* @__PURE__ */ React.createElement(FormBuilderFormInterfaceDropdown, { item, values: formValuesObj, t: translateFn });
        case ELEMENTS_CLASSES.SPACER:
          return /* @__PURE__ */ React.createElement(FormBuilderFormInterfaceSpacer, { item });
        case ELEMENTS_CLASSES.HEADING:
          return /* @__PURE__ */ React.createElement(FormBuilderFormInterfaceHeading, { item, t: translateFn });
        case ELEMENTS_CLASSES.FULL_NAME:
          return /* @__PURE__ */ React.createElement(
            FormBuilderFormInterfaceFullName,
            {
              item,
              values: formValuesObj,
              analyticForm,
              t: translateFn
            }
          );
        case ELEMENTS_CLASSES.SINGLE_LINE_TEXT:
        case ELEMENTS_CLASSES.NUMBER:
        case ELEMENTS_CLASSES.WEBSITE:
        case ELEMENTS_CLASSES.EMAIL:
        case ELEMENTS_CLASSES.PHONE:
        case ELEMENTS_CLASSES.DATE:
        case ELEMENTS_CLASSES.BIRTHDAY:
          return /* @__PURE__ */ React.createElement(
            FormBuilderFormInterfaceInput,
            {
              item,
              values: formValuesObj,
              analyticForm,
              t: translateFn
            }
          );
        case ELEMENTS_CLASSES.HEADER:
          return /* @__PURE__ */ React.createElement(FormBuilderFormInterfaceHeader, { item, t: translateFn });
        case ELEMENTS_CLASSES.MULTI_LINE_TEXT:
          return /* @__PURE__ */ React.createElement(
            FormBuilderFormInterfaceTextarea,
            {
              item,
              values: formValuesObj,
              analyticForm,
              t: translateFn
            }
          );
        case ELEMENTS_CLASSES.FILE_UPLOAD:
          return /* @__PURE__ */ React.createElement(
            FormBuilderFormInterfaceFileUpload,
            {
              item,
              teamId,
              formId,
              apiUrl,
              analyticForm,
              onPending: (isPending, inputName) => setPendingComponents((s) => __spreadProps(__spreadValues({}, s), { [inputName]: isPending })),
              t: translateFn
            }
          );
        case ELEMENTS_CLASSES.STAR_RATING:
          return /* @__PURE__ */ React.createElement(
            FormBuilderFormInterfaceScale,
            {
              item,
              values: formValuesObj,
              analyticForm,
              t: translateFn
            }
          );
        case ELEMENTS_CLASSES.LINE:
          return /* @__PURE__ */ React.createElement(FormBuilderFormInterfaceLine, { item });
        case ELEMENTS_CLASSES.PARAGRAPH:
          return /* @__PURE__ */ React.createElement(FormBuilderFormInterfaceParagraph, { item, t: translateFn });
        case ELEMENTS_CLASSES.CONSENT:
          return /* @__PURE__ */ React.createElement(FormBuilderFormInterfaceConsent, { item, analyticForm, t: translateFn });
        case ELEMENTS_CLASSES.COMMUNITY_CONSENT:
          return /* @__PURE__ */ React.createElement(
            FormBuilderFormInterfaceCommunityConsent,
            {
              item,
              formId,
              analyticForm,
              t: translateFn
            }
          );
        case ELEMENTS_CLASSES.ACCEPTANCE:
          return /* @__PURE__ */ React.createElement(FormBuilderFormInterfaceAcceptance, { item, analyticForm, t: translateFn });
        case ELEMENTS_CLASSES.MULTIPLE_CHOICE:
          return /* @__PURE__ */ React.createElement(
            FormBuilderFormInterfaceChoice,
            {
              item,
              formId,
              values: formValuesObj,
              analyticForm,
              t: translateFn
            }
          );
        case ELEMENTS_CLASSES.SINGLE_CHOICE:
          return /* @__PURE__ */ React.createElement(
            FormBuilderFormInterfaceChoice,
            {
              item,
              formId,
              values: formValuesObj,
              analyticForm,
              t: translateFn
            }
          );
        case ELEMENTS_CLASSES.IMAGE:
          return /* @__PURE__ */ React.createElement(
            FormBuilderFormInterfaceImage,
            {
              item,
              included,
              uploadedImages,
              t: translateFn
            }
          );
        case ELEMENTS_CLASSES.THUMB_SCALE:
          return /* @__PURE__ */ React.createElement(FormBuilderFormInterfaceThumbScale, { item, values: formValuesObj, t: translateFn });
        case ELEMENTS_CLASSES.SMILEY_SCALE:
          return /* @__PURE__ */ React.createElement(
            FormBuilderFormInterfaceScale,
            {
              item,
              values: formValuesObj,
              analyticForm,
              t: translateFn
            }
          );
        case ELEMENTS_CLASSES.NUMBER_SCALE:
          return /* @__PURE__ */ React.createElement(
            FormBuilderFormInterfaceNumberScale,
            {
              item,
              values: formValuesObj,
              analyticForm,
              t: translateFn
            }
          );
        case ELEMENTS_CLASSES.PRODUCT_ASSOCIATION:
        case ELEMENTS_CLASSES.HIDDEN:
          return /* @__PURE__ */ React.createElement(FormBuilderFormInterfaceHidden, { item, values: formValuesObj });
        case ELEMENTS_CLASSES.SINGLE_MATRIX:
          return /* @__PURE__ */ React.createElement(
            FormBuilderFormInterfaceMatrix,
            {
              item,
              values: formValuesObj,
              analyticForm,
              t: translateFn
            }
          );
        case ELEMENTS_CLASSES.MULTIPLE_MATRIX:
          return /* @__PURE__ */ React.createElement(
            FormBuilderFormInterfaceMatrix,
            {
              item,
              values: formValuesObj,
              formId,
              analyticForm,
              t: translateFn
            }
          );
        case ELEMENTS_CLASSES.ADDRESS:
          return /* @__PURE__ */ React.createElement(
            FormBuilderFormInterfaceAddress,
            {
              item,
              values: formValuesObj,
              formId,
              locale: language,
              onPending: (isPending, inputName) => setPendingComponents((s) => __spreadProps(__spreadValues({}, s), { [inputName]: isPending })),
              isPreview,
              analyticForm,
              t: translateFn
            }
          );
        case ELEMENTS_CLASSES.VIMEO:
          return /* @__PURE__ */ React.createElement(FormBuilderFormInterfaceVideo, { item, isPreview });
        case ELEMENTS_CLASSES.YOUTUBE:
          return /* @__PURE__ */ React.createElement(FormBuilderFormInterfaceVideo, { item, isPreview, language });
        default:
          return;
      }
    }
    Logger.error("Invalid item type");
  };
  let url = `${apiUrl}/v2/teams/${teamId}/forms/${formId}/form_submissions`;
  if (currentLocale) {
    url += `?include=files&locale=${currentLocale == null ? void 0 : currentLocale.replace("_", "-")}`;
  }
  const handleSubmit = (e) => __async(void 0, null, function* () {
    var _a2, _b2;
    e.preventDefault();
    if (isSubmitted) return;
    if (!element.dispatchEvent(new CustomEvent("beforeSubmit", { cancelable: true }))) return;
    setIsSubmitted(true);
    const headers = {};
    const formData = new FormData(e.target);
    const filteredFormData = new FormData();
    for (const [key, value] of formData.entries()) {
      if (!(value instanceof File)) {
        filteredFormData.append(key, value);
      }
    }
    if (tintAnonymousUid) {
      headers["X-TINT-Anonymous-UID"] = tintAnonymousUid;
    }
    const deviceFingerprint = window.bbDeviceFingerprint;
    if (deviceFingerprint) {
      headers["X-TINT-Device-Fingerprint"] = deviceFingerprint;
    }
    try {
      const response = yield fetch(url, {
        method: "POST",
        body: filteredFormData,
        headers,
        referrerPolicy: "no-referrer-when-downgrade"
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseq = yield response.json();
      try {
        setShowSuccessMessage(
          ((_a2 = layout == null ? void 0 : layout.postSubmit) == null ? void 0 : _a2.action) === POST_SUBMIT_ACTION.SHOW_SUCCESS_MESSAGE || !((_b2 = layout == null ? void 0 : layout.postSubmit) == null ? void 0 : _b2.action)
        );
        (() => __async(void 0, null, function* () {
          yield analyticForm == null ? void 0 : analyticForm.trackFormSubmitted();
          element.dispatchEvent(new CustomEvent("afterSubmit", { detail: responseq }));
        }))();
      } catch (error) {
        Logger.error("Error in onLoad function", error);
      }
    } catch (error) {
      Logger.error("There was an error submitting the form!", error);
    }
  });
  const direction = ((_d = isoLanguageList[language]) == null ? void 0 : _d.direction) || "ltr";
  const { formattedRestOfCss, formattedMediaCss } = FormBuilderStylesService.getFormattedStyles(
    styles,
    isPreview
  );
  return /* @__PURE__ */ React.createElement(React.Fragment, null, (elements == null ? void 0 : elements.length) === 0 ? /* @__PURE__ */ React.createElement(FormBuilderFormEmpty, null) : /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("style", null, formattedRestOfCss, formattedMediaCss), /* @__PURE__ */ React.createElement(
    "form",
    {
      dir: direction,
      name: formName,
      className: "div div-form",
      method: "POST",
      action: url,
      encType: "multipart/form-data",
      onSubmit: handleSubmit
    },
    /* @__PURE__ */ React.createElement("div", null, forceSuccessMessageVisibility || showSuccessMessage ? /* @__PURE__ */ React.createElement(
      FormBuilderFormInterfaceSuccessMessage,
      {
        item: layout == null ? void 0 : layout[FORM_BUILDER_LAYOUT_ELEMENT_TYPE.SUCCESS_MESSAGE],
        t: translateFn
      }
    ) : /* @__PURE__ */ React.createElement(React.Fragment, null, elements == null ? void 0 : elements.map((item, i) => {
      return /* @__PURE__ */ React.createElement(Fragment, { key: i }, renderElement(item));
    }), /* @__PURE__ */ React.createElement(
      FormBuilderFormInterfaceSubmitButton,
      {
        disabled: isAnyComponentsPending,
        item: layout == null ? void 0 : layout[FORM_BUILDER_LAYOUT_ELEMENT_TYPE.SUBMIT_BUTTON],
        t: translateFn
      }
    )))
  )));
};
