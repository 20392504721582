var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { submitButton } from "../elements/submit-button";
import { successMessage } from "../elements/success-message";
import { matrix } from "./shared/matrix";
import { choice } from "./shared/choice";
import { consents } from "./shared/consents";
export const common = __spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues({
  ROOT: ":host",
  ALL: "*",
  FORM: ".div-form",
  FORM_RTL: "[dir='rtl'] *",
  FORM_DIV: ".div-form > div",
  FORM_ELEMENTS: ".div-form > div > *",
  LINKS: "a, .input-file input::before",
  FIELDSET: "fieldset",
  INPUT_CLASS: ".div-form .input",
  INPUT_EDIT: "input, select, textarea",
  INPUT_CHECKBOX: 'input[type="checkbox"]',
  INPUT_CHECKBOX_DISABLED: 'input[type="checkbox"]:disabled',
  INPUT_DATE: "input[type='date']",
  INPUT_RADIO: "input[type='radio']",
  INPUT_NUMBER: "input[type='number']",
  INPUT_CHECKBOX_CHECKED: 'input[type="checkbox"]:checked',
  INPUT_FOCUS: "input:focus, select:focus, textarea:focus, .div-submit-button button:focus, a:focus",
  INPUT_TEXT_RTL: "*[dir='rtl'] input[type='text'], *[dir='rtl'] input[type='tel'], *[dir='rtl'] input[type='email'], *[dir='rtl'] input[type='url']",
  INPUT_NUMBER_RTL: "*[dir='rtl'] input[type='number']",
  SELECT: ".div-form select",
  SELECT_RTL: "*[dir='rtl'] select",
  LABEL: "label, legend, thead th, tbody th, .input-number-scale > div, .input-address label",
  LABEL_HIDDEN: ".label-hidden legend, .label-hidden label:first-child",
  HINT: ".div-hint",
  IFRAME: "iframe",
  REQUIRED: ".required legend:not(:empty):after",
  LABEL_REQUIRED: ".input > label:not(:empty):first-child:has(+ :required):after, .input:has(input[required]) legend:not(:empty):after"
}, consents), choice), matrix), submitButton), successMessage);
