import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import './tint-editor-top-bar.sass';
import { faTableCells, faTableList } from '@fortawesome/free-solid-svg-icons';
import TintEditorTopNavigation from '../top-bar-navigation/tint-editor-top-navigation';
import DropdownButton, {
  ButtonType,
  DropdownButtonType,
} from '../../../../../components/interface/dropdown/button/dropdown-button';
import { faSortSizeDown } from '@fortawesome/pro-solid-svg-icons';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { SearchInput, SearchInputType } from '../../../../../components/search-input/search-input';
import DropdownSortListRadioList, {
  getSortKey,
} from '../../../../../components/interface/dropdown/lists/sort-list-radio/dropdown-sort-list-radio-list';
import TintEditorTopBarFilter from '../top-bar-filter/tint-editor-top-bar-filter';
import { useDispatch } from 'react-redux';
import useDebounce from '../../../../../components/hooks/use-debounce/use-debounce';
import { setRenderType, sortPostsBy } from '../../../actions/posts.actions';
import { useHistory } from 'react-router-dom';
import { SortDropdownHeader } from './sort-dropdown-header';
import TintIcon from '../../../../../components/interface/icon/icon';
import { PostRenderType } from '../../../reducers/posts.reducer';
import { isAllowed } from '../../../../../services/functions/features-checker/features-checker';
import { Config } from '../../../../../utils/config';

export const SortBy = {
  TIME_POSTED: 'time-posted',
  LAST_MODERATED: 'last_moderated_at',
  PINNED: 'pinned',
  RELEVANCE: 'score',
};

const sortOptions = [
  {
    name: 'Time Posted',
    value: SortBy.TIME_POSTED,
    isActive: false,
  },
  {
    name: 'Last Moderated',
    value: SortBy.LAST_MODERATED,
    isActive: false,
  },
  {
    name: 'Pinned',
    value: SortBy.PINNED,
    isActive: false,
  },
  {
    name: 'Relevancy',
    value: SortBy.RELEVANCE,
    isActive: false,
  },
];

const TintEditorTopBar = ({
  onToggleClick,
  isGlobalPageState,
  modalDOMDestination,
  onFilterModalOpen,
  isScrollingDown,
  isAnyFilterSelected,
  isSettingsSidebarExpanded,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [queryValue, setQueryValue] = useState();
  const [isFilterModalOpen, setFilterModalVisibility] = useState(false);
  const initialUpdate = useRef();
  const debouncedPhrase = useDebounce(queryValue, 500);
  const renderType = useSelector(state => state?.tintEditorPosts.renderType);
  const session = useSelector(state => state.session);
  const [sortByValue, setSortBy] = useState(
    sortOptions.find(e => e.value === localStorage.getItem(getSortKey(session?.data?.currentTeam))) || sortOptions[0]
  );
  const [initialSortOption, setInitialSortOption] = useState(null);
  const featureList = session.data.features;
  const hasAccessToPostsReplies = isAllowed(featureList, [Config.features.boardsHorizontal]);
  useEffect(() => {
    if (debouncedPhrase !== undefined) {
      dispatch(sortPostsBy(debouncedPhrase, 'q'));
    }
  }, [dispatch, debouncedPhrase]);

  const setPageState = name => {
    history.push({
      search: `?page=${name.toLowerCase()}`,
    });
  };

  const onSearchInputChange = value => {
    setQueryValue(value);

    if (value !== '') {
      if (!initialSortOption) {
        const savedSortOptionValue = localStorage.getItem(getSortKey(session?.data?.currentTeam));
        setInitialSortOption(sortOptions.find(e => e.value === savedSortOptionValue) || sortOptions[0]);
      }

      const sortOption = sortOptions.find(e => e.value === SortBy.RELEVANCE);
      setSortBy(sortOption);
      localStorage.setItem(getSortKey(session?.data?.currentTeam), sortOption.value);
    } else {
      if (initialSortOption) {
        setSortBy(initialSortOption);
        localStorage.setItem(getSortKey(session?.data?.currentTeam), initialSortOption.value);
      }
      setInitialSortOption(null);
    }
  };

  return (
    <div
      ref={initialUpdate}
      className={`tint-editor-top-bar ${isFilterModalOpen ? 'tint-editor-top-bar--modal-filter-open' : ''}`}>
      <div className='tint-editor-top-bar__navigation'>
        <TintEditorTopNavigation
          isGlobalPageState={isGlobalPageState}
          onToggleClick={onToggleClick}
          isSettingsSidebarExpanded={isSettingsSidebarExpanded}
          onFeedTabClick={(feed, name) => {
            dispatch(sortPostsBy(feed, 'status'));
            setPageState(name);
          }}
        />
      </div>
      <div className={`tint-editor-top-bar__search ${isScrollingDown ? 'tint-editor-top-bar__search--collapsed' : ''}`}>
        <SearchInput inputValue={queryValue} onInputChange={onSearchInputChange} types={[SearchInputType.ROUND]} />
      </div>
      <div
        className={`tint-editor-top-bar__sort ${
          isScrollingDown
            ? isAnyFilterSelected && isScrollingDown
              ? 'tint-editor-top-bar__sort--filter-selected'
              : 'tint-editor-top-bar__sort--collapsed'
            : ''
        }`}>
        <DropdownButton
          dropdownHeader={props => <SortDropdownHeader {...props} iconCenter={faSortSizeDown} />}
          dropdownList={props => <DropdownSortListRadioList {...props} />}
          list={sortOptions}
          currentItem={sortByValue}
          dropdownType={DropdownButtonType.RADIO_BUTTON}
          iconLeft={faSortSizeDown}
          iconRight={faCaretDown}
          placeholder='Time Posted'
          buttonType={ButtonType.BUTTON_SORT_ACTIVE}
          onChangeValue={sortBy => {
            setSortBy(sortBy);
            dispatch(sortPostsBy(sortBy.value, 'sortBy'));
          }}
        />
        {hasAccessToPostsReplies ? (
          <div className='tint-editor-top-bar__view-type'>
            <div onClick={() => dispatch(setRenderType(PostRenderType.GRID))}>
              <TintIcon icon={faTableCells} color={renderType === PostRenderType.GRID ? '#212121' : '#838E94'} />
            </div>
            <div onClick={() => dispatch(setRenderType(PostRenderType.LIST))}>
              <TintIcon icon={faTableList} color={renderType === PostRenderType.LIST ? '#212121' : '#838E94'} />
            </div>
          </div>
        ) : null}
        <div className='tint-editor-top-bar__filter'>
          <TintEditorTopBarFilter
            modalDOMDestination={modalDOMDestination}
            onModalStateChange={isOpen => {
              setFilterModalVisibility(isOpen);
              onFilterModalOpen(isOpen);
            }}
            isSettingsSidebarExpanded={isSettingsSidebarExpanded}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(TintEditorTopBar);
