import { useState } from 'react';

const handleResponse = response => {
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
};

export const useFormBuilderCountries = ({ item, formId, locale, onPending } = {}) => {
  const elements = item?.elements;
  const divs = elements?.filter(el => el?.nodeName === 'div');
  const addressElements = divs?.map(div => div?.elements);
  const inputs = addressElements?.map(arr => arr?.find(el => el?.nodeName === 'input' || el?.nodeName === 'select'));
  const countrySelect = inputs?.find(el => el?.id?.includes('country'));

  const [countryDetails, setCountryDetails] = useState({});
  const [countriesList, setCountriesList] = useState(countrySelect?.elements);

  const getCountryDetails = async ({ countryId }) => {
    onPending(true, countrySelect.name);

    try {
      const response = await fetch(
        `https://api.tintup.com/v2/countries/${countryId}?include=subdivisions${locale ? `&locale=${locale}` : ''}`
      );
      handleResponse(response);
      const data = await response.json();
      return data;
    } catch (err) {
      throw new Error(`Cannot fetch country details: ${err.message}`);
    } finally {
      onPending(false, countrySelect.name);
    }
  };
  const getCountriesLocales = async ({ countriesIds }) => {
    onPending(true, countrySelect.name);
    try {
      const response = await fetch(`https://api.tintup.com/v2/countries?filter[id]=${countriesIds}&locale=${locale}`);
      handleResponse(response);
      const data = await response.json();
      return data.data;
    } catch (err) {
      throw new Error(`Cannot fetch countries locales: ${err.message}`);
    } finally {
      onPending(false, countrySelect.name);
    }
  };
  const getCountriesList = async () => {
    try {
      const response = await fetch('https://api.tintup.com/v2/countries');
      handleResponse(response);
      const data = await response.json();
      return data.data;
    } catch (err) {
      throw new Error(`Cannot fetch countries list: ${err.message}`);
    }
  };

  const updateCountryDetails = async (countryId = 'en_US') => {
    const details = await getCountryDetails({ countryId: countryId });
    const subdivisions =
      details?.included
        ?.filter(el => el?.type === 'subdivision')
        ?.map(subdivision => {
          return {
            value: subdivision?.id,
            name: subdivision?.attributes?.name,
          };
        }) || [];
    setCountryDetails({ ...details, subdivisionsList: [...subdivisions] });
  };

  const updateCountriesNames = async ({ countriesIds }) => {
    const localizedCountries = await getCountriesLocales({ countriesIds });
    setCountriesList(cl =>
      cl.map(country => {
        const translatedName = localizedCountries?.find(localizedCountry => localizedCountry?.id === country.value)
          ?.attributes?.name;
        return { ...country, name: translatedName };
      })
    );
  };

  const onCountryChange = ({ e, localizeCountryNames }) => {
    const formDiv = document.querySelector(`[data-form-id="${formId}"] div`);
    if (formDiv) {
      const selectedCountry = e?.target?.selectedOptions?.[0];
      const countryInputHTML = formDiv?.shadowRoot?.getElementById(countrySelect.id);

      if (localizeCountryNames) {
        const countriesIds = [...countrySelect.elements].map(country => country.value);
        updateCountriesNames({ countriesIds });
      }

      updateCountryDetails(selectedCountry ? selectedCountry.value : countryInputHTML.selectedOptions[0].value);
    }
  };

  return {
    localizedCountriesList: countriesList,
    countryDetails,
    onCountryChange,
    getCountryDetails,
    getCountriesList,
    updateCountryDetails,
  };
};
