import React, { useEffect, useRef, useState } from 'react';
import './tint-editor.container.sass';
import TintEditorSettingsSidebar from '../../components/tint-editor/settings-sidebar/tint-editor-settings-sidebar';
import TintEditorTopBar from '../../components/tint-editor/top-bar/tint-editor-top-bar';
import PaginationBar from '../../components/pagination-bar/pagination-bar';
import { useLocation, useParams } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { getCTAS, getTint } from '../../actions/tint-editor.fat-actions';
import TintIcon from '../../../../components/interface/icon/icon';
import { getGlobalPosts, getPosts, getPostsAggregations, getPostsFromURL } from '../../actions/posts.fat-actions';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import ECommerceContainer from '../../../ecommerce/containers/ecommerce/ecommerce.container';
import SocialFeedsSidebar from '../../components/tint-editor/sidebars/social-feed-sidebar/social-feed-sidebar';
import { clearPostState, sortPostsBy } from '../../actions/posts.actions';
import { FeedQuery } from '../../components/tint-editor/top-bar-navigation/tint-editor-top-navigation';
import { PageInitService } from '../../../../services/page-init/page-init.service';

import {
  clearTintEditorState,
  closeEcommerceModal,
  closeVisualSearchModal,
  toggleCTASidebar,
} from '../../actions/tint-editor.actions';
import TintEditorModal from '../../components/tint-editor/modal/tint-editor-modal';
import TintEditorManageCtaSidebarWrapper from '../../components/tint-editor/sidebars/mange-cta-sidebar-wrapper/tint-editor-manage-cta-sidebar-wrapper';
import { TintEditorConfig } from '../../utils/tint-editor.config';
import { toast } from 'react-toastify';
import { getSocialConnections } from '../../components/tint-editor/sidebars/social-feed-sidebar/actions/social-feeds.fat-actions';
import { useQuery } from '../../../../components/hooks/use-query/use-query';
import TintEditorVisualSearchSidebar from '../../components/tint-editor/sidebars/visual-search/tint-editor-visual-search-sidebar';
import { UpgradeNote } from '../../components/upgrade-note/upgrade-note';
import session, { getPlan } from '../../../../reducers/session';
import { clearSocialFeedState } from '../../components/tint-editor/sidebars/social-feed-sidebar/actions/social-feeds.actions';
import useSavedFilters from '../../../../components/hooks/use-saved-filters/use-saved-filters';
import TintEditorManageCustomPostSidebar, {
  customPostSidebar,
} from '../../components/tint-editor/sidebars/manage-custom-post/tint-editor-manage-custom-post.sidebar';
import { FeedList } from '../../components/tint-editor/feed-list/feed-list';
import { getSocialFeedLength } from '../../components/tint-editor/sidebars/social-feed-sidebar/reducers/social-feeds.selector';
import { Config } from '../../../../utils/config';
import { useCardLoadersCount } from '../../../../components/hooks/use-card-loaders-count/use-card-loaders-count';
import { PostRenderType } from '../../reducers/posts.reducer';
import { FeedListHorizontal } from '../../components/tint-editor/feed-list-horizontal/feed-list-horizontal';
import { isSidebarOpen, getSidebarData, isModalOpen } from '../../../../reducers/ui/ui.selector';
import { closeSidebar, closeModal, setMediaData } from '../../../../actions/ui/ui.actions';
import { socialFeedSidebar } from '../../components/social-feed-card/social-feed-card';
import { assetModalId, AssetsModal } from '../../../social-publishing/components/assets/assets-modal/assets-modal';
import { unselectAllAssets } from '../../../asset-manager/actions/asset-manager.actions';
import { getMappedPosts } from '../../selectors/tint-editor-posts.selector';
import { getSortKey } from '../../../../components/interface/dropdown/lists/sort-list-radio/dropdown-sort-list-radio-list';

export const tintEditorNavigationStorageKey = 'tint-editor-navigation';

export const TintEditorViewState = {
  ALL: 'all',
  PUBLIC: 'public',
  PRIVATE: 'private',
  TRASH: 'trash',
};

const startNavbarAnimationHeight = 124;

const TintEditorContainer = () => {
  const location = useLocation();
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const listScrollRef = useRef(null);
  const { tintId } = useParams();
  const isGlobalPageState = tintId === TintEditorConfig.routePaths.global.tintId;
  const plan = useSelector(getPlan);
  const [isTrialNoteVisible, setTrialNoteVisibility] = useState(plan?.attributes?.trial);
  const [isFetchingData, setFetchingData] = useState(!isGlobalPageState);
  const dispatch = useDispatch();
  const modal = useSelector(state => state.tintEditor.modal);
  const teamId = useSelector(state => state.session.data['team_id']);
  const session = useSelector(state => state.session);
  const isNavigationVisible = localStorage.getItem(tintEditorNavigationStorageKey)
    ? JSON.parse(localStorage.getItem(tintEditorNavigationStorageKey))
    : true;
  const [isSettingsSidebarExpanded, setIsSettingsSidebarExpanded] = useState(isNavigationVisible);
  const ecommercePost = useSelector(state => state.tintEditor.modalEcommerce);
  const tintEditor = useSelector(state => state?.tintEditor);
  const posts = useSelector(getMappedPosts);
  const selectedPosts = useSelector(state => state.tintEditorPosts.selectedPosts);
  const arePostsFetching = useSelector(state => state?.tintEditorPosts.isFetching);
  const socialFeedLength = useSelector(getSocialFeedLength);
  const links = useSelector(state => state?.tintEditorPosts.links);
  const pageSize = useSelector(state => state.tintEditorPosts.pageSize || 20);
  const currentPageStart = useSelector(state => state.tintEditorPosts.currentPageStart);
  const renderType = useSelector(state => state.tintEditorPosts.renderType);
  const currentPageEnd = useSelector(state => state.tintEditorPosts.currentPageEnd);
  const selectedFilters = useSelector(state => state?.tintEditorPosts.selectedFilters);
  const isLastPage = useSelector(state => state.tintEditorPosts.isLastPage);
  const isSocialFeedSidebarOpen = useSelector(state => isSidebarOpen(state, socialFeedSidebar));
  const isAssetPreviewModalVisible = useSelector(state => isModalOpen(state, assetModalId));
  const isCustomPostSidebarOpen = useSelector(state => isSidebarOpen(state, customPostSidebar));
  const sidebarData = useSelector(state => getSidebarData(state, customPostSidebar));

  const [scrollingParameters, setScrollingParameters] = useState({
    previousScroll: 0,
    isScrollingDown: false,
  });
  const isAnyFilterSelected =
    (selectedFilters && Object.values(selectedFilters).some(el => el?.length > 0)) || selectedFilters?.published_at;
  const [isSelectedMenuVisible, setIsSelectedMenuVisible] = useState(selectedPosts?.length > 0);
  const minCardWidthOptions = {
    desktop: 340,
    mobile: 260,
  };
  const listRef = useRef();
  const loadersNumber = useCardLoadersCount({
    minCardWidthOptions: minCardWidthOptions,
    ref: listRef,
  });
  const queryParams = useQuery();
  const tintEditorState = queryParams.get(TintEditorConfig.urlSearchParamState);
  const [isFilterModalOpen, setFilterModalVisibility] = useState(false);

  const onToggleSidebar = isVisible => {
    setIsSettingsSidebarExpanded(isVisible);
    localStorage.setItem(tintEditorNavigationStorageKey, isVisible);
  };

  const defineNavbarClass = () => {
    return scrollingParameters.isScrollingDown
      ? 'tint-editor-navbar__background-filler--collapsed'
      : isAnyFilterSelected
      ? 'tint-editor-navbar__background-filler--filter-selected'
      : '';
  };

  const defineFeedClass = () => {
    return isAnyFilterSelected
      ? isSelectedMenuVisible
        ? 'tint-editor-container__feed--post-and-filter-selected'
        : 'tint-editor-container__feed--filter-selected'
      : isSelectedMenuVisible
      ? 'tint-editor-container__feed--post-selected'
      : '';
  };

  const getPostStatusState = () => {
    switch (tintEditorState) {
      case TintEditorViewState.PRIVATE:
        return FeedQuery.PRIVATE;

      case TintEditorViewState.PUBLIC:
        return FeedQuery.PUBLIC;

      case TintEditorViewState.TRASH:
        return FeedQuery.TRASH;

      case TintEditorViewState.ALL:
      default:
        return FeedQuery.ALL;
    }
  };

  const sortByFilters = useSelector(state => state?.tintEditorPosts?.sortPostsBy);

  const getSortByFilters = () => {
    const sortBy = localStorage.getItem(getSortKey(session?.data?.currentTeam));
    return { ...sortByFilters, sortBy };
  };

  useEffect(() => {
    const postsRequest = { teamId, pageSize, sortBy: getSortByFilters(), filters: selectedFilters };
    postsRequest.tintId = tintId;
    dispatch(getPostsAggregations(postsRequest));
  }, []);

  useEffect(() => {
    if (socialFeedLength !== 0) {
      getTintEditorPosts();
    }
  }, [dispatch, teamId, pageSize, isGlobalPageState, sortByFilters, selectedFilters, socialFeedLength, renderType]);

  const getTintEditorPosts = () => {
    const postsRequest = { teamId, pageSize, sortBy: getSortByFilters(), filters: selectedFilters, renderType };
    if (!sortByFilters?.status) {
      dispatch(sortPostsBy(getPostStatusState(tintEditorState), 'status'));
      return;
    }

    if (getSortByFilters()?.status && isGlobalPageState) {
      dispatch(getGlobalPosts(postsRequest)).catch(() => toast.error('Cannot get posts, try again later'));
    } else {
      postsRequest.tintId = tintId;
      dispatch(getPosts(postsRequest)).catch(() => {
        toast.error('Cannot get posts, try again later');
      });
    }
  };

  !isGlobalPageState && useSavedFilters({ tintId });

  useEffect(() => {
    if (!isGlobalPageState) {
      const requests = [
        dispatch(getTint(tintId)),
        dispatch(getCTAS(tintId)),
        dispatch(getSocialConnections({ url: `/social_feeds?include=tint&filter[tint_id]=${tintId}&page[size]=500` })),
      ];

      Promise.all(requests).finally(() => setFetchingData(false));
    }

    return () => {
      dispatch(clearPostState());
      dispatch(clearTintEditorState());
      dispatch(clearSocialFeedState());
      dispatch(closeSidebar(socialFeedSidebar));
      dispatch(closeSidebar(customPostSidebar));
    };
  }, [dispatch, tintId]);

  useEffect(() => {
    if (tintEditor.data) {
      const pageTitleHeader = isGlobalPageState
        ? TintEditorConfig.routePaths.global.title
        : tintEditor?.data?.attributes?.name;
      PageInitService.configure({
        pageTitle: pageTitleHeader,
        pageHeader: pageTitleHeader,
      });
    }
  }, [tintEditor.data]);

  useEffect(() => {
    if (location.pathname.includes(Config.routePaths.globalModeration.plainPath)) {
      PageInitService.configure({
        pageTitle: Config.routePaths.globalModeration.title,
        pageHeader: Config.routePaths.globalModeration.title,
      });
    }
    return () => {
      dispatch(sortPostsBy(undefined, 'status'));
    };
  }, []);

  const onScrollHandler = e => {
    const currentScrollTop = e.currentTarget.scrollTop;
    if (e.currentTarget.scrollTop > startNavbarAnimationHeight) {
      setScrollingParameters(
        currentScrollTop <= scrollingParameters.previousScroll
          ? { previousScroll: currentScrollTop, isScrollingDown: false }
          : { previousScroll: currentScrollTop, isScrollingDown: true }
      );
    } else {
      e.currentTarget.scrollTop < startNavbarAnimationHeight &&
        scrollingParameters.isScrollingDown &&
        setScrollingParameters({ previousScroll: currentScrollTop, isScrollingDown: false });
    }
  };

  const onCloseAssetModal = id => {
    dispatch(unselectAllAssets());
    dispatch(closeModal(id));
  };

  const onUGCAssetConfirm = data => {
    const mappedAssets = data?.map(asset => ({
      id: asset?.relationships?.media?.data?.id,
      type: asset.attributes?.mime_type,
      preview: asset.attributes?.thumbnail_url,
      poster: asset.attributes?.thumbnail_url,
      size: asset.attributes?.size,
      resolution: asset.attributes?.dimensions,
      duration: asset.attributes?.duration,
    }));

    const currentMedia = sidebarData?.media || [];
    dispatch(setMediaData({ id: customPostSidebar, data: [...new Set([...currentMedia, ...mappedAssets])] }));
    onCloseAssetModal(assetModalId);
  };

  return (
    <div className='tint-editor-container' ref={containerRef}>
      {!isGlobalPageState && (
        <div
          className={`tint-editor-container__navigation ${
            !isGlobalPageState && isSettingsSidebarExpanded
              ? 'tint-editor-container__navigation--expanded'
              : 'tint-editor-container__navigation--collapsed'
          }`}>
          <TintEditorSettingsSidebar
            isSettingsSidebarExpanded={isSettingsSidebarExpanded}
            onToggleSidebar={onToggleSidebar}
          />
        </div>
      )}
      <div
        className={`tint-editor-container__content ${
          !isGlobalPageState && isSettingsSidebarExpanded
            ? 'tint-editor-container__content--expanded'
            : 'tint-editor-container__content--collapsed'
        } ${isFilterModalOpen ? 'tint-editor-container__content--modal-open' : ''}`}
        id='tint-editor-container-content'
        ref={contentRef}>
        <div className='tint-editor-navbar'>
          <div className={`tint-editor-navbar__background-filler ${defineNavbarClass()} `} />
          {
            <TintEditorTopBar
              isGlobalPageState={isGlobalPageState}
              onToggleClick={() => onToggleSidebar(true)}
              modalDOMDestination={contentRef.current}
              onFilterModalOpen={isOpen => setFilterModalVisibility(isOpen)}
              isScrollingDown={scrollingParameters.isScrollingDown}
              isAnyFilterSelected={isAnyFilterSelected}
              isSettingsSidebarExpanded={isSettingsSidebarExpanded}
            />
          }
          <PaginationBar
            scrollRef={listScrollRef}
            getPosts={getTintEditorPosts}
            isScrollingDown={scrollingParameters.isScrollingDown}
            isAnyFilterSelected={isAnyFilterSelected}
            isSelectedMenuVisible={isSelectedMenuVisible}
            setIsSelectedMenuVisible={setIsSelectedMenuVisible}
          />
        </div>
        {isTrialNoteVisible && <UpgradeNote setTrialNoteVisibility={setTrialNoteVisibility} />}
        <div className={`tint-editor-container__feed ${defineFeedClass()}`} onScroll={onScrollHandler} ref={listRef}>
          {renderType === PostRenderType.GRID ? (
            <FeedList
              posts={posts}
              loadersNumber={loadersNumber}
              listRef={listRef}
              isFetchingData={isFetchingData}
              arePostsFetching={arePostsFetching}
              isGlobalPageState={isGlobalPageState}
              isSettingsSidebarExpanded={isSettingsSidebarExpanded}
            />
          ) : (
            <FeedListHorizontal
              posts={posts}
              getTintEditorPosts={getTintEditorPosts}
              loadersNumber={loadersNumber}
              listRef={listRef}
              isFetchingData={isFetchingData}
              arePostsFetching={arePostsFetching}
              isGlobalPageState={isGlobalPageState}
              isSettingsSidebarExpanded={isSettingsSidebarExpanded}
            />
          )}
          <div ref={listScrollRef} style={{ position: 'absolute', top: '-100px', left: 0 }}></div>
        </div>
        {posts?.length > 0 && (
          <div className='tint-editor-container__pagination-buttons-wrapper'>
            <button
              disabled={currentPageStart === 1}
              onClick={() => {
                listScrollRef.current.scrollIntoView();
                dispatch(getPostsFromURL(links?.prev, currentPageStart - pageSize));
              }}
              className={`tint-editor-container__pagination-button ${
                currentPageStart === 1 ? 'tint-editor-container__pagination-button--disabled' : ''
              }`}>
              <TintIcon icon={faChevronLeft} fontSize='16px' />
              <span className='tint-editor-container__button-text'>Previous</span>
            </button>
            <button
              disabled={isLastPage}
              className={`tint-editor-container__pagination-button ${
                currentPageEnd < pageSize ? 'tint-editor-container__pagination-button--disabled' : ''
              }`}
              onClick={() => {
                if (currentPageEnd < pageSize) {
                  return;
                }
                listScrollRef.current.scrollIntoView();
                dispatch(getPostsFromURL(links?.next, currentPageStart + pageSize));
              }}>
              <span className='tint-editor-container__button-text'>Next</span>
              <TintIcon icon={faChevronRight} fontSize='16px' />
            </button>
          </div>
        )}
        {ecommercePost && (
          <ECommerceContainer
            tintId={tintId}
            newEditorPost={ecommercePost}
            onClose={() => dispatch(closeEcommerceModal())}
          />
        )}
        {isSocialFeedSidebarOpen && <SocialFeedsSidebar tintId={tintId} />}
      </div>
      {modal && <TintEditorModal isOpen={modal} />}
      {tintEditor?.CTAModal?.isCTASidebarOpen && (
        <TintEditorManageCtaSidebarWrapper
          tintId={tintId}
          CTAModalDetails={tintEditor?.CTAModal}
          isVisible={tintEditor?.CTAModal.isCTASidebarOpen}
          onClose={() => dispatch(toggleCTASidebar(!tintEditor?.CTAModal.isCTASidebarOpen))}
        />
      )}
      {tintEditor?.modalVisualSearch && (
        <TintEditorVisualSearchSidebar
          isOpen={tintEditor.modalVisualSearch}
          onClose={() => dispatch(closeVisualSearchModal())}
        />
      )}
      {isCustomPostSidebarOpen && (
        <>
          <TintEditorManageCustomPostSidebar
            isVisible={!!isCustomPostSidebarOpen}
            onClose={() => dispatch(closeSidebar(customPostSidebar))}
            data={[]}
          />
          {isAssetPreviewModalVisible && (
            <AssetsModal
              isOpen={isAssetPreviewModalVisible}
              isAssetPreviewModalVisible={isAssetPreviewModalVisible}
              onClose={onCloseAssetModal}
              onSubmit={onUGCAssetConfirm}
              modalDOMDestination={containerRef?.current}
            />
          )}
        </>
      )}
    </div>
  );
};

export default TintEditorContainer;
