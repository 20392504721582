import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const fileUpload = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FILE_UPLOAD_FILE]: {
    display: "grid",
    gap: "0 10px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FILE_UPLOAD_INPUT]: {
    position: "relative",
    opacity: 1,
    "min-height": "80px",
    padding: 0,
    "white-space": "break-spaces",
    "border-radius": "6px",
    border: "none"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FILE_UPLOAD_INPUT_BEFORE]: {
    cursor: "pointer",
    content: "attr(data-content-text)",
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    "align-items": "center",
    "justify-content": "center",
    "background-color": "#f6f7f8",
    "text-decoration": "underline",
    width: "calc(100% - 20px)",
    height: "100%",
    padding: "0 10px",
    "text-align": "center"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FILE_UPLOAD_UL]: {
    padding: 0
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FILE_UPLOAD_LI]: {
    display: "grid",
    gap: "0 10px",
    "grid-template": '"name action" auto "progress progress" auto / 1fr auto',
    border: "1px solid #DFDFDF",
    padding: "10px",
    margin: "20px 0",
    "border-radius": "6px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FILE_UPLOAD_FILE_NAME]: {
    "grid-area": "name"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FILE_UPLOAD_OUTPUT_LABEL]: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: "1px",
    margin: "-1px",
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    width: "1px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FILE_UPLOAD_PROGRESS]: {
    width: "100%",
    "grid-area": "progress"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FILE_UPLOAD_BUTTON]: {
    outline: "0",
    border: "0",
    cursor: "pointer",
    color: "transparent",
    "text-indent": "-9999px",
    width: "20px",
    height: "20px",
    "grid-area": "action",
    background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath d='M13 3A1.0001 1.0001 0 0 0 11.986328 4L6 4A1.0001 1.0001 0 1 0 6 6L24 6A1.0001 1.0001 0 1 0 24 4L18.013672 4A1.0001 1.0001 0 0 0 17 3L13 3zM6 8L6 24C6 25.105 6.895 26 8 26L22 26C23.105 26 24 25.105 24 24L24 8L6 8z' fill='%23000000'/%3E%3C/svg%3E")`
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FILE_UPLOAD_ERROR]: {
    "grid-area": "progress"
  }
};
