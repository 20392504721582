import React from 'react';
import './form-builder.form.interface.header.sass';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';
import { FormBuilderElementsMappingService } from '../../../../services/builder/form-builder.elements-mapping.service';

const FormBuilderFormInterfaceHeader = ({ item, styles, t }) => {
  if (!item) return null;

  const { h1, p } = FormBuilderElementsMappingService.getSingleElementHTMLTags(item);

  return (
    <hgroup className={`${item.class}`}>
      <h1 style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.HEADER_TITLE]}>{t(h1.nodeValue)}</h1>
      {p && <p style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.HEADER_CAPTION]}>{t(p.nodeValue)}</p>}
    </hgroup>
  );
};

FormBuilderFormInterfaceHeader.displayName = 'FormBuilderFormInterfaceHeader';
export default React.memo(FormBuilderFormInterfaceHeader);
