import { SUBMIT_BUTTON_STYLES_VARIABLES } from "./variables/submit-button";
export const submitButton = {
  [SUBMIT_BUTTON_STYLES_VARIABLES.BORDER_STYLE]: "none",
  [SUBMIT_BUTTON_STYLES_VARIABLES.BUTTON_COLOR]: "#0472EF",
  [SUBMIT_BUTTON_STYLES_VARIABLES.COLOR]: "#ffffff",
  [SUBMIT_BUTTON_STYLES_VARIABLES.BORDER_RADIUS]: "0",
  [SUBMIT_BUTTON_STYLES_VARIABLES.BORDER_WIDTH]: "",
  [SUBMIT_BUTTON_STYLES_VARIABLES.BORDER_COLOR]: "",
  [SUBMIT_BUTTON_STYLES_VARIABLES.WIDTH]: "100%",
  [SUBMIT_BUTTON_STYLES_VARIABLES.ALIGNMENT]: "left",
  [SUBMIT_BUTTON_STYLES_VARIABLES.FONT_SIZE]: "16px",
  [SUBMIT_BUTTON_STYLES_VARIABLES.HORIZONTAL_SPACING]: "12px",
  [SUBMIT_BUTTON_STYLES_VARIABLES.VERTICAL_SPACING]: "12px"
};
