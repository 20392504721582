import React from 'react';
import './dropdown-email-domains.sass';
import useFeatureAccessibility from '../../hooks/use-feature-accessibility/use-feature-accessibility';
import { Config } from '../../../utils/config';
import { filters } from '../../../feature/account-settings/utils/filter-helper/filter-helper';
import { EmailDomainsStatus } from '../../../types/status';
import { dropdownDefaultSelectField } from '../../../feature/tint-editor/components/tint-editor/sidebars/social-feed-sidebar/utils/common/common';
import ApiDropdown from '../api-dropdown';
import { getUrl } from '../api-dropdown.helpers';

export const emailDomainsDropdownId = 'emailDomains';

const createEmailDomainsAdditionalOption = {
  name: '+ Add Domain',
  value: 'add_domain',
  link: Config.routePaths.teamSettings.emailDomains.path,
};

export const DropdownEmailDomains = ({ currentElement, onChange, styles, placeholder = 'Select...' }) => {
  const { domainsEmail } = useFeatureAccessibility();
  const baseUrl = '/email_domains';

  if (!domainsEmail) return null;
  return (
    <div className='tint-email-dropdown'>
      <ApiDropdown
        placeholder={placeholder}
        fetchUrl={(url, query) =>
          getUrl({
            url,
            query,
            baseUrl: baseUrl,
            filters: [
              {
                by: filters.QUERY,
                value: query,
              },
              {
                by: filters.STATUS,
                value: EmailDomainsStatus.COMPLETE,
              },
            ],
            size: Config.defaultEmailDomainsPageSize,
          })
        }
        baseUrl={baseUrl}
        currentElement={currentElement}
        dropdownId={emailDomainsDropdownId}
        onChange={onChange}
        unselectOption={dropdownDefaultSelectField}
        additionalOption={createEmailDomainsAdditionalOption}
        styles={styles}
        fixedOption={!!createEmailDomainsAdditionalOption}
      />
    </div>
  );
};
