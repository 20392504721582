var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { ELEMENTS_CLASSES, ELEMENTS_ID_PHRASES } from "../../utils/form-builder.types";
import { hasPhrase } from "../../helpers/helpers";
export const communityConsentMappingKeys = {
  EMAIL: "email",
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  COUNTRY: "country",
  ZIP_CODE: "zip_code"
};
const mapSingleElementByItsHTMLTags = (element) => {
  const mappedElement = {
    class: element == null ? void 0 : element.class,
    tags: {}
  };
  const findAllNodeNames = (element2) => {
    if (element2 == null ? void 0 : element2.nodeName) {
      if (Array.isArray(mappedElement.tags[element2.nodeName])) {
        mappedElement.tags[element2.nodeName].push(element2);
      } else {
        mappedElement.tags[element2.nodeName] = [element2];
      }
    }
    if (element2 == null ? void 0 : element2.elements) {
      for (const nestedElement of element2.elements) {
        findAllNodeNames(nestedElement);
      }
    }
  };
  findAllNodeNames(element);
  return mappedElement;
};
const mapElementsByTheirHTMLTags = (elements) => {
  return elements.map((element) => {
    return mapSingleElementByItsHTMLTags(element);
  });
};
const getAllSingleElementHTMLTags = (element) => {
  var _a;
  return (_a = mapSingleElementByItsHTMLTags(element)) == null ? void 0 : _a.tags;
};
const getSingleElementHTMLTags = (element) => {
  var _a;
  const tags = (_a = mapSingleElementByItsHTMLTags(element)) == null ? void 0 : _a.tags;
  return Object.keys(tags).reduce((acc, key) => {
    acc[key] = tags[key][0] || "";
    return acc;
  }, {});
};
const formatInputForDropdown = (input) => {
  var _a, _b;
  return { name: (_a = input == null ? void 0 : input.name) == null ? void 0 : _a.replace("[]", ""), value: (_b = input == null ? void 0 : input.name) == null ? void 0 : _b.replace("[]", ""), id: input == null ? void 0 : input.id };
};
const mapElementIntoInputsList = (element) => {
  var _a, _b, _c;
  return [
    ...((_a = element == null ? void 0 : element.tags) == null ? void 0 : _a.input) || [],
    ...((_b = element == null ? void 0 : element.tags) == null ? void 0 : _b.textarea) || [],
    ...((_c = element == null ? void 0 : element.tags) == null ? void 0 : _c.select) || []
  ];
};
const convertFieldsToDropdownOptions = (fields) => {
  const newObj = {};
  for (const key in fields) {
    newObj[key] = fields[key].map(formatInputForDropdown);
  }
  return newObj;
};
const extractFieldsFromElements = (mappedElements) => {
  const fields = {};
  const elements = Object.values(ELEMENTS_CLASSES);
  for (const className of elements) {
    const inputs = mappedElements.flatMap(mapElementIntoInputsList);
    const idPhrases = ELEMENTS_ID_PHRASES[className];
    const uniqueInputs = {};
    for (const input of inputs) {
      uniqueInputs[input.name] = input;
    }
    const uniqueInputsArr = Object.values(uniqueInputs);
    if (typeof idPhrases === "object") {
      for (const key in idPhrases) {
        fields[idPhrases[key]] = uniqueInputsArr.filter((el) => hasPhrase(el, idPhrases[key]));
      }
    } else {
      if (className === ELEMENTS_CLASSES.CONSENT) {
        fields[idPhrases] = uniqueInputsArr.filter((el) => hasPhrase(el, idPhrases, "consent_input"));
      } else {
        fields[idPhrases] = uniqueInputsArr.filter((el) => hasPhrase(el, idPhrases));
      }
    }
  }
  return fields;
};
const getSelectedFields = (dataMappingObj) => {
  const selectedFields = {};
  const mappingKeys = Object.values(ELEMENTS_ID_PHRASES).reduce((acc, element) => {
    if (typeof element === "object") {
      return __spreadValues(__spreadValues({}, acc), element);
    } else {
      return __spreadProps(__spreadValues({}, acc), { [element.toUpperCase()]: element });
    }
  }, {});
  for (const key in mappingKeys) {
    const mappingKey = mappingKeys[key];
    if (mappingKey === ELEMENTS_ID_PHRASES[ELEMENTS_CLASSES.ADDRESS].POSTAL_CODE) {
      selectedFields[mappingKey] = dataMappingObj[communityConsentMappingKeys.ZIP_CODE] || "";
    } else {
      selectedFields[mappingKey] = dataMappingObj[mappingKey] || "";
    }
  }
  return selectedFields;
};
export const FormBuilderElementsMappingService = {
  mapElementsByTheirHTMLTags,
  mapSingleElementByItsHTMLTags,
  getAllSingleElementHTMLTags,
  getSingleElementHTMLTags,
  getSelectedFields,
  extractFieldsFromElements,
  convertFieldsToDropdownOptions
};
