import React, { useState, useEffect, useRef } from 'react';
import './form-builder.form.interface.file-upload.sass';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';
import Logger from '../../../../../../services/logger/logger';
import { errorTypes, useFormBuilderUpload } from '../../../../hooks/use-form-builder-upload';
import { useDataTranslationsValue } from '../../../../hooks/use-data-translations-value';
import { FormBuilderFormHint } from '../common/hint/form-builder-form.hint';
import { FormBuilderElementsMappingService } from '../../../../services/builder/form-builder.elements-mapping.service';

const FormBuilderFormInterfaceFileUpload = ({ item, styles, teamId, formId, apiUrl, onPending, analyticForm, t }) => {
  if (!item) return null;

  const [, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const { input, label, p: hint } = FormBuilderElementsMappingService.getSingleElementHTMLTags(item);

  const {
    uploading: uploadingLabel,
    remove: removeLabel,
    invalid: invalidLabel,
    failed: failedLabel,
    tooShortVideo: tooShortVideoLabel,
    tooLongVideo: tooLongVideoLabel,
  } = useDataTranslationsValue({
    element: input,
    key: 'data-locale',
  });

  const { onUploadFiles, files, isAnyComponentsPending, onRemoveFile } = useFormBuilderUpload({
    onUploadError: err => Logger.error(err),
    multiple: input?.multiple,
    onPending: isPending => onPending(isPending, input.name),
    url: `${apiUrl}/v2/teams/${teamId}/forms/${formId}/form_submissions/upload/${input.name.replace('[]', '')}`,
  });

  useEffect(() => {
    onPending(isAnyComponentsPending, input.name);
  }, [isAnyComponentsPending]);

  const handleDragIn = e => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  };

  const handleDragOut = e => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = e => {
    e.preventDefault();
    e.stopPropagation();
    e.persist();
    setIsDragging(false);

    const droppedFiles = e?.dataTransfer?.files;
    fileInputRef.current.files = droppedFiles;
    onUploadFiles(droppedFiles);
  };

  const onFocus = e => {
    e.target.style.outlineColor = styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS]?.outlineColor;
    analyticForm?.trackInputFocused(input.name);
  };

  const onBlur = () => analyticForm?.trackInputBlurred(input.name);

  const dataTranslationsAttribute = JSON.stringify({
    uploading: t(uploadingLabel),
    remove: t(removeLabel),
    invalid: t(invalidLabel),
    failed: t(failedLabel),
  });

  const handleVideoValidation = file => {
    return new Promise(resolve => {
      if (!file.type.startsWith('video/')) {
        resolve(null);
        return;
      }

      const video = document.createElement('video');
      const objectURL = URL.createObjectURL(file);

      video.src = objectURL;
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        URL.revokeObjectURL(objectURL);
        const duration = video.duration;

        const minLength = input['data-validation']?.min_duration || 0;
        const maxLength = input['data-validation']?.max_duration || 10000000;

        if (duration < minLength) {
          resolve(
            tooShortVideoLabel
              ? t(tooShortVideoLabel)
              : `Video ${file.name} is too short. Minimum length is ${minLength} seconds.`
          );
        } else if (duration > maxLength) {
          resolve(
            tooLongVideoLabel
              ? t(tooLongVideoLabel)
              : `Video ${file.name} is too long. Maximum length is ${maxLength} seconds.`
          );
        } else {
          resolve(null);
        }
      };

      video.onerror = () => {
        URL.revokeObjectURL(objectURL);
        resolve(`Unable to load video file ${file.name}.`);
      };
    });
  };

  const onFileChange = async files => {
    const fileInputElement = fileInputRef.current;
    const validationErrors = [];

    for (const file of files) {
      const customMessage = await handleVideoValidation(file);

      if (customMessage) {
        validationErrors.push(customMessage);
      }
    }

    if (validationErrors.length > 0) {
      fileInputElement.setCustomValidity(validationErrors.join('\n'));
    } else {
      fileInputElement.setCustomValidity('');
      onUploadFiles(files);
    }

    fileInputElement.reportValidity();
  };

  return (
    <div
      className={item.class}
      onDrop={handleDrop}
      onDragEnter={handleDragIn}
      onDragLeave={handleDragOut}
      onDragOver={handleDragOver}
      style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS]}
      //style={{ border: isDragging ? '2px dashed #ccc' : undefined }} // Optional style for drag state
    >
      <label style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.LABEL]} htmlFor={input.name}>
        {t(label.nodeValue)}
      </label>
      <input
        ref={fileInputRef}
        id={input.id}
        name={input.name}
        style={{
          ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT],
          border: 'none',
        }}
        data-content-text={t(input.nodeValue)}
        required={input.required}
        onFocus={onFocus}
        onBlur={onBlur}
        accept={input.accept}
        multiple={input.multiple}
        type='file'
        onChange={e => onFileChange(e.target.files)}
        aria-describedby={input['aria-describedby']}
      />

      {files.length > 0 && (
        <output>
          <ul>
            {files.map((file, index) => {
              return (
                <li
                  className='input-file__file'
                  aria-invalid={file.error ? true : false}
                  key={`${file.name}-${index}`}
                  style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.FILE_UPLOAD_FILE] }}>
                  {!file.error && file.id && (
                    <input
                      key={`${file.id}`}
                      type='hidden'
                      id={`${input.id}_${index}`}
                      name={input.name}
                      value={file.id}
                    />
                  )}

                  <div
                    className='input-file__file-name'
                    style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.FILE_UPLOAD_FILE_NAME] }}>
                    {file.name}
                  </div>

                  {file.error && (
                    <div
                      className='input-file__file-error'
                      style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.FILE_UPLOAD_ERROR] }}>
                      {t(file.error === errorTypes.failed ? failedLabel : invalidLabel, { name: file.name })}
                    </div>
                  )}
                  {!file.error && file.progress !== 100 && (
                    <progress
                      id={`${input.id}_${index}`}
                      max='100'
                      value={file.progress}
                      aria-label={t(uploadingLabel, { name: file.name }) || ''}
                      style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.FILE_UPLOAD_PROGRESS] }}
                    />
                  )}

                  <button
                    style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.FILE_UPLOAD_BUTTON] }}
                    className='input-file__file-button'
                    onClick={() => {
                      onRemoveFile(file.id);
                      if (input.multiple) {
                        if (files.length === 1) {
                          fileInputRef.current.value = '';
                        }
                      } else {
                        fileInputRef.current.value = '';
                      }
                    }}>
                    {t(removeLabel, { name: file.name }) || ''}
                  </button>
                </li>
              );
            })}
          </ul>
        </output>
      )}
      <FormBuilderFormHint
        item={t(hint?.nodeValue)}
        id={hint?.id}
        style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.HINT]}
      />
    </div>
  );
};

FormBuilderFormInterfaceFileUpload.displayName = 'FormBuilderFormInterfaceFileUpload';
export default React.memo(FormBuilderFormInterfaceFileUpload);
