import { useSelector } from "react-redux";
import { FormBuilderElementsMappingService } from "../services/builder/form-builder.elements-mapping.service";
import { useMemo } from "react";
export const useDropdownFields = (selector) => {
  const elements = useSelector(selector);
  const {
    mapElementsByTheirHTMLTags,
    extractFieldsFromElements,
    convertFieldsToDropdownOptions
  } = FormBuilderElementsMappingService;
  const mappedElements = mapElementsByTheirHTMLTags(elements);
  const mappedAllFields = convertFieldsToDropdownOptions(extractFieldsFromElements(mappedElements));
  return useMemo(() => mappedAllFields, [mappedAllFields]);
};
