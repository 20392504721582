import {
  getAssetsError,
  getAssetsStart,
  getAssetsSuccess,
  updateAssetTagsError,
  updateAssetTagsStart,
  updateAssetTagsSuccess,
  updateAssetStatusError,
  updateAssetStatusStart,
  updateAssetStatusSuccess,
  updateAssetError,
  updateAssetStart,
  updateAssetSuccess,
  postMarketingIntegrationsAssetRequestStart,
  postMarketingIntegrationsAssetRequestError,
  postMarketingIntegrationsAssetRequestSuccess,
  getMarketingIntegrationsAssetRequestStart,
  getMarketingIntegrationsAssetRequestError,
  getMarketingIntegrationsAssetRequestSuccess,
  postAssetsStart,
  postAssetsSuccess,
  postAssetsError,
  deleteAssetsError,
  deleteAssetsStart,
  deleteAssetsSuccess,
  downloadAssetsStart,
  downloadAssetsSuccess,
  downloadAssetsError,
  getAssetDownloadStatusStart,
  getAssetDownloadStatusSuccess,
  getAssetDownloadStatusError,
  updateBulkTagStart,
  updateBulkTagSuccess,
  updateBulkTagError,
  updateAssetsToCollectionsStart,
  updateAssetsToCollectionsSuccess,
  updateAssetsToCollectionsError,
} from './asset-manager.actions';
import { Config } from '../../../utils/config';
import { ApiService } from '../../../services/api-service/api-service';

export const getAssetDownloadStatus = requestId => {
  return dispatch => {
    dispatch(getAssetDownloadStatusStart());
    return ApiService.get(`/assets/export/requests/${requestId}`)
      .then(res => {
        const exportDetails = {
          id: res.data.data.id,
          ...res.data.data.attributes,
        };
        return dispatch(getAssetDownloadStatusSuccess(exportDetails));
      })
      .catch(err => dispatch(getAssetDownloadStatusError(err)));
  };
};

export const downloadAssets = ({ collection_ids, ids }) => {
  const data = {
    type: 'asset_export_request',
    attributes: {
      collection_ids,
      ids,
    },
  };

  return dispatch => {
    dispatch(downloadAssetsStart());
    return ApiService.post('/assets/export/requests', { data })
      .then(data => {
        return dispatch(downloadAssetsSuccess(data));
      })
      .catch(err => {
        dispatch(downloadAssetsError(err));
        throw err;
      });
  };
};

export const updateBulkTag = ({ selectedAssets, tags }) => {
  const _data = selectedAssets.map(asset => {
    return {
      id: asset.id,
      type: 'asset',
      attributes: {
        tags: [...tags, ...asset.attributes?.tags],
      },
    };
  });

  return dispatch => {
    dispatch(updateBulkTagStart());
    return ApiService.patch('/assets', { data: _data }, { headers: ApiService.getBulkHeaders() })
      .then(res => {
        return dispatch(updateBulkTagSuccess(res.data.data));
      })
      .catch(err => {
        dispatch(updateBulkTagError(err));
        throw err;
      });
  };
};

export const postAsset = ({ filesUploaded, tags, title }) => {
  const data = [...Array(filesUploaded.length).keys()].map((e, i) => {
    return {
      type: 'asset',
      attributes: {
        tags: tags || [],
        post_text: '',
        name: title || '',
        media: filesUploaded[i]?.id,
      },
    };
  });
  return dispatch => {
    dispatch(postAssetsStart());
    return ApiService.post('/assets', { data }, { headers: ApiService.getBulkHeaders() })
      .then(res => {
        dispatch(postAssetsSuccess(res.data));
        return res.data;
      })
      .catch(err => dispatch(postAssetsError(err)));
  };
};

export const postMarketingIntegrationsAssetRequest = ({ id, postKey, postValues, folderId }) => {
  const data = postValues.map(elementId => ({
    id,
    type: 'marketing_integration_request',
    attributes: {
      [postKey]: elementId,
      folder_id: folderId,
      marketing_integration_id: id,
    },
  }));

  return dispatch => {
    dispatch(postMarketingIntegrationsAssetRequestStart());
    return ApiService.post(`/marketing_integrations/${id}/requests`, { data }, { headers: ApiService.getBulkHeaders() })
      .then(res => {
        return dispatch(postMarketingIntegrationsAssetRequestSuccess(res.data.data));
      })
      .catch(err => {
        dispatch(postMarketingIntegrationsAssetRequestError(err));
        throw err;
      });
  };
};

export const getMarketingIntegrationsAssetRequest = requestId => {
  return dispatch => {
    dispatch(getMarketingIntegrationsAssetRequestStart());
    return ApiService.get(`/marketing_integrations/requests/${requestId}`)
      .then(res => {
        const response = {
          id: res.data.data.id,
          ...res.data.data.attributes,
        };
        return dispatch(getMarketingIntegrationsAssetRequestSuccess(response));
      })
      .catch(err => dispatch(getMarketingIntegrationsAssetRequestError(err)));
  };
};

export const getAssets = ({ assetsUrl, sortOptions }) => {
  return dispatch => {
    const url =
      assetsUrl ||
      `/assets?include=collections&page[size]=${Config.defaultAssetPageSize}
      ${sortOptions ? `&${sortOptions}` : ''}`;

    dispatch(getAssetsStart());
    return ApiService.get(url)
      .then(res => {
        const assets = res.data.data.map(asset => {
          asset.isSelected = false;
          asset.collections =
            res.data.included
              ?.filter(e => e.type === 'collection')
              ?.filter(e => asset.relationships.collections.data.some(r => e.id === r.id)) || [];
          return asset;
        });

        return dispatch(
          getAssetsSuccess({
            assets,
            links: res.data.links,
            meta: res.data.meta,
          })
        );
      })
      .catch(err => dispatch(getAssetsError(err)));
  };
};

export const updateAssetsToCollections = data => {
  return dispatch => {
    dispatch(updateAssetsToCollectionsStart());
    return ApiService.patch(
      '/assets?include=collections',
      {
        data,
      },
      { headers: ApiService.getBulkHeaders() }
    )
      .then(res => {
        return dispatch(updateAssetsToCollectionsSuccess(res.data));
      })
      .catch(err => {
        dispatch(updateAssetsToCollectionsError(err));
      });
  };
};

export const deleteAssets = ({ ids }) => {
  const data = {
    data: ids.map(e => ({ id: e, type: 'asset' })),
  };

  return dispatch => {
    dispatch(deleteAssetsStart());
    return ApiService.delete('/assets', { headers: ApiService.getBulkHeaders() }, data)
      .then(() => {
        return dispatch(deleteAssetsSuccess());
      })
      .catch(err => {
        dispatch(deleteAssetsError(err));
        throw err;
      });
  };
};

export const updateAssetTags = (tags, assetId) => {
  const data = {
    data: {
      type: 'asset',
      attributes: {
        tags,
      },
    },
  };

  return dispatch => {
    dispatch(updateAssetTagsStart());
    return ApiService.put(`/assets/${assetId}`, data)
      .then(res => {
        dispatch(updateAssetTagsSuccess(res.data.data));
      })
      .catch(err => dispatch(updateAssetTagsError(err)));
  };
};

export const updateAssetStatus = (status, assetIds) => {
  const data = assetIds.map(assetId => {
    return {
      id: assetId,
      type: 'asset',
      attributes: {
        status,
      },
    };
  });

  return dispatch => {
    dispatch(updateAssetStatusStart());
    return ApiService.patch('/assets', { data: data }, { headers: ApiService.getBulkHeaders() })
      .then(res => {
        dispatch(updateAssetStatusSuccess(res.data.data));
      })
      .catch(err => dispatch(updateAssetStatusError(err)));
  };
};

export const updateAsset = (title = '', description = '', alternative_text = '', assetId) => {
  const attributes = {};
  if (title) {
    attributes.name = title;
  }

  if (description) {
    attributes.description = description;
  }

  if (alternative_text) {
    attributes.alternative_text = alternative_text;
  }
  const data = {
    data: {
      type: 'assets',
      attributes,
    },
  };

  return dispatch => {
    dispatch(updateAssetStart());
    return ApiService.patch(`/assets/${assetId}`, data)
      .then(res => {
        dispatch(updateAssetSuccess(res.data.data));
      })
      .catch(err => dispatch(updateAssetError(err)));
  };
};
