import React, { Component } from 'react';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { EditUploadDesign } from '../../../';
import { EditColorInput } from '../../../';

export class EditTimerStyles extends Component {
  render() {
    return (
      <EditModalBody title='Display icons for your accounts. Insert URLs to add social icons to the layout.'>
        <EditColorInput
          color={this.props.data.styles.timerTextColor}
          label='timer text color'
          onChange={value => {
            this.props.styleDataUpdate({ timerTextColor: value });
          }}
        />
        <EditColorInput
          color={this.props.data.styles.labelTextColor}
          label='label text color'
          onChange={value => {
            this.props.styleDataUpdate({ labelTextColor: value });
          }}
        />
        <EditColorInput
          color={this.props.data.styles.dividerColor}
          label='divider color'
          onChange={value => {
            this.props.styleDataUpdate({ dividerColor: value });
          }}
        />
        <EditUploadDesign
          dataUpdate={this.props.dataUpdate}
          styleDataUpdate={this.props.styleDataUpdate}
          data={this.props.data}
        />
      </EditModalBody>
    );
  }
}
