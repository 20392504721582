import { SocialAccountsFooterLabels } from '../helpers/helpers';
import { SidebarItemTypes } from '../../../../tint-editor/components/tint-editor/sidebars/social-feed-sidebar/utils/meta/consts';
import { SocialFeedsSource } from '../../../../../types/social-feeds';

import * as Yup from 'yup';

export const validateSchema = () => {
  return Yup.object({
    external_id: Yup.string().required('Site Key is required.'),
    access_token: Yup.string().required('Secret Key is required.'),
  });
};

export const reCaptcha = [
  {
    footer: {
      leftButtonLabel: SocialAccountsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialAccountsFooterLabels.firstStepNext,
    },
  },
  {
    footer: {
      leftButtonLabel: SocialAccountsFooterLabels.finalStepPrev,
      rightButtonLabel: SocialAccountsFooterLabels.finalStepNext,
    },
    accountSourceType: SocialFeedsSource.RE_CAPTCHA,
    header: 'Add reCAPTCHA',
    selectedOption: { id: 'account', title: 'Add reCAPTCHA' },
    formSchema: {
      account: {
        submitMapper: values => ({
          external_id: values.external_id,
          access_token: values.access_token,
        }),
        validationSchema: validateSchema(),
        inputs: [
          {
            id: 'external_id',
            backendId: 'external_id',
            label: 'SITE KEY',
            placeholder: 'Enter Site Key',
            type: SidebarItemTypes.TEXT,
          },
          {
            id: 'access_token',
            backendId: 'access_token',
            label: 'SECRET KEY',
            placeholder: 'Enter Secret Key',
            type: SidebarItemTypes.TEXT,
          },
        ],
      },
    },
  },
];
