const mapSingleElementByItsHTMLTags = element => {
  const mappedElement = {
    class: element?.class,
    tags: {},
  };

  const findAllNodeNames = element => {
    if (element?.nodeName) {
      if (Array.isArray(mappedElement.tags[element.nodeName])) {
        mappedElement.tags[element.nodeName].push(element);
      } else {
        mappedElement.tags[element.nodeName] = [element];
      }
    }
    if (element?.elements) {
      for (const nestedElement of element.elements) {
        findAllNodeNames(nestedElement);
      }
    }
  };

  findAllNodeNames(element);

  return mappedElement;
};

const mapElementsByTheirHTMLTags = elements => {
  return elements.map(element => {
    return mapSingleElementByItsHTMLTags(element);
  });
};

const getAllSingleElementHTMLTags = element => {
  return mapSingleElementByItsHTMLTags(element)?.tags;
};

const getSingleElementHTMLTags = element => {
  const tags = mapSingleElementByItsHTMLTags(element)?.tags;

  return Object.keys(tags).reduce((acc, key) => {
    acc[key] = tags[key][0] || '';
    return acc;
  }, {});
};

export const FormBuilderElementsMappingService = {
  mapElementsByTheirHTMLTags,
  mapSingleElementByItsHTMLTags,
  getAllSingleElementHTMLTags,
  getSingleElementHTMLTags,
};
