import React from 'react';
import './form-builder.form.interface.acceptance.sass';
import { useTranslation } from 'react-i18next';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';
import { FormBuilderElementsMappingService } from '../../../../services/builder/form-builder.elements-mapping.service';

const FormBuilderFormInterfaceAcceptance = ({ item, styles, analyticForm }) => {
  if (!item) return null;

  const { t } = useTranslation();

  const { input: checkbox, a, label, ['#text']: text } = FormBuilderElementsMappingService.getSingleElementHTMLTags(
    item
  );

  const onFocus = e => {
    e.target.style.outlineColor = styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS]?.outlineColor;
    analyticForm?.trackInputFocused(checkbox.name);
  };

  const onBlur = () => analyticForm?.trackInputBlurred(checkbox.name);

  const link = `<a href=${t(a.href)} target="_blank">${t(a?.nodeValue)}</a>`;

  const textHtml = t(text.nodeValue, {
    link: link,
  });

  return (
    <div
      className={`${item.class}`}
      style={{
        ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS],
        ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.ACCEPTANCE],
      }}>
      <input
        type={checkbox?.type}
        name={checkbox?.name}
        onFocus={onFocus}
        onBlur={onBlur}
        id={checkbox?.id}
        required={checkbox?.required}
        value={checkbox.value}
        style={{
          ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.ACCEPTANCE_INPUT],
        }}
      />
      <label
        htmlFor={label.for}
        style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.ACCEPTANCE_LABEL] }}
        dangerouslySetInnerHTML={{ __html: textHtml }}
      />
    </div>
  );
};

FormBuilderFormInterfaceAcceptance.displayName = 'FormBuilderFormInterfaceAcceptance';
export default React.memo(FormBuilderFormInterfaceAcceptance);
