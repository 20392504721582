import React from 'react';
import './form-builder.form.interface.success-message.sass';
import { useTranslation } from 'react-i18next';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';

const FormBuilderFormInterfaceSuccessMessage = ({ styles, item }) => {
  if (!item) return null;

  const { t } = useTranslation();
  const successMessageData = item.elements;

  return (
    <output className='div div-success-message'>
      <span
        className='div-success-message__title'
        style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SUCCESS_MESSAGE_TITLE]}>
        {t(successMessageData?.find(el => el.nodeName === 'h2')?.nodeValue)}
      </span>
      <div className='div-success-message__caption-container'>
        <span
          className='div-success-message__caption'
          style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SUCCESS_MESSAGE_CAPTION]}>
          {t(successMessageData?.find(el => el.nodeName === 'p')?.nodeValue)}
        </span>
      </div>
    </output>
  );
};

FormBuilderFormInterfaceSuccessMessage.displayName = 'FormBuilderFormInterfaceSuccessMessage';
export default React.memo(FormBuilderFormInterfaceSuccessMessage);
