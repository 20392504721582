import React, { useEffect } from 'react';
import './form-builder.form.interface.community-consent.sass';
import { useTranslation } from 'react-i18next';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';
import { FormBuilderElementsMappingService } from '../../../../services/builder/form-builder.elements-mapping.service';

export const termsAndConditionsLabelKey = 'FORM.DIV DIV-COMMUNITY-CONSENT.TERMS-AND-CONDITIONS_LABEL';
export const privacyPolicyLabelKey = 'FORM.DIV DIV-COMMUNITY-CONSENT.PRIVACY-POLICY-LABEL';
export const communityUrlKey = 'FORM.DIV DIV-COMMUNITY-CONSENT.COMMUNITY-URL';
export const communityNameKey = 'FORM.DIV DIV-COMMUNITY-CONSENT.COMMUNITY-NAME';
export const communityAgeKey = 'FORM.DIV DIV-COMMUNITY-CONSENT.COMMUNITY-AGE';

const FormBuilderFormInterfaceCommunityConsent = ({ item, styles, formId, analyticForm }) => {
  if (!item) return null;

  const { t } = useTranslation();

  const { input: checkbox, label } = FormBuilderElementsMappingService.getSingleElementHTMLTags(item);

  const onFocus = e => {
    e.target.style.outlineColor = styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS]?.outlineColor;
    analyticForm?.trackInputFocused(checkbox.name);
  };

  const onBlur = () => analyticForm?.trackInputBlurred(checkbox.name);

  const communityName = t(communityNameKey);
  const communityUrl = t(communityUrlKey);
  const communityAge = t(communityAgeKey);

  const communityTermsAndConditionsLink = `<a href="${communityUrl}/policies/terms_conditions" target="_blank">${t(
    termsAndConditionsLabelKey
  )}</a>`;

  const communityPrivacyPolicyLink = `<a href="${communityUrl}/policies/privacy" target="_blank">${t(
    privacyPolicyLabelKey
  )}</a>`;

  const textHtml = t(label.nodeValue, {
    minimum_age: communityAge,
    name: communityName,
    terms_link: communityTermsAndConditionsLink,
    privacy_policy_link: communityPrivacyPolicyLink,
  });

  useEffect(() => {
    const formDiv = document.querySelector(`[data-form-id="${formId}"] div`);
    if (formDiv?.shadowRoot) {
      const consentInputElement = formDiv.shadowRoot.getElementById(checkbox.id);

      const dataMapping = checkbox?.['data-mapping'];
      const dataMappingObj = dataMapping ? JSON.parse(dataMapping) : {};
      const relatedEmailInput = formDiv.shadowRoot.querySelector(`[name="${dataMappingObj?.email}"]`);
      consentInputElement.disabled = true;

      if (relatedEmailInput) {
        relatedEmailInput.onchange = () => {
          const isEmailEmpty = relatedEmailInput.value === '' || relatedEmailInput.value === undefined;
          consentInputElement.disabled = isEmailEmpty;

          if (consentInputElement.checked && isEmailEmpty) {
            consentInputElement.checked = false;
          }
        };
      }
    }
  }, [formId, checkbox]);

  return (
    <div
      className={`${item.class}`}
      style={{
        ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS],
        ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.COMMUNITY_CONSENT],
      }}>
      <input
        style={{
          ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT],
          ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.COMMUNITY_CONSENT_INPUT],
        }}
        type={checkbox?.type}
        name={checkbox?.name}
        onFocus={onFocus}
        onBlur={onBlur}
        id={checkbox?.id}
        required={checkbox?.required}
        value={checkbox.value ? checkbox.value : ''}
      />

      <label
        htmlFor={label.for}
        style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.COMMUNITY_CONSENT_LABEL] }}
        dangerouslySetInnerHTML={{ __html: textHtml }}
      />
    </div>
  );
};

FormBuilderFormInterfaceCommunityConsent.displayName = 'FormBuilderFormInterfaceCommunityConsent';
export default React.memo(FormBuilderFormInterfaceCommunityConsent);
