import { SidebarIcons, SidebarItemTypes } from "../utils/meta/consts";
import { formsDisabledButton, postMediaAltTextDisabledButton, SocialFeedsFooterLabels } from "./helpers";
import { SocialFeedsAccountTypes, BackendKeys, OptionsKeys, FormMediaKeys } from "../consts";
import {
  initializeFormId,
  initializeEmailTemplateValue,
  initializeAuthorNameValue,
  initializeAuthorEmailValue,
  initializeAuthorAvatarValue,
  initializePostTextValue,
  initializePostIncentivizedValue,
  initializeTagsValue,
  initializePostAlternativeTextValue,
  initializeTitleValue,
  initializeRatingValue,
  initializeMediaValue,
  validationSchemaRatingsAndReviews
} from "../utils/validation-schema/public-posting-schema";
import useFeatureAccessibility from "../../../../../../../components/hooks/use-feature-accessibility/use-feature-accessibility";
import { GuardNames } from "../../../../../../../guards/guards-list";
export const reviewsRatingsUpgrade = [
  {
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext
    },
    editFooter: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.firstStepSave
    },
    accountSourceType: "reviews_ratings",
    selectedOption: { id: "reviews", title: "Add Ratings & Reviews" },
    header: "Add Ratings & Reviews",
    description: "Ratings & Reviews adds a button to the top right of your display, allowing users to post reviews directly to your board.",
    formSchema: {
      reviews: {
        validationSchema: validationSchemaRatingsAndReviews(),
        submitMapper: (values) => {
          var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j, _k, _l, _m, _n;
          const options = Object.entries({
            [OptionsKeys.EMAIL_TEMPLATE_ID]: (_a = values.email_template_id) == null ? void 0 : _a.value,
            [OptionsKeys.INCENTIVIZED]: (_c = (_b = values.incentivized) == null ? void 0 : _b.value) == null ? void 0 : _c.toString(),
            [OptionsKeys.AUTHOR_NAME_FIELD]: (_d = values.name) == null ? void 0 : _d.value,
            [OptionsKeys.AUTHOR_EMAIL_FIELD]: (_e = values.email) == null ? void 0 : _e.value,
            [OptionsKeys.AUTHOR_AVATAR_FIELD]: (_f = values.avatar) == null ? void 0 : _f.value,
            [OptionsKeys.POST_RATING_FIELD]: (_g = values.rating) == null ? void 0 : _g.value,
            [OptionsKeys.POST_TITLE_FIELD]: (_h = values.title) == null ? void 0 : _h.value,
            [OptionsKeys.POST_TEXT_FIELD]: (_i = values.text) == null ? void 0 : _i.value,
            [OptionsKeys.POST_ALT_TEXT_FIELD]: ((_j = values.media) == null ? void 0 : _j.value) && ((_k = values.alternative_text) == null ? void 0 : _k.value) ? (_l = values.alternative_text) == null ? void 0 : _l.value : null,
            [OptionsKeys.MEDIA]: (_m = values.media) == null ? void 0 : _m.value,
            [OptionsKeys.TAGS]: (_n = values.tags) == null ? void 0 : _n.value
          }).reduce((acc, [key, value]) => {
            acc[key] = typeof value === "boolean" && value || typeof value === "string" && value.length > 0 ? value : null;
            return acc;
          }, {});
          return {
            source: "public_post",
            form_id: values.form.value,
            options
          };
        },
        inputs: [
          {
            id: SocialFeedsAccountTypes.FORM,
            backendId: BackendKeys.EXTERNAL_ID,
            label: "SELECT FORM",
            placeholder: "Select Form...",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true
          },
          {
            id: "email_template_id",
            backendId: OptionsKeys.EMAIL_TEMPLATE_ID,
            label: "EMAIL TEMPLATE",
            placeholder: "Select...",
            description: "Choose your email replies template",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.EMAIL_TEMPLATE_SELECT,
            disabled: formsDisabledButton,
            featureName: GuardNames.POSTS_REPLIES
          },
          {
            id: "name",
            backendId: OptionsKeys.AUTHOR_NAME_FIELD,
            label: "SELECT AUTHOR NAME",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the review\u2019s author name.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
            advanced: true
          },
          {
            id: "email",
            backendId: OptionsKeys.AUTHOR_EMAIL_FIELD,
            label: "SELECT AUTHOR EMAIL",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the review\u2019s author email.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton
          },
          {
            id: FormMediaKeys.AVATAR,
            backendId: OptionsKeys.AUTHOR_AVATAR_FIELD,
            label: "SELECT AUTHOR AVATAR",
            placeholder: "Select...",
            description: "Choose the author avatar field",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton
          },
          {
            id: "rating",
            backendId: OptionsKeys.POST_RATING_FIELD,
            label: "RATING",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the review\u2019s rating.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton
          },
          {
            id: "title",
            backendId: OptionsKeys.POST_TITLE_FIELD,
            label: "POST TITLE",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the review\u2019s title.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
            advanced: true
          },
          {
            id: "text",
            backendId: OptionsKeys.POST_TEXT_FIELD,
            label: "POST TEXT",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the review\u2019s body content section.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
            advanced: true
          },
          {
            id: "incentivized",
            backendId: OptionsKeys.INCENTIVIZED,
            label: "INCENTIVIZED REVIEW",
            placeholder: "Select...",
            description: "Choose which field indicates whether this is an incentivized review or not.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
            advanced: true,
            advancedLabel: "Mark reviews as incentivized"
          },
          {
            id: "tags",
            backendId: OptionsKeys.TAGS,
            label: "POST TAGS",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the review\u2019s tags.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
            defaultValue: initializeTagsValue,
            advanced: true
          },
          {
            id: FormMediaKeys.MEDIA,
            backendId: OptionsKeys.MEDIA,
            label: "SELECT MEDIA",
            placeholder: "Select...",
            description: "Choose the media field",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton
          },
          {
            id: FormMediaKeys.POST_MEDIA_ALT_TEXT,
            backendId: OptionsKeys.POST_ALT_TEXT_FIELD,
            label: "POST MEDIA ALTERNATIVE TEXT",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the review\u2019s alternative text.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: postMediaAltTextDisabledButton
          }
        ],
        editInputs: [
          {
            id: SocialFeedsAccountTypes.FORM,
            backendId: BackendKeys.EXTERNAL_ID,
            label: "SELECT FORM",
            placeholder: "Select Form...",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
            defaultValue: initializeFormId
          },
          {
            id: "email_template_id",
            backendId: OptionsKeys.EMAIL_TEMPLATE_ID,
            label: "EMAIL TEMPLATE",
            placeholder: "Select...",
            description: "Choose your email replies template",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.EMAIL_TEMPLATE_SELECT,
            disabled: formsDisabledButton,
            defaultValue: initializeEmailTemplateValue,
            featureName: GuardNames.POSTS_REPLIES
          },
          {
            id: "name",
            backendId: OptionsKeys.AUTHOR_NAME_FIELD,
            label: "SELECT AUTHOR NAME",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the review\u2019s author name.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
            defaultValue: initializeAuthorNameValue,
            advanced: true
          },
          {
            id: "email",
            backendId: OptionsKeys.AUTHOR_EMAIL_FIELD,
            label: "SELECT AUTHOR EMAIL",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the review\u2019s author email.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
            defaultValue: initializeAuthorEmailValue
          },
          {
            id: FormMediaKeys.AVATAR,
            backendId: OptionsKeys.AUTHOR_AVATAR_FIELD,
            label: "SELECT AUTHOR AVATAR",
            placeholder: "Select...",
            description: "Choose the author avatar field",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
            defaultValue: initializeAuthorAvatarValue
          },
          {
            id: "rating",
            backendId: OptionsKeys.POST_RATING_FIELD,
            label: "RATING",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the review\u2019s rating.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
            defaultValue: initializeRatingValue
          },
          {
            id: "title",
            backendId: OptionsKeys.POST_TITLE_FIELD,
            label: "POST TITLE",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the review\u2019s title.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
            defaultValue: initializeTitleValue,
            advanced: true
          },
          {
            id: OptionsKeys.POST_TEXT_FIELD,
            backendId: OptionsKeys.POST_TEXT_FIELD,
            label: "POST TEXT",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the review\u2019s body content section.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
            defaultValue: initializePostTextValue,
            advanced: true
          },
          {
            id: "incentivized",
            backendId: OptionsKeys.INCENTIVIZED,
            label: "INCENTIVIZED REVIEW",
            placeholder: "Select...",
            description: "Choose which field indicates whether this is an incentivized review or not.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
            defaultValue: initializePostIncentivizedValue,
            advanced: true,
            advancedLabel: "Mark reviews as incentivized"
          },
          {
            id: "tags",
            backendId: OptionsKeys.TAGS,
            label: "POST TAGS",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the review\u2019s tags.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
            defaultValue: initializeTagsValue,
            advanced: true
          },
          {
            id: FormMediaKeys.MEDIA,
            backendId: OptionsKeys.MEDIA,
            label: "SELECT MEDIA",
            placeholder: "Select...",
            description: "Choose the media field",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
            defaultValue: initializeMediaValue
          },
          {
            id: FormMediaKeys.POST_MEDIA_ALT_TEXT,
            backendId: OptionsKeys.POST_ALT_TEXT_FIELD,
            label: "POST MEDIA ALTERNATIVE TEXT",
            placeholder: "Select...",
            description: "Choose the field that you want to map to the review\u2019s alternative text.",
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: postMediaAltTextDisabledButton,
            defaultValue: initializePostAlternativeTextValue
          }
        ]
      }
    }
  }
];
