import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../../elements-classes/form-builder.styles.elements-classes";
export const consents = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.CONSENTS]: {
    display: "flex",
    width: "100%"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.CONSENTS_LABEL]: {
    cursor: "pointer",
    margin: "0 0 0 12px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.CONSENTS_INPUT_REQUIRED]: {
    content: "'*'"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.CONSENTS_INPUT]: {
    "border-radius": "4px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.CONSENTS_LABEL_RTL]: {
    margin: "0 12px 0 0"
  }
};
