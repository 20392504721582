var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
import { FORM_STYLES_VARIABLES } from "../../root/schema/variables/main";
import { FORM_FIELDS_STYLES_VARIABLES } from "../../root/schema/variables/form-fields";
import { commonMedia } from "./common-media";
import { matrix } from "./shared/matrix";
import { choice } from "./shared/choice";
import { consents } from "./shared/consents";
export const common = __spreadValues(__spreadValues(__spreadValues(__spreadProps(__spreadValues({}, commonMedia), {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.ALL]: {
    "font-family": "inherit",
    "box-sizing": "border-box",
    "word-break": "normal",
    "overflow-wrap": "anywhere"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FORM]: {
    "font-size": `var(${FORM_STYLES_VARIABLES.FONT_SIZE})`,
    "font-family": `var(${FORM_STYLES_VARIABLES.FONT_FAMILY})`,
    "background-color": `var(${FORM_STYLES_VARIABLES.BACKGROUND_COLOR})`,
    "border-radius": `var(${FORM_STYLES_VARIABLES.CORNER_RADIUS})`,
    height: "max-content",
    padding: "16px",
    width: "initial"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.INPUT_DATE]: {
    cursor: "pointer",
    padding: "8px 16px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.IFRAME]: {
    "aspect-ratio": 16 / 9,
    border: 0
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.INPUT_RADIO]: {
    cursor: "pointer"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.INPUT_NUMBER]: {
    padding: "8px 16px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.INPUT_TEXT_RTL]: {
    padding: "8px 16px 8px 0"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.INPUT_FOCUS]: {
    "outline-color": `var(${FORM_FIELDS_STYLES_VARIABLES.ACTIVE_COLOR})`
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.INPUT_CHECKBOX]: {
    "min-width": "16px",
    "min-height": "16px",
    "max-width": "16px",
    "max-height": "16px",
    margin: 0,
    padding: 0,
    border: "1px solid #b6b8bb",
    "border-radius": 0,
    cursor: "pointer"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.INPUT_CHECKBOX_DISABLED]: {
    "background-color": "#e6e6e6"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FIELDSET]: {
    border: "none",
    margin: 0,
    padding: 0
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.INPUT_CHECKBOX_CHECKED]: {
    content: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%233B99FC' width='24' height='24' viewBox='0 0 24 24'><rect width='100%' height='100%' fill='%233B99FC'/><path fill='white' stroke='white' stroke-width='2' d='M6.293 13.293l2.828 2.828 7.778-7.778 1.414 1.414-9.192 9.192-4.95-4.95 1.122-1.606z'/%3E%3C/svg%3E")`
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.LABEL_REQUIRED]: {
    content: "'*'"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.REQUIRED]: {
    content: "'*'"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.INPUT_CLASS]: {
    width: "100%",
    padding: "10px 0",
    position: "relative",
    "margin-bottom": `var(${FORM_FIELDS_STYLES_VARIABLES.ELEMENTS_SPACING})`
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.INPUT_EDIT]: {
    "border-style": `var(${FORM_FIELDS_STYLES_VARIABLES.BORDER_STYLE})`,
    "border-radius": `var(${FORM_FIELDS_STYLES_VARIABLES.BORDER_RADIUS})`,
    "border-width": `var(${FORM_FIELDS_STYLES_VARIABLES.BORDER_WIDTH})`,
    "border-color": `var(${FORM_FIELDS_STYLES_VARIABLES.BORDER_COLOR})`,
    "font-size": `var(${FORM_FIELDS_STYLES_VARIABLES.FIELD_TEXT_FONT_SIZE})`,
    "font-family": "inherit",
    "background-color": `var(${FORM_FIELDS_STYLES_VARIABLES.FILL_COLOR})`,
    color: `var(${FORM_FIELDS_STYLES_VARIABLES.FIELD_TEXT_COLOR})`,
    padding: "8px 0 8px 16px",
    width: "100%",
    "min-height": "42px",
    appearance: "none"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SELECT]: {
    "background-repeat": "no-repeat",
    "background-image": `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' width='14' height='14' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/%3E%3C/svg%3E")`,
    padding: "8px 36px 8px 8px",
    cursor: "pointer",
    "background-position": "calc(100% - 8px) 50%"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SELECT_RTL]: {
    padding: "8px 8px 8px 36px",
    "background-position": "8px 50%"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.LINKS]: {
    color: `var(${FORM_STYLES_VARIABLES.LINK_COLOR})`
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FORM_ELEMENTS]: {
    margin: "0 10px 40px",
    width: "100%"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.LINE]: {
    "border-color": "#E8EDF4",
    width: "100%"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.LABEL]: {
    display: "block",
    "font-size": `var(${FORM_FIELDS_STYLES_VARIABLES.LABEL_FONT_SIZE})`,
    color: `var(${FORM_FIELDS_STYLES_VARIABLES.LABEL_COLOR})`,
    padding: 0,
    margin: "0 0 12px 0"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.LABEL_HIDDEN]: {
    border: "0",
    "clip-path": "inset(50%)",
    height: "1px",
    margin: "-1px",
    overflow: "hidden",
    padding: "0",
    position: "absolute",
    width: "1px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FORM_RTL]: {
    "text-align": "right"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FORM_DIV]: {
    display: "flex",
    "flex-wrap": "wrap",
    margin: "0 10px"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.HINT]: {
    display: "inline-block",
    "font-weight": 400,
    "font-family": "inherit",
    "font-size": `var(${FORM_FIELDS_STYLES_VARIABLES.HINT_FONT_SIZE})`,
    color: `var(${FORM_FIELDS_STYLES_VARIABLES.HINT_COLOR})`,
    margin: "12px 0"
  }
}), consents), choice), matrix);
