import React, { Fragment } from 'react';
import './form-builder.form.interface.number-scale.sass';
import { useTranslation } from 'react-i18next';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';
import { FormBuilderElementsMappingService } from '../../../../services/builder/form-builder.elements-mapping.service';

const FormBuilderFormInterfaceNumberScale = ({ styles, item, values, analyticForm }) => {
  if (!item) return null;

  const { t } = useTranslation();
  const tags = FormBuilderElementsMappingService.getAllSingleElementHTMLTags(item);
  const legend = tags.legend[0];
  const input = tags.input[0];
  const scaleElements = tags.input;
  const labelElements = tags.div;
  const minLabel = labelElements.find(el => el.id.includes('min'));
  const maxLabel = labelElements.find(el => el.id.includes('max'));
  const defaultValues = values?.getAll(input?.name);

  const minLabelStyles = `div#${minLabel.id}`;
  const maxLabelStyles = `div#${maxLabel.id}`;

  const onFocus = e => {
    e.target.style.outlineColor = styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS]?.outlineColor;
    analyticForm?.trackInputFocused(input.name);
  };

  const onBlur = () => analyticForm?.trackInputBlurred(input.name);

  return (
    <div
      className={`${item.class}`}
      style={{
        ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS],
        ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.NUMBER_SCALE],
      }}>
      <fieldset style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.NUMBER_SCALE_FIELDSET]}>
        <legend
          style={{
            ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.LABEL],
            ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.NUMBER_SCALE_LEGEND],
          }}>{`${t(legend?.nodeValue)}${input?.required ? '*' : ''}`}</legend>
        {scaleElements
          .filter(el => (el?.nodeName?.includes('input') && el?.type === 'radio') || el?.type === 'checkbox')
          .map((el, index) => {
            const labelNodeValue = scaleElements.find(label => label?.for === el?.id)?.nodeValue;

            return (
              <Fragment key={index}>
                <input
                  type={input?.type}
                  name={el?.name}
                  id={el?.id}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  defaultChecked={defaultValues?.indexOf(el?.value?.toString()) >= 0}
                  value={el?.value}
                  required={input?.required}
                  style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.NUMBER_SCALE_INPUT]}
                  aria-describedby={el?.['aria-describedby']}
                />
                <label htmlFor={el?.id} style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.NUMBER_SCALE_LABEL]}>
                  {t(labelNodeValue)}
                </label>
                {index === 0 ? (
                  <div
                    id={minLabel?.id}
                    aria-hidden='true'
                    style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.NUMBER_SCALE_DIV], ...styles[minLabelStyles] }}>
                    {t(minLabel?.nodeValue)}
                  </div>
                ) : null}
                {index === scaleElements.length - 1 ? (
                  <div
                    id={maxLabel?.id}
                    aria-hidden='true'
                    style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.NUMBER_SCALE_DIV], ...styles[maxLabelStyles] }}>
                    {t(maxLabel?.nodeValue)}
                  </div>
                ) : null}
              </Fragment>
            );
          })}
      </fieldset>
    </div>
  );
};

FormBuilderFormInterfaceNumberScale.displayName = 'FormBuilderFormInterfaceNumberScale';
export default React.memo(FormBuilderFormInterfaceNumberScale);
