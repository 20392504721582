var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { submitButton } from "../elements/submit-button";
import { successMessage } from "../elements/success-message";
export const common = __spreadValues(__spreadValues({
  ALL: "*",
  FORM: ".div-form",
  FORM_RTL: ".div-form[dir='rtl'] *",
  FORM_DIV: ".div-form > div",
  FORM_ELEMENTS: ".div-form > div > *",
  LINKS: "a, .input-file input::before",
  FIELDSET: "fieldset",
  INPUT_CLASS: ".input",
  INPUT_EDIT: "input, select, textarea",
  INPUT_CHECKBOX: 'input[type="checkbox"]',
  INPUT_CHECKBOX_CHECKED: 'input[type="checkbox"]:checked',
  INPUT_CONSENT: ".input-consent input, .input-community-consent input, .input-acceptance input",
  INPUT_FOCUS: "input:focus, select:focus, textarea:focus, .div-submit-button button:focus, a:focus",
  INPUT_TEXT_RTL: "*[dir='rtl'] input[type='text']",
  INPUT_CHOICE_RTL: "*[dir='rtl'] .input-single-choice input, *[dir='rtl'] .input-multiple-choice input",
  SELECT: "select",
  LABEL: "label, legend, thead th, tbody th, .input-number-scale > div, .input-address label",
  LABEL_HIDDEN: ".label-hidden legend, .label-hidden label",
  HINT: ".div-hint",
  REQUIRED: ".required legend:not(:empty):after",
  LABEL_REQUIRED: ".input > label:not(:empty):first-child:has(+ :required):after, .input:has(input[required]) legend:not(:empty):after",
  INPUT_CONSENT_REQUIRED: ".input-consent:has(input[required]) label:not(:empty):after, .input-community-consent:has(input[required]) label:not(:empty):after, .input-acceptance:has(input[required]) label:not(:empty):after",
  CHOICE_TWO_COLUMN: ".input-choice-two-column",
  CHOICE_THREE_COLUMN: ".input-choice-three-column"
}, submitButton), successMessage);
