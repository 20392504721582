import React, { Fragment, useEffect } from "react";
import { ELEMENTS_CLASSES } from "../../../../utils/form-builder.types";
import { Config } from "../../../../../../utils/config";
import { FormBuilderElementsMappingService } from "../../../../services/builder/form-builder.elements-mapping.service";
const FormBuilderFormInterfaceChoice = ({ item, formId, values, analyticForm, t }) => {
  if (!item) return null;
  const elements = item.elements;
  const tags = FormBuilderElementsMappingService.getAllSingleElementHTMLTags(item);
  const legend = tags.legend[0];
  const input = tags.input[0];
  const isSingleChoice = item.class.includes(ELEMENTS_CLASSES.SINGLE_CHOICE);
  const isDevEnv = Config.isDevEnv();
  const defaultValues = values == null ? void 0 : values.getAll(input.name);
  const onFocus = () => {
    analyticForm == null ? void 0 : analyticForm.trackInputFocused(input.name);
  };
  const onBlur = () => analyticForm == null ? void 0 : analyticForm.trackInputBlurred(input.name);
  useEffect(() => {
    if (!isSingleChoice && (input == null ? void 0 : input.required)) {
      const formDiv = document.querySelector(`[form-id="${formId}"] div`);
      const root = isDevEnv ? document : formDiv == null ? void 0 : formDiv.shadowRoot;
      const firstInvalidInputHTML = root == null ? void 0 : root.getElementById(input.id);
      firstInvalidInputHTML == null ? void 0 : firstInvalidInputHTML.setCustomValidity("Please tick any box if you want to proceed.");
    }
  }, [input == null ? void 0 : input.id, input == null ? void 0 : input.required, isSingleChoice]);
  const onChange = () => {
    const formDiv = document.querySelector(`[form-id="${formId}"] div`);
    const root = isDevEnv ? document : formDiv == null ? void 0 : formDiv.shadowRoot;
    const inputs = tags.input;
    const inputsArr = [];
    inputs.forEach((el) => {
      const inputHTML = root == null ? void 0 : root.getElementById(el.id);
      inputsArr.push(inputHTML);
    });
    const firstInvalidInputHTML = root == null ? void 0 : root.getElementById(input.id);
    const isAnyInputSelected = inputsArr == null ? void 0 : inputsArr.some((el) => (el == null ? void 0 : el.checked) === true);
    if (isAnyInputSelected) {
      firstInvalidInputHTML == null ? void 0 : firstInvalidInputHTML.setCustomValidity("");
    } else {
      firstInvalidInputHTML == null ? void 0 : firstInvalidInputHTML.setCustomValidity("Please tick any box if you want to proceed.");
    }
  };
  const currentOnChangeHandler = (() => {
    if (!isSingleChoice && (input == null ? void 0 : input.required)) {
      return onChange;
    }
    return () => {
    };
  })();
  return /* @__PURE__ */ React.createElement("fieldset", { className: item.class }, /* @__PURE__ */ React.createElement("legend", null, t(legend == null ? void 0 : legend.nodeValue)), elements.filter((el) => {
    var _a;
    return ((_a = el == null ? void 0 : el.nodeName) == null ? void 0 : _a.includes("input")) && (el == null ? void 0 : el.type) === "radio" || (el == null ? void 0 : el.type) === "checkbox";
  }).map((el, index) => {
    var _a;
    const labelNodeValue = (_a = elements.find((label) => (label == null ? void 0 : label.for) === (el == null ? void 0 : el.id))) == null ? void 0 : _a.nodeValue;
    return /* @__PURE__ */ React.createElement(Fragment, { key: index }, /* @__PURE__ */ React.createElement(
      "input",
      {
        type: input == null ? void 0 : input.type,
        name: el == null ? void 0 : el.name,
        id: el == null ? void 0 : el.id,
        defaultChecked: (defaultValues == null ? void 0 : defaultValues.indexOf(el == null ? void 0 : el.value)) >= 0,
        onFocus,
        onBlur,
        onChange: currentOnChangeHandler,
        value: el == null ? void 0 : el.value,
        required: isSingleChoice ? input == null ? void 0 : input.required : false
      }
    ), /* @__PURE__ */ React.createElement("label", { htmlFor: el == null ? void 0 : el.id }, t(labelNodeValue)));
  }));
};
FormBuilderFormInterfaceChoice.displayName = "FormBuilderFormInterfaceChoice";
export default React.memo(FormBuilderFormInterfaceChoice);
