import { ACTIONS } from '../../actions/builder/form-builder-submission.builder.actions';
import produce from 'immer';

export const initialState = {
  isFetching: false,
  included: [],
  meta: undefined,
  links: {
    self: undefined,
    prev: undefined,
    next: undefined,
  },
  data: [],
};

export const formBuilderSubmissionBuilderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_SUBMISSION.REQUEST:
      return produce(state, draft => {
        draft.isFetching = true;
      });

    case ACTIONS.GET_SUBMISSION.SUCCESS:
      return produce(state, draft => {
        draft.data = action.payload?.data.map(el => {
          const relationships = el.attributes?.data?.relationships;
          delete el.attributes?.data?.relationships;

          return {
            ...el,
            attributes: {
              ...el.attributes,
              data: {
                ...el.attributes.data,
                // we should display uploaded files in sepatate columns based on filed ids
                // {
                //    field_name_1: [{ id: 1, type: 'upload', attributes: { ... } }, { id: 2, type: 'upload', attributes: { ... } }],
                //    field_name_2: [{ id: 4, type: 'upload', attributes: { ... } }, { id: 6, type: 'upload', attributes: { ... } }],
                //   ...
                // }
                ...(relationships && typeof relationships === 'object'
                  ? Object.entries(relationships).reduce((obj, [key, value]) => {
                      const files = action.payload?.included
                        ?.filter(e => e?.type === 'upload')
                        ?.filter(e => value?.data?.some(file => file?.id === e?.id && file?.type === 'upload'));
                      return Object.assign(obj, { [key]: files });
                    }, {})
                  : {}),
              },
            },
          };
        });

        draft.included = action.payload?.included;
        draft.meta = action.payload?.meta;
        draft.links = action.payload?.links;
        draft.isFetching = false;
      });

    case ACTIONS.GET_SUBMISSION.FAILURE:
      return produce(state, draft => {
        draft.isFetching = false;
      });

    case ACTIONS.RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
