export const SUBMIT_BUTTON_STYLES_VARIABLES = {
  BUTTON_COLOR: "--submit-button-background-color",
  BORDER_STYLE: "--submit-button-border-style",
  BORDER_COLOR: "--submit-button-border-color",
  BORDER_WIDTH: "--submit-button-border-width",
  BORDER_RADIUS: "--submit-button-corner-radius",
  COLOR: "--submit-button-text-color",
  WIDTH: "--submit-button-width",
  ALIGNMENT: "--submit-button-alignment",
  FONT_SIZE: "--submit-button-font-size",
  HORIZONTAL_SPACING: "--submit-button-horizontal-spacing",
  VERTICAL_SPACING: "--submit-button-vertical-spacing"
};
