export const identify = (id, traits) => {
  window._hsq?.push(['identify', traits]);

  //window.analytics?.identify(id, traits);
};

export const track = (name, properties) => {
  //hubspot
  window._hsq?.push([
    'trackCustomBehavioralEvent',
    {
      name,
      properties,
    },
  ]);

  //window.analytics?.track(name, properties);
};

const init = ({ userId }) => {};

export const analytics = {
  track,
  identify,
  init,
};
