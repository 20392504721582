import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
import { SUCCESS_MESSAGE_STYLES_VARIABLES } from "../../root/schema/variables/success-message";
export const successMessage = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SUCCESS_MESSAGE]: {
    display: "flex",
    "justify-content": "center",
    "align-items": "center",
    "flex-direction": "column",
    "background-color": "#ffffff",
    width: "600px",
    "min-height": "400px",
    height: "max-content",
    padding: "40px",
    margin: "auto",
    "word-break": "normal",
    "overflow-wrap": "anywhere"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SUCCESS_MESSAGE_TITLE]: {
    "font-size": `var(${SUCCESS_MESSAGE_STYLES_VARIABLES.TITLE_FONT_SIZE})`,
    "line-height": `var(${SUCCESS_MESSAGE_STYLES_VARIABLES.TITLE_FONT_SIZE})`,
    "font-weight": `var(${SUCCESS_MESSAGE_STYLES_VARIABLES.TITLE_FONT_WEIGHT})`,
    color: `var(${SUCCESS_MESSAGE_STYLES_VARIABLES.TITLE_COLOR})`,
    "font-style": `var(${SUCCESS_MESSAGE_STYLES_VARIABLES.TITLE_FONT_STYLE})`
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SUCCESS_MESSAGE_CAPTION]: {
    display: "block",
    "text-align": "center",
    "font-size": `var(${SUCCESS_MESSAGE_STYLES_VARIABLES.TEXT_FONT_SIZE})`,
    "line-height": `var(${SUCCESS_MESSAGE_STYLES_VARIABLES.TEXT_FONT_SIZE})`,
    color: `var(${SUCCESS_MESSAGE_STYLES_VARIABLES.TEXT_COLOR})`
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SUCCESS_MESSAGE_CAPTION_CONTAINER]: {
    padding: "10px 0 4px",
    "max-width": "100%"
  }
};
