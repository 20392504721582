import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const singleChoice = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SINGLE_CHOICE_INPUT]: {
    width: "16px",
    height: "16px",
    "min-width": "16px",
    "min-height": "16px",
    "border-radius": "50%",
    padding: "0",
    border: "1px solid #b6b8bb"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SINGLE_CHOICE_INPUT_CHECKED]: {
    border: "3px solid #3B99FC"
  }
};
