import { FORM_BUILDER_BREAKPOINTS } from "../../breakpoints";
import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const commonMedia = {
  [FORM_BUILDER_BREAKPOINTS.MDM]: {
    [FORM_BUILDER_STYLE_ELEMENT_CLASSES.FORM]: {
      padding: "50px 40px 40px 40px"
    }
  },
  [FORM_BUILDER_BREAKPOINTS.SMM]: {
    [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MATRIX_TABLE]: {
      width: "100%",
      "table-layout": "fixed"
    },
    [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MATRIX_THEAD]: {
      display: "table-header-group"
    },
    [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MATRIX_TR]: {
      display: "table-row",
      "margin-bottom": 0
    },
    [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MATRIX_TD_TH]: {
      padding: "8px 8px 8px 0",
      display: "table-cell",
      "text-align": "center"
    },
    [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MATRIX_TD_TH_RTL]: {
      padding: "8px 0 8px 8px"
    },
    [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MATRIX_TD_AFTER]: {
      content: "''",
      padding: 0
    },
    [FORM_BUILDER_STYLE_ELEMENT_CLASSES.MATRIX_TBODY_TH]: {
      "text-align": "initial"
    }
  }
};
