import { FORM_FIELDS_STYLES_VARIABLES } from "./variables/form-fields";
export const formFields = {
  [FORM_FIELDS_STYLES_VARIABLES.LABEL_COLOR]: "#2C2D30",
  [FORM_FIELDS_STYLES_VARIABLES.FIELD_TEXT_COLOR]: "#767676",
  [FORM_FIELDS_STYLES_VARIABLES.FILL_COLOR]: "#ffffff",
  [FORM_FIELDS_STYLES_VARIABLES.HINT_COLOR]: "#6D7278",
  [FORM_FIELDS_STYLES_VARIABLES.ACTIVE_COLOR]: "#3B99FC",
  [FORM_FIELDS_STYLES_VARIABLES.BORDER_STYLE]: "solid",
  [FORM_FIELDS_STYLES_VARIABLES.BORDER_WIDTH]: "1px",
  [FORM_FIELDS_STYLES_VARIABLES.BORDER_COLOR]: "#767676",
  [FORM_FIELDS_STYLES_VARIABLES.BORDER_RADIUS]: "6px",
  [FORM_FIELDS_STYLES_VARIABLES.LABEL_FONT_SIZE]: "16px",
  [FORM_FIELDS_STYLES_VARIABLES.FIELD_TEXT_FONT_SIZE]: "16px",
  [FORM_FIELDS_STYLES_VARIABLES.HINT_FONT_SIZE]: "16px",
  [FORM_FIELDS_STYLES_VARIABLES.ELEMENTS_SPACING]: "40px"
};
