export const GroupOneName = "group-1";
export const ELEMENTS_CLASSES = {
  SINGLE_LINE_TEXT: "input input-single",
  NUMBER: "input input-number",
  MULTI_LINE_TEXT: "input input-textarea",
  WEBSITE: "input input-website",
  STAR_RATING: "input input-rating",
  SINGLE_CHOICE: "input input-single-choice",
  MULTIPLE_CHOICE: "input input-multiple-choice",
  DROPDOWN: "input input-dropdown",
  HEADER: "div div-header",
  HEADING: "div div-heading",
  PARAGRAPH: "div div-paragraph",
  FULL_NAME: "input input-name",
  EMAIL: "input input-email",
  PHONE: "input input-phone",
  ADDRESS: "input input-address",
  SPACER: "div div-spacer",
  LINE: "div div-line",
  FILE_UPLOAD: "input input-file",
  IMAGE: "div div-image",
  YOUTUBE: "div div-youtube",
  VIMEO: "div div-vimeo",
  COMMUNITY_CONSENT: "input input-community-consent",
  ACCEPTANCE: "input input-acceptance",
  CONSENT: "input input-consent",
  PRODUCT_ASSOCIATION: "input input-product-association",
  HIDDEN: "input input-hidden",
  SMILEY_SCALE: "input input-smile-scale",
  THUMB_SCALE: "input input-thumb-scale",
  NUMBER_SCALE: "input input-number-scale",
  DATE: "input input-date",
  BIRTHDAY: "input input-birthday",
  SINGLE_MATRIX: "input input-single-matrix",
  MULTIPLE_MATRIX: "input input-multiple-matrix"
};
export const FORM_BUILDER_LAYOUT_ELEMENT_TYPE = {
  SUCCESS_MESSAGE: "div div-success-message",
  SUBMIT_BUTTON: "div div-submit-button"
};
export const FORM_BUILDER_NODE_NAMES = {
  SELF: "",
  LABEL: "label",
  DIV: "div",
  LEGEND: "legend",
  H1: "h1",
  H2: "h2",
  IMG: "img",
  TEXT: "#text",
  INPUT: "input",
  TEXTAREA: "textarea",
  P: "p"
};
export const FORM_BUILDER_TITLE_PROPERTIES = {
  ALT: "alt",
  SRC: "src",
  CONTENT: "content",
  NAME: "name",
  VALUE: "value",
  NODE_VALUE: "nodeValue"
};
export const FORM_BUILDER_INPUT_TYPES = {
  TEXT: "text",
  NUMBER: "number",
  DATE: "date",
  URL: "url",
  TEL: "tel",
  EMAIL: "email",
  TEXTAREA: "textarea"
};
export const itemClassToType = (_class) => {
  if (!_class) {
    return;
  }
  return Object.values(ELEMENTS_CLASSES).find((inputType) => {
    const splitInputType = inputType.split(" ");
    const splitClass = _class.split(" ");
    if (splitClass[1] === splitInputType[1]) {
      return `${splitInputType[0]}-${splitInputType[1]}`;
    }
    return null;
  });
};
export const ELEMENTS_ID_PHRASES = {
  [ELEMENTS_CLASSES.SINGLE_LINE_TEXT]: "single_line",
  [ELEMENTS_CLASSES.MULTI_LINE_TEXT]: "multi_line",
  [ELEMENTS_CLASSES.NUMBER]: "number",
  [ELEMENTS_CLASSES.STAR_RATING]: "rating",
  [ELEMENTS_CLASSES.HEADER]: "header",
  [ELEMENTS_CLASSES.HEADING]: "heading",
  [ELEMENTS_CLASSES.FULL_NAME]: {
    FIRST_NAME: "first_name",
    LAST_NAME: "last_name"
  },
  [ELEMENTS_CLASSES.EMAIL]: "email",
  [ELEMENTS_CLASSES.FILE_UPLOAD]: "media",
  [ELEMENTS_CLASSES.CONSENT]: "consent",
  [ELEMENTS_CLASSES.SINGLE_CHOICE]: "single_choice",
  [ELEMENTS_CLASSES.MULTIPLE_CHOICE]: "multiple_choice",
  [ELEMENTS_CLASSES.WEBSITE]: "website",
  [ELEMENTS_CLASSES.IMAGE]: "image",
  [ELEMENTS_CLASSES.YOUTUBE]: "youtube",
  [ELEMENTS_CLASSES.VIMEO]: "vimeo",
  [ELEMENTS_CLASSES.SMILEY_SCALE]: "smiley_scale",
  [ELEMENTS_CLASSES.PHONE]: "phone",
  [ELEMENTS_CLASSES.ADDRESS]: {
    ADDRESS_LINE_1: "address_line_1",
    ADDRESS_LINE_2: "address_line_2",
    ADDRESS_LINE_3: "address_line_3",
    ADDRESS_LEVEL_1: "address_level_1",
    ADDRESS_LEVEL_2: "address_level_2",
    COUNTRY: "country",
    POSTAL_CODE: "postal_code"
  },
  [ELEMENTS_CLASSES.COMMUNITY_CONSENT]: "community_consent",
  [ELEMENTS_CLASSES.DROPDOWN]: "dropdown",
  [ELEMENTS_CLASSES.THUMB_SCALE]: "thumb_scale",
  [ELEMENTS_CLASSES.SPACER]: "spacer",
  [ELEMENTS_CLASSES.NUMBER_SCALE]: "number_scale",
  [ELEMENTS_CLASSES.DATE]: "date",
  [ELEMENTS_CLASSES.ACCEPTANCE]: "acceptance",
  [ELEMENTS_CLASSES.BIRTHDAY]: "birthday",
  [ELEMENTS_CLASSES.PRODUCT_ASSOCIATION]: "product_association",
  [ELEMENTS_CLASSES.SINGLE_MATRIX]: "single_matrix",
  [ELEMENTS_CLASSES.MULTIPLE_MATRIX]: "multiple_matrix",
  [ELEMENTS_CLASSES.HIDDEN]: "hidden"
};
export const FORM_BUILDER_TYPES_TO_LABELS = {
  [ELEMENTS_CLASSES.SINGLE_LINE_TEXT]: "Single-Line Text",
  [ELEMENTS_CLASSES.NUMBER]: "Number",
  [ELEMENTS_CLASSES.MULTI_LINE_TEXT]: "Multi-Line Text",
  [ELEMENTS_CLASSES.STAR_RATING]: "Star Rating",
  [ELEMENTS_CLASSES.SINGLE_CHOICE]: "Single Choice",
  [ELEMENTS_CLASSES.MULTIPLE_CHOICE]: "Multiple Choice",
  [ELEMENTS_CLASSES.DROPDOWN]: "Dropdown",
  [ELEMENTS_CLASSES.HEADER]: "Header",
  [ELEMENTS_CLASSES.HEADING]: "Heading",
  [ELEMENTS_CLASSES.PARAGRAPH]: "Paragraph",
  [ELEMENTS_CLASSES.FULL_NAME]: "Full Name",
  [ELEMENTS_CLASSES.EMAIL]: "Email",
  [ELEMENTS_CLASSES.PHONE]: "Phone",
  [ELEMENTS_CLASSES.ADDRESS]: "Address",
  [ELEMENTS_CLASSES.SPACER]: "Spacer",
  [ELEMENTS_CLASSES.WEBSITE]: "Website",
  [ELEMENTS_CLASSES.LINE]: "Line",
  [ELEMENTS_CLASSES.FILE_UPLOAD]: "File Upload",
  [ELEMENTS_CLASSES.IMAGE]: "Image",
  [ELEMENTS_CLASSES.YOUTUBE]: "YouTube",
  [ELEMENTS_CLASSES.VIMEO]: "Vimeo",
  [ELEMENTS_CLASSES.CONSENT]: "Rights Management Consent",
  [ELEMENTS_CLASSES.SMILEY_SCALE]: "Smiley Scale",
  [ELEMENTS_CLASSES.THUMB_SCALE]: "Thumb Scale",
  [ELEMENTS_CLASSES.NUMBER_SCALE]: "Number Scale",
  [ELEMENTS_CLASSES.COMMUNITY_CONSENT]: "Community Sign Up",
  [ELEMENTS_CLASSES.ACCEPTANCE]: "Acceptance",
  [ELEMENTS_CLASSES.DATE]: "Date",
  [ELEMENTS_CLASSES.BIRTHDAY]: "Birthday",
  [ELEMENTS_CLASSES.PRODUCT_ASSOCIATION]: "Product Association",
  [ELEMENTS_CLASSES.SINGLE_MATRIX]: "Single Matrix",
  [ELEMENTS_CLASSES.MULTIPLE_MATRIX]: "Multiple Matrix",
  [ELEMENTS_CLASSES.HIDDEN]: "Hidden"
};
export const FORM_BUILDER_ELEMENTS_WITH_HINTS = [
  [ELEMENTS_CLASSES.SINGLE_LINE_TEXT],
  [ELEMENTS_CLASSES.MULTI_LINE_TEXT],
  [ELEMENTS_CLASSES.WEBSITE],
  [ELEMENTS_CLASSES.DROPDOWN],
  [ELEMENTS_CLASSES.EMAIL],
  [ELEMENTS_CLASSES.PHONE],
  [ELEMENTS_CLASSES.FILE_UPLOAD]
];
