import React from 'react';
import './form-builder.form-empty.sass';

const FormBuilderFormEmpty = () => {
  return (
    <div className='form-builder-form-empty'>
      <span className='form-builder-form-empty__title'>Blank Form</span>
      <span className='form-builder-form-empty__caption'>Add at least 1 field to the form to get started.</span>
    </div>
  );
};
FormBuilderFormEmpty.displayName = 'FormBuilderFormEmpty';
export default React.memo(FormBuilderFormEmpty);
