var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { header } from "./elements/header";
import { heading } from "./elements/heading";
import { submitButton } from "./elements/submit-button";
import { successMessage } from "./elements/success-message";
import { image } from "./elements/image";
import { singleChoice } from "./elements/single-choice";
import { common } from "./common/common";
import { spacer } from "./elements/spacer";
import { smileyScale } from "./elements/smiley-scale";
import { fileUpload } from "./elements/file-upload";
import { thumbScale } from "./elements/thumb-scale";
import { numberScale } from "./elements/number-scale";
import { singleMatrix } from "./elements/single-matrix";
import { multipleMatrix } from "./elements/multiple-matrix";
import { multiLineText } from "./elements/multi-line-text";
import { starRating } from "./elements/star-rating";
import { line } from "./elements/line";
import { fullName } from "./elements/full-name";
import { root } from "../root/root";
export const defaultFormStyles = __spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues({}, root), common), header), heading), submitButton), successMessage), image), singleChoice), spacer), smileyScale), fileUpload), thumbScale), numberScale), singleMatrix), multipleMatrix), multiLineText), starRating), line), fullName);
