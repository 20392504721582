import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../../elements-classes/form-builder.styles.elements-classes";
export const singleMatrix = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SINGLE_MATRIX_INPUT]: {
    margin: 0,
    "border-radius": "50%",
    border: "1px solid #b6b8bb"
  },
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.SINGLE_MATRIX_INPUT_CHECKED]: {
    border: "3px solid #3B99FC"
  }
};
