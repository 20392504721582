/*
  This function is responsible for creating translation keys and adding them to the translations object
  and should be used before the form is saved to the database.
  @param {element} element - The element to add the translation keys to
  @returns {element} - The element with the translation keys added
  @returns {translations} - The translations object with the new keys added
 */
import i18n from '../../components/i18next/i18n';
import { Config } from '../../../../utils/config';
import { FORM_BUILDER_INPUT_CLASSES, itemClassToType } from '../../utils/form-builder.types';
import { formBuilderCustomTranslations } from './form-builder.translations';
import { createI18nInstance } from '../../components/i18next/i18n.instance';

export const _generateTranslation = (parentElement, element) => {
  // Handler for submit button and success message because they are layout form elements,
  // the thing is sometimes form elements contain label-hidden class
  // which would cause creating keys with weird names
  let parentClass = parentElement.class;

  const cleanParentClass = itemClassToType(parentClass.class);
  if (cleanParentClass) {
    parentClass = cleanParentClass;
  }
  return _createTranslationKey(`FORM.${parentClass.toUpperCase()}.${element.nodeName.toUpperCase()}`);
};

const addTranslationsToNestedElements = (element, elements, translations) => {
  elements.forEach(e => {
    if ('nodeValue' in e || 'placeholder' in e || 'href' in e || 'content' in e || 'src' in e || 'alt' in e) {
      const key = _generateTranslation(element, e); // Assuming this function generates a unique key

      if ('nodeValue' in e) {
        translations[key] = e.nodeValue;
        e.nodeValue = key;

        if ('href' in e) {
          const _key = _generateTranslation(element, { nodeName: `${e.nodeName}.HREF` });
          translations[_key] = e.href;
          e.href = _key;
        }
      } else if ('placeholder' in e) {
        translations[key] = e.placeholder;
        e.placeholder = key;
      } else if ('content' in e) {
        translations[key] = e.content;
        e.content = key;
      } else if ('src' in e) {
        translations[key] = e.src;
        e.src = key;
        if ('alt' in e) {
          const _key = _generateTranslation(element, { nodeName: `${e.nodeName}.ALT` });
          translations[_key] = e.alt;
          e.alt = _key;
        }
      }
    }

    // Recursive call for nested elements
    if (e.elements) {
      addTranslationsToNestedElements(element, e.elements, translations);
    }
  });
};

const addInternalizationToElement = element => {
  //since I don't know how to handle it now I'm adding this p exception here
  if (element.nodeName === 'p') {
    const key = _createTranslationKey(`FORM.${element.nodeName.toUpperCase()}`);
    let _translations = { [key]: element.nodeValue };
    element.nodeValue = key;
    return { element, translations: _translations };
  }

  if (!element?.elements) {
    //eslint-disable-next-line no-console
    console.error('Elements array is not defined');
    return { element };
  }

  const translations = {};
  addTranslationsToNestedElements(element, element.elements, translations);

  if (element.class === FORM_BUILDER_INPUT_CLASSES.FILE_UPLOAD) {
    const input = element.elements.find(el => el.nodeName === 'input');

    const dataLocale = JSON.parse(input['data-locale']);
    const dataLocaleTranslations = Object.entries(dataLocale)
      .map(([key, tr]) => {
        const value = formBuilderCustomTranslations['data-locale'][key];
        return { [tr]: value };
      })
      .reduce((acc, obj) => {
        const key = Object.keys(obj)[0];
        acc[key] = obj[key];
        return acc;
      }, {});

    return { element, translations: { ...dataLocaleTranslations, ...translations } };
  }

  return { element, translations };
};

// This function is responsible for creating unique translation key
function _createTranslationKey(prefix = '') {
  return `${prefix}.${Math.random()
    .toString(36)
    .substring(2)
    .toUpperCase()}`;
}

function loadInternalization(lang, translations) {
  i18n.addResourceBundle(lang, 'translation', translations);
}

function updateSingleTranslation(lang, key, value) {
  i18n.addResource(lang, 'translation', key, value);
}

function changeLanguage(lang) {
  i18n.changeLanguage(lang);
}

function init(translations, lang, isPreview) {
  // In short, logic below is responsible for detecting current form language,
  // which depends on lang HTML attribute, for example if fr-CA is specified,
  // we should look first for fr-CA then fr then fr-*
  // (where * is any existing French locale and use the first one found in case there are multiple).

  const translationKeys = Object?.keys(translations);
  const splitLang = lang.includes('_') ? lang.split('_') : lang.split('-');
  const similarLanguages = translationKeys?.filter(lang =>
    lang?.toLowerCase()?.includes(splitLang?.[0]?.toLowerCase())
  );
  const mainLanguage = similarLanguages.find(lang =>
    lang?.toLowerCase()?.includes(Config.language.defaultLanguage.toLowerCase()) ? lang : lang?.length === 2
  );

  const languageWithTranslation = similarLanguages.find(simlang => simlang?.toLowerCase() === lang?.toLowerCase());

  let language;

  if (languageWithTranslation) language = languageWithTranslation;

  if (!language && mainLanguage) language = mainLanguage;

  if (!language && similarLanguages[0]) language = similarLanguages[0];

  if (!language) language = translationKeys[0];

  //if you are wondering why it's here let me tell you this beautiful story
  //we want to malform the translations object to be compatible with i18next so we are adding "translation" key to each language
  for (let lang in translations) {
    const currentContent = translations[lang];
    translations[lang] = { translation: currentContent };
  }

  const initValues = {
    lng: language,
    fallbackLng: Config.language.defaultLanguage,
    resources: translations,
    keySeparator: '*',
    saveMissing: true,
    returnEmptyString: true,
    fallbackValue: false,
    returnNull: false,
    interpolation: {
      escapeValue: false,
    },
    missingKeyHandler: () => {
      return '';
    },
    parseMissingKeyHandler: () => {
      return '';
    },
  };

  if (isPreview) {
    i18n.init(initValues);
    return { language };
  } else {
    const i18Instance = createI18nInstance(initValues);
    return { language, i18Instance };
  }
}

export const FormBuilderTranslatorService = {
  addInternalizationToElement,
  updateSingleTranslation,
  loadInternalization,
  changeLanguage,
  init,
};
