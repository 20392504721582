import { SidebarItemTypes } from '../utils/meta/consts';
import { SocialFeedsFooterLabels } from './helpers';
import * as Yup from 'yup';

const validationSchema = () => {
  return Yup.object({
    address: Yup.string().required('Address is required.'),
    name: Yup.string().required('Name is required.'),
    country: Yup.string().required('Country is required.'),
    zip: Yup.string().required('Zip Code is required.'),
    city: Yup.string().required('City is required.'),
    state: Yup.string().required('State is required.'),
  });
};

export const userReview = [
  {
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext,
    },
    accountSourceType: 'review_trackers',
    selectedOption: { id: 'reviews', title: 'Add User Reviews' },
    header: 'Add User Reviews',
    img: '/images/reviews.png',
    description:
      'Enter your location info below to pull in reviews from 100+ review sites. Please note: reviews from your address may take up to 48 hours to appear.',
    formSchema: {
      reviews: {
        validationSchema: validationSchema(),
        submitMapper: values => {
          return {
            options: {
              name: values.name,
              address: values.address,
              country: values.country,
              zip: values.zip,
              city: values.city,
              state: values.state,
            },
          };
        },
        inputs: [
          {
            id: 'name',
            backendId: 'name',
            label: 'LOCATION NAME',
            placeholder: 'Location Name',
            icon: null,
            type: SidebarItemTypes.TEXT,
          },
          {
            id: 'address',
            backendId: 'address',
            label: 'ADDRESS',
            placeholder: 'Address',
            icon: null,
            type: SidebarItemTypes.TEXT,
          },
          {
            id: 'city',
            backendId: 'city',
            label: 'CITY',
            placeholder: 'City',
            icon: null,
            type: SidebarItemTypes.TEXT,
          },
          {
            id: 'state',
            backendId: 'state',
            label: 'STATE/PROVINCE',
            placeholder: 'State / Province',
            icon: null,
            type: SidebarItemTypes.TEXT,
          },
          {
            id: 'zip',
            backendId: 'zip',
            label: 'ZIP/POSTAL CODE',
            placeholder: 'Zip / Postal Code',
            icon: null,
            type: SidebarItemTypes.TEXT,
          },
          {
            id: 'country',
            backendId: 'country',
            label: 'COUNTRY',
            placeholder: 'United States',
            icon: null,
            type: SidebarItemTypes.TEXT,
          },
        ],
      },
    },
  },
];
