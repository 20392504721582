import { ACTIONS } from '../../actions/email-templates/email-templates.actions';
import { produce } from 'immer';

export const initialState = {
  data: [],
  isFetching: false,
  error: undefined,
  selectedEmailTemplates: [],
};

export const emailTemplates = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_EMAIL_TEMPLATES.REQUEST:
    case ACTIONS.POST_EMAIL_TEMPLATE.REQUEST:
    case ACTIONS.UPDATE_EMAIL_TEMPLATE.REQUEST:
    case ACTIONS.DELETE_EMAIL_TEMPLATE.REQUEST:
    case ACTIONS.BULK_DELETE_EMAIL_TEMPLATES.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };

    case ACTIONS.GET_EMAIL_TEMPLATES.SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        links: action.payload.links,
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.UPDATE_EMAIL_TEMPLATE.SUCCESS:
      return produce(state, draft => {
        draft.data = state.data.map(e => {
          if (e.id === action.payload.id) {
            return action.payload;
          }
          return e;
        });
        draft.isFetching = false;
        draft.error = undefined;
      });

    case ACTIONS.GET_EMAIL_TEMPLATES.FAILURE:
    case ACTIONS.POST_EMAIL_TEMPLATE.FAILURE:
    case ACTIONS.DELETE_EMAIL_TEMPLATE.FAILURE:
    case ACTIONS.BULK_DELETE_EMAIL_TEMPLATES.FAILURE:
    case ACTIONS.UPDATE_EMAIL_TEMPLATE.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };

    case ACTIONS.POST_EMAIL_TEMPLATE.SUCCESS:
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        links: action.payload.links,
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.DELETE_EMAIL_TEMPLATE.SUCCESS:
      return produce(state, draft => {
        draft.data = draft.data.filter(domain => domain.id !== action.payload);
        draft.isFetching = false;
      });

    case ACTIONS.SELECT_EMAIL_TEMPLATE:
      return produce(state, draft => {
        draft.selectedEmailTemplates = [...draft.selectedEmailTemplates, action.payload];
      });

    case ACTIONS.UNSELECT_EMAIL_TEMPLATE:
      return produce(state, draft => {
        draft.selectedEmailTemplates = draft.selectedEmailTemplates.filter(({ id }) => {
          return action.payload !== id;
        });
      });

    case ACTIONS.UNSELECT_ALL_EMAIL_TEMPLATES:
      return produce(state, draft => {
        draft.selectedEmailTemplates = [];
      });

    case ACTIONS.BULK_DELETE_EMAIL_TEMPLATES.SUCCESS:
      return produce(state, draftState => {
        draftState.data = draftState.data.filter(template => {
          return !action.payload.some(f => f.id === template.id);
        });
        draftState.isFetching = false;
        draftState.error = false;
        draftState.selectedEmailTemplates = [];
      });

    default: {
      return state;
    }
  }
};
