import React from 'react';
import './form-builder.form.interface.full-name.sass';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';
const FormBuilderFormInterfaceFullName = ({ item, styles, values, analyticForm, t }) => {
  const divs = item.elements.filter(element => element.nodeName === 'div');

  const label = item.elements.find(element => element.nodeName === 'legend');
  const firstName = divs[0];
  const firstNameLabel = firstName.elements.find(element => element.nodeName === 'label');
  const firstNameInput = firstName.elements.find(element => element.nodeName === 'input');
  const lastName = divs[1];
  const lastNameLabel = lastName.elements.find(element => element.nodeName === 'label');
  const lastNameInput = lastName.elements.find(element => element.nodeName === 'input');

  const onFocus = (e, input) => {
    e.target.style.outlineColor = styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS]?.outlineColor;
    analyticForm?.trackInputFocused(input.name);
  };

  const onBlur = input => analyticForm?.trackInputBlurred(input.name);

  return (
    <fieldset className={`${item.class}`} style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS]}>
      <legend style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.LABEL]}>{t(label.content)}</legend>
      <div className={firstName.class}>
        <label style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.LABEL]} htmlFor={firstNameLabel.for}>
          {t(firstNameLabel.nodeValue)}
        </label>
        <input
          style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT], ...styles[`#${firstNameInput.id}`] }}
          name={firstNameInput.name}
          defaultValue={values?.getAll(firstNameInput.name)}
          type={firstNameInput.type}
          autoComplete={firstNameInput.autocomplete}
          onFocus={e => onFocus(e, firstNameInput)}
          onBlur={() => onBlur(firstNameInput)}
          placeholder={t(firstNameInput.placeholder)}
          required={firstNameInput.required}
          id={firstNameInput.id}
        />
      </div>
      <div className={lastName.class}>
        <label style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.LABEL]} htmlFor={lastNameLabel.for}>
          {t(lastNameLabel.nodeValue)}
        </label>
        <input
          style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT], ...styles[`#${lastNameInput.id}`] }}
          name={lastNameInput.name}
          defaultValue={values?.getAll(lastNameInput.name)}
          type={lastNameInput.type}
          autoComplete={lastNameInput.autocomplete}
          onFocus={e => onFocus(e, lastNameInput)}
          onBlur={() => onBlur(lastNameInput)}
          placeholder={t(lastNameInput.placeholder)}
          required={lastNameInput.required}
          id={lastNameInput.id}
        />
      </div>
    </fieldset>
  );
};

FormBuilderFormInterfaceFullName.displayName = 'FormBuilderFormInterfaceFullName';
export default React.memo(FormBuilderFormInterfaceFullName);
