import { SocialAccountsFooterLabels } from '../helpers/helpers';
import { SidebarItemTypes } from '../../../../tint-editor/components/tint-editor/sidebars/social-feed-sidebar/utils/meta/consts';
import { SocialFeedsSource } from '../../../../../types/social-feeds';

import * as Yup from 'yup';

export const validateSchema = () => {
  return Yup.object({
    access_token: Yup.string().required('Pass Key is required.'),
    secret: Yup.string().required('Shared Encoding Key is required.'),
  });
};

export const bazaarvoice = [
  {
    footer: {
      leftButtonLabel: SocialAccountsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialAccountsFooterLabels.firstStepNext,
    },
  },
  {
    footer: {
      leftButtonLabel: SocialAccountsFooterLabels.finalStepPrev,
      rightButtonLabel: SocialAccountsFooterLabels.finalStepNext,
    },
    accountSourceType: SocialFeedsSource.BAZAARVOICE,
    header: 'Add Bazaarvoice',
    selectedOption: { id: 'account', title: 'Add Bazaarvoice' },
    formSchema: {
      account: {
        submitMapper: values => ({
          access_token: values.access_token,
          secret: values.secret,
        }),
        validationSchema: validateSchema(),
        inputs: [
          {
            id: 'access_token',
            backendId: 'access_token',
            label: 'PASS KEY',
            placeholder: 'Enter Pass Key',
            type: SidebarItemTypes.TEXT,
          },
          {
            id: 'secret',
            backendId: 'secret',
            label: 'SHARED ENCODING KEY',
            placeholder: 'Enter Encoding Key',
            type: SidebarItemTypes.TEXT,
          },
        ],
      },
    },
  },
];
