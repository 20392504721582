export const FORM_FIELDS_STYLES_VARIABLES = {
  LABEL_COLOR: "--label-color",
  FIELD_TEXT_COLOR: "--field-text-color",
  FILL_COLOR: "--fill-color",
  HINT_COLOR: "--hint-color",
  ACTIVE_COLOR: "--active-color",
  BORDER_STYLE: "--input-border-style",
  BORDER_WIDTH: "--input-border-width",
  BORDER_COLOR: "--input-border-color",
  BORDER_RADIUS: "--input-corner-radius",
  LABEL_FONT_SIZE: "--label-font-size",
  FIELD_TEXT_FONT_SIZE: "--field-text-font-size",
  HINT_FONT_SIZE: "--hint-font-size",
  ELEMENTS_SPACING: "--elements-spacing"
};
