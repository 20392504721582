import React from 'react';
import useFeatureAccessibility from '../../hooks/use-feature-accessibility/use-feature-accessibility';
import { Config } from '../../../utils/config';
import { filters } from '../../../feature/account-settings/utils/filter-helper/filter-helper';
import ApiDropdown from '../api-dropdown';
import { getUrl } from '../api-dropdown.helpers';
import { dropdownDefaultSelectField } from '../../../feature/tint-editor/components/tint-editor/sidebars/social-feed-sidebar/utils/common/common';

export const emailTemplatesDropdownId = 'emailTemplates';

const createEmailTemplatesAdditionalOption = {
  name: '+ Add Email Template',
  value: 'add_email_template',
  link: Config.routePaths.teamSettings.emailTemplates.emailTemplateAdd.path,
};

export const DropdownEmailTemplates = ({ currentElement, type, onChange, styles, placeholder = 'Select...' }) => {
  const { domainsEmail, emailTemplatesBuilder } = useFeatureAccessibility();
  const baseUrl = '/email_templates';

  if (!(emailTemplatesBuilder && domainsEmail)) return null;
  return (
    <div className='tint-email-templates-dropdown'>
      <ApiDropdown
        placeholder={placeholder}
        fetchUrl={(url, query) =>
          getUrl({
            url,
            query,
            baseUrl: baseUrl,
            filters: [
              {
                by: filters.QUERY,
                value: query,
              },
              ...(type
                ? [
                    {
                      by: filters.TYPE,
                      value: type,
                    },
                  ]
                : []),
            ],
            size: Config.defaultEmailTemplatesPageSize,
          })
        }
        baseUrl={baseUrl}
        currentElement={currentElement}
        dropdownId={emailTemplatesDropdownId}
        onChange={onChange}
        unselectOption={dropdownDefaultSelectField}
        additionalOption={createEmailTemplatesAdditionalOption}
        styles={styles}
        fixedOption={!!createEmailTemplatesAdditionalOption}
      />
    </div>
  );
};
