import { ACTIONS } from '../actions/audience-builder.action';
import produce from 'immer';

export const initialState = {
  audiences: [],
  members: [],
  selectedMembers: [],
  selectedAudiences: [],
  isMemberListFetching: false,
  isAudienceFetching: false,
  isError: false,
  person: undefined,
  currentMembersFilter: {},
  sidebar: undefined,
  currentAudience: undefined,
  audiencesLinks: undefined,
  membersLinks: undefined,
  modal: {
    name: undefined,
    onSubmit: undefined,
  },
};

export const audienceBuilder = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.BULK_DELETE_MEMBERS.REQUEST:
    case ACTIONS.DELETE_MEMBER.REQUEST:
    case ACTIONS.DELETE_AUDIENCE.REQUEST:
    case ACTIONS.BULK_DELETE_AUDIENCES.REQUEST:
    case ACTIONS.GET_PERSON.REQUEST:
      return {
        ...state,
        isError: false,
      };

    case ACTIONS.GET_MEMBERS.REQUEST:
      return {
        ...state,
        isMemberListFetching: action.payload,
        isError: false,
      };

    case ACTIONS.GET_AUDIENCES.REQUEST:
    case ACTIONS.UPDATE_AUDIENCE.REQUEST:
      return {
        ...state,
        isAudienceFetching: action.payload,
        isError: false,
      };

    case ACTIONS.GET_AUDIENCES.SUCCESS:
      return produce(state, draft => {
        draft.isAudienceFetching = false;
        draft.audiences = action.payload.isPagination
          ? [...state.audiences, ...action.payload.data]
          : action.payload.data;
        draft.audiencesLinks = action.payload.links;
      });

    case ACTIONS.GET_MEMBERS.SUCCESS:
      return produce(state, draft => {
        draft.isMemberListFetching = false;
        draft.members = action.payload.isPagination ? [...state.members, ...action.payload.data] : action.payload.data;
        draft.membersLinks = action.payload.links;
      });

    case ACTIONS.DESELECT_ALL_MEMBERS:
      return produce(state, draft => {
        draft.selectedMembers = [];
      });

    case ACTIONS.SELECT_MEMBERS:
      return produce(state, draft => {
        draft.selectedMembers = action.payload;
      });

    case ACTIONS.DESELECT_ALL_AUDIENCES:
      return produce(state, draft => {
        draft.selectedAudiences = [];
      });

    case ACTIONS.SELECT_AUDIENCES:
      return produce(state, draft => {
        draft.selectedAudiences = action.payload;
      });

    case ACTIONS.SET_CURRENT_AUDIENCE:
      return produce(state, draft => {
        draft.currentAudience = action.payload;
      });

    case ACTIONS.BULK_DELETE_MEMBERS.SUCCESS:
      return produce(state, draftState => {
        draftState.members = draftState.members.filter(member => {
          return !action.payload.some(p => p.id === member.id);
        });
        draftState.isMemberListFetching = false;
        draftState.isError = false;
        draftState.selectedMembers = [];
      });

    case ACTIONS.DELETE_MEMBER.SUCCESS:
      return produce(state, draft => {
        draft.members = draft.members.filter(member => member.id !== action.payload);
        draft.isMemberListFetching = false;
      });

    case ACTIONS.BULK_DELETE_AUDIENCES.SUCCESS:
      return produce(state, draftState => {
        draftState.audiences = draftState.audiences.filter(a => {
          return !action.payload.some(p => p.id === a.id);
        });
        draftState.isAudienceFetching = false;
        draftState.isError = false;
        draftState.selectedAudiences = [];
      });

    case ACTIONS.DELETE_AUDIENCE.SUCCESS:
      return produce(state, draft => {
        draft.audiences = draft.audiences.filter(a => a.id !== action.payload);
        draft.isAudienceFetching = false;
      });

    case ACTIONS.ADD_AUDIENCE.SUCCESS:
      return produce(state, draft => {
        draft.audiences = [...state.audiences, action.payload.data];
      });

    case ACTIONS.UPDATE_AUDIENCE.SUCCESS:
      return produce(state, draft => {
        draft.audiences = state.audiences.map(a => {
          if (a.id === action.payload.id) {
            return action.payload;
          }

          return a;
        });
      });

    case ACTIONS.GET_PERSON.SUCCESS:
      return {
        ...state,
        isFetching: false,
        person: action.payload.data,
      };

    case ACTIONS.ADD_PERSON.SUCCESS:
      return produce(state, draft => {
        draft.members = [...draft.members, action.payload];
      });

    case ACTIONS.OPEN_MEMBERS_FILTER:
      return {
        ...state,
        isMemberFilterOpen: true,
      };

    case ACTIONS.CLOSE_MEMBERS_FILTER:
      return {
        ...state,
        isMemberFilterOpen: false,
      };

    case ACTIONS.UPDATE_MEMBERS_FILTER:
      return produce(state, draft => {
        draft.currentMembersFilter = {
          ...draft.currentMembersFilter,
          ...action.payload,
        };
      });

    case ACTIONS.CLEAR_MEMBERS_FILTER:
      return produce(state, draft => {
        draft.currentMembersFilter = {};
      });

    case ACTIONS.OPEN_SIDEBAR:
      return {
        ...state,
        sidebar: action.payload,
      };

    case ACTIONS.CLOSE_SIDEBAR:
      return {
        ...state,
        sidebar: undefined,
      };

    case ACTIONS.GET_AUDIENCES.FAILURE:
    case ACTIONS.GET_MEMBERS.FAILURE:
    case ACTIONS.BULK_DELETE_MEMBERS.FAILURE:
    case ACTIONS.DELETE_MEMBER.FAILURE:
    case ACTIONS.DELETE_AUDIENCE.FAILURE:
    case ACTIONS.BULK_DELETE_AUDIENCES.FAILURE:
    case ACTIONS.ADD_AUDIENCE.FAILURE:
    case ACTIONS.UPDATE_AUDIENCE.FAILURE:
    case ACTIONS.GET_PERSON.FAILURE:
    case ACTIONS.GET_EXPORT_MEMBERS.FAILURE:
    case ACTIONS.GET_EXPORT_MEMBERS_STATUS.FAILURE:
    case ACTIONS.GET_IMPORT_MEMBERS.FAILURE:
    case ACTIONS.GET_IMPORT_MEMBERS_STATUS.FAILURE:
      return {
        ...state,
        isError: action.payload,
        isAudienceFetching: false,
        isMemberListFetching: false,
      };

    default:
      return state;
  }
};
