var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
import { FORM_BUILDER_STYLE_ELEMENT_CLASSES } from "../elements-classes/form-builder.styles.elements-classes";
import { main } from "./schema/main";
import { formHeader } from "./schema/form-header";
import { formHeadings } from "./schema/form-headings";
import { formFields } from "./schema/form-fields";
import { submitButton } from "./schema/submit-button";
import { successMessage } from "./schema/success-message";
export const root = {
  [FORM_BUILDER_STYLE_ELEMENT_CLASSES.ROOT]: __spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues(__spreadValues({}, main), formHeader), formHeadings), formFields), submitButton), successMessage)
};
